import React, { PropsWithChildren, createContext, useEffect } from 'react';

import { Api } from '../api';
import { SUBJECTS, SubjectProps } from '../App';
import { TOPICS, TopicKeys } from '../data/topics';
import { useSavedState, useStorage } from '../hooks';

export interface CompleteEvent {
  section: string;
  answer?: string;
  points: number;
}

export interface CompletedEvent extends CompleteEvent {
  time: number;
}

interface Preferences {
  onboarded: boolean;
  interests: string[];
}

export interface TopicProps {
  completeEvent: (completeEvent: CompleteEvent) => void;
  setProgress: (progress: CompletedEvent[]) => void;
  resetProgress: () => void;
  setCodename: (codename: string) => void;
  setPreferences: (preferences: Preferences) => void;
  progress: CompletedEvent[];
  codename: string;
  preferences: Preferences;
  orderedTopics: SubjectProps[];
}

const defaultHistoryContext: TopicProps = {
  completeEvent: () => {},
  setProgress: () => {},
  resetProgress: () => {},
  setCodename: () => {},
  setPreferences: () => {},
  progress: [],
  codename: '',
  preferences: {
    onboarded: false,
    interests: [],
  },
  orderedTopics: [],
};

export const HistoryContext = createContext<TopicProps>(defaultHistoryContext);

export const HistoryProvider = React.memo(({ children }: PropsWithChildren<Record<never, never>>) => {
  const prefKey = 'lttb-preferences';
  const [preferences, setPreferences] = useSavedState<Preferences>(prefKey, {
    onboarded: false,
    interests: [],
  });

  const codenameKey = 'lttb-codename';
  const [codename, setCodename] = useSavedState<string>(codenameKey, '');

  const userKey = 'lttb-progress';
  const [progress, setProgress] = useSavedState<CompletedEvent[]>(userKey, [], (value) =>
    deleteDeprecatedSections(value),
  );

  useEffect(() => {
    if (codename !== '') {
      Api.user.fetch({ codename }).then((result) => {
        setPreferences(result.data.preferences);
        setProgress((localProgress) => {
          if (JSON.stringify(localProgress) !== JSON.stringify(result.data.progress)) {
            const allProgress = deleteDeprecatedSections([...localProgress, ...result.data.progress]);

            const filteredProgress = allProgress
              .sort((a, b) => a.time - b.time)
              .filter((item, index) => {
                const firstIndex = allProgress.findIndex((i) => i.section === item.section);
                return firstIndex === index;
              });

            Api.user.update({ codename, progress: filteredProgress });

            return filteredProgress;
          }

          return deleteDeprecatedSections(localProgress);
        });
      });
    }
  }, [codename, setProgress, setPreferences]);

  const orderedInterestTopics = SUBJECTS.filter(({ id }) => preferences.interests.includes(id)).sort((a, b) => {
    // move "quit-smoking" to the top if selected
    if (a.id === TopicKeys.QUIT_SMOKING) return -1;
    if (b.id === TopicKeys.QUIT_SMOKING) return 1;
    return 0;
  });
  const remainingTopics = SUBJECTS.filter(({ id }) => !preferences.interests.includes(id));
  const orderedTopics = [...orderedInterestTopics, ...remainingTopics];

  const completeEvent = (event: CompleteEvent) => {
    const newProgress = [...progress];

    const previousAnswer = progress.findIndex((e) => e.section === event.section);

    if (previousAnswer > -1) {
      newProgress.splice(previousAnswer, 1);
    }

    const newEvent = { ...event, time: Date.now() };
    const result = [...newProgress, newEvent];
    setProgress(result);

    if (codename !== '') {
      Api.user.update({ codename, progress: result });
    }

    Object.keys(TOPICS).forEach((topicKey) => {
      if (TOPICS[topicKey].sectionPrefix === event.section.split('-')[0]) {
        const sectionCount = TOPICS[topicKey].sections.length;
        const preCompletedCount = progress.filter((e) => e.section.startsWith(TOPICS[topicKey].sectionPrefix)).length;
        const postCompletedCount = result.filter((e) => e.section.startsWith(TOPICS[topicKey].sectionPrefix)).length;
        if (preCompletedCount < postCompletedCount && postCompletedCount === sectionCount) {
          // @ts-expect-error gtag isn't globally typed
          gtag('event', 'unlock', { event_category: 'badge', event_label: TOPICS[topicKey].title });
        }
      }
    });
  };

  const { remove } = useStorage(userKey);

  const resetProgress = () => {
    setProgress([]);
    remove();
  };

  return (
    <HistoryContext.Provider
      value={{
        completeEvent,
        setProgress,
        resetProgress,
        setCodename,
        setPreferences,
        progress,
        codename,
        preferences,
        orderedTopics,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
});

/**
 * Delete completed events that do not have matching sections in topics
 */
function deleteDeprecatedSections(progress: CompletedEvent[]): CompletedEvent[] {
  const sectionIds: Set<string> = new Set();
  for (const topic in TOPICS) {
    TOPICS[topic].sections.forEach((section) => sectionIds.add(section.id));
  }

  return progress.filter((section) => sectionIds.has(section.section));
}
