export interface AnswerProps {
  id: string;
  copy: string;
  correct?: boolean;
  result?: string;
  imageURL?: string;
  imageAlt?: string;
  bgImageURL?: string;
}
export interface FactProps {
  icon?: string;
  copy: string;
}
export interface SectionProps {
  id: string;
  type:
    | 'multi'
    | 'flip'
    | 'flip-all'
    | 'flip-all-image-back'
    | 'checklist'
    | 'static'
    | 'static-long'
    | 'static-extralong'
    | 'static-image'
    | 'video'
    | 'fast-facts';
  title: string;
  copy?: string;
  imageURL?: string;
  imageAlt?: string;
  answers?: AnswerProps[];
  facts?: FactProps[];
  youtubeId?: string;
  afterCopy?: string;
}
export interface TopicProps {
  title: string;
  id: string;
  sectionPrefix: string;
  intro: string;
  heroImg: string;
  heroAlt: string;
  sections: SectionProps[];
}
export enum TopicKeys {
  MOVE_MORE = 'move-more',
  EAT_HEALTHY = 'eat-healthy',
  BLOOD_PRESSURE = 'blood-pressure',
  CHOLESTEROL = 'cholesterol',
  BLOOD_SUGAR = 'blood-sugar',
  WORK_WITH_A_DOCTOR = 'work-with-a-doctor',
  STRESS_LESS = 'stress-less',
  QUIT_SMOKING = 'quit-smoking',
}
export const TOPICS: Record<string, TopicProps> = {
  [TopicKeys.MOVE_MORE]: {
    title: 'Move More',
    id: 'move-more',
    sectionPrefix: 'mm',
    intro:
      '<p>Physical activity doesn’t have to be hard work. Anytime you’re moving your body, you’re protecting your heart. And it’s never too late to get started.</p>',
    heroImg: 'hero-move.jpg',
    heroAlt: 'A man and a young girl playing basketball',
    sections: [
      {
        id: 'mm-01',
        type: 'multi',
        title: 'Keep it Pumping!',
        copy: '<p>Getting regular physical activity is key to a healthy heart! Whether it’s active chores or a walk around the block, anything that gets your heart pumping is good news for your health.</p><p><strong>About how many minutes of physical activity did you get over the past 7 days?</strong></p>',
        answers: [
          {
            id: 'mm-01-none',
            copy: 'None',
            correct: true,
            result:
              '<ul><li><span class="uppercase">That’s okay!</span> You have to start somewhere — and now is the perfect time.</li><li><strong>Experts recommend that adults get at least 2 hours and 30 minutes (150 minutes) of physical activity every week.</strong></li><li>This could be 20 minutes every day, 30 minutes 5 days a week, or any other combination that works for you.</li><li>You can start with 10 minutes a day.</li></ul>',
          },
          {
            id: 'mm-01-less',
            copy: 'Less than 2 hours and 30 minutes',
            correct: true,
            result:
              '<ul><li class="uppercase">You’re on your way to a healthier heart!</li><li><strong>Experts recommend that adults get at least 2 hours and 30 minutes (150 minutes) of physical activity every week.</strong></li><li>This could be 20 minutes every day, 30 minutes 5 days a week, or any other combination that works for you.</li></ul>',
          },
          {
            id: 'mm-01-more',
            copy: 'More than 2 hours and 30 minutes',
            correct: true,
            result:
              '<ul><li class="uppercase">Amazing work!</li><li>You hit the weekly physical activity recommendation of at least <strong>2 hours and 30 minutes (150 minutes) for adults.</strong></li><li><strong>Experts also recommend doing muscle-strengthening activities 2 days a week.</strong></li></ul>',
          },
        ],
      },
      {
        id: 'mm-02',
        type: 'static-image',
        title: 'Move More, Feel Better',
        copy: '<p>Physical activity has tons of health benefits. It can lower your risk for heart disease, stroke, diabetes, and lots of other health problems. But it can also help you start feeling better <strong>right now</strong>.</p><p>That’s right! Moving more can:</p><ul><li>Brighten your mood</li><li>Help you sleep better</li><li>Give you more energy</li><li>Ease your anxiety</li></ul>',
        imageURL: 'static-image-mm-02.jpg',
        imageAlt: 'A man jogging with headphones in a blue zippered sweatshirt',
      },
      {
        id: 'mm-03',
        type: 'flip-all',
        title: 'Mix It Up!',
        copy: '<p>When it comes to physical activity, it’s important to mix things up so you can try new things and work out your body (and your heart!) in different ways&hellip;</p>',
        answers: [
          {
            id: 'mm-03-A',
            copy: 'Start slow',
            bgImageURL: 'bg-image-mm-03-startslow.png',
            result:
              '<p>If you’re just getting started, focus on moderate-intensity activities — like walking or doing active chores.</p>',
          },
          {
            id: 'mm-03-B',
            copy: 'Speed it up',
            bgImageURL: 'bg-image-mm-03-speeditup.png',
            result:
              '<ul><li>If you’re ready, try some fast-paced activities — like running or swimming laps. These activities make you breathe hard and bring your heart rate up.</li><li>With higher-intensity aerobic activity, you get the same health benefits in half the time!</li></ul>',
          },
          {
            id: 'mm-03-C',
            copy: 'Build muscle strength',
            bgImageURL: 'bg-image-mm-03-buildmuscle.png',
            result:
              '<ul><li>Don’t forget those muscles! Activities like squats, lunges, arm circles, lifting weights, and even carrying groceries can help you build muscle and strengthen your heart.</li><li>Experts recommend that adults do muscle-strengthening activities at least 2 days every week.</li></ul>',
          },
        ],
      },
      {
        id: 'mm-04',
        type: 'video',
        title: 'Move More for a Healthy Heart',
        copy: '<p>Did you know just 10 minutes of physical activity could help your heart? Watch this short video clip to learn how...</p>',
        youtubeId: 'FBcRuzn8wrk',
        afterCopy:
          '<p>View a <a href="https://www.youtube.com/watch?v=KEGtiBb-jOk">version of this video with audio description</a>.</p>',
      },
      {
        id: 'mm-05',
        type: 'multi',
        title: 'Drop It Low',
        copy: '<p>Some health benefits of physical activity can pay off right away. Did you know that a single session of physical activity can lower your blood pressure?</p><p>It’s true! <strong>Can you guess how long this benefit can last?</strong></p>',
        answers: [
          {
            id: 'mm-05-A',
            copy: 'Up to 1 hour',
            result:
              '<p><strong class="uppercase">Good guess!</strong> But with just 1 session of physical activity, you can have positive effects for <strong>up to 24 hours</strong>.</p>',
          },
          {
            id: 'mm-05-B',
            copy: 'Up to 8 hours',
            result:
              '<p><strong class="uppercase">Good guess!</strong> But with just 1 session of physical activity, you can have positive effects for <strong>up to 24 hours</strong>.</p>',
          },
          {
            id: 'mm-05-C',
            copy: 'Up to 16 hours',
            result:
              '<p><strong class="uppercase">Good guess!</strong> But with just 1 session of physical activity, you can have positive effects for <strong>up to 24 hours</strong>.</p>',
          },
          {
            id: 'mm-05-D',
            copy: 'Up to 24 hours',
            correct: true,
            result:
              '<p><strong class="uppercase">You got it!</strong> You can experience the positive effects of 1 session of physical activity for <strong>up to 24 hours</strong>. So get moving and watch your blood pressure fall!</p>',
          },
        ],
      },
      {
        id: 'mm-07',
        type: 'static',
        title: 'Get It In',
        copy: '<p>Planning ahead and keeping track of your physical activity can help you stay motivated. Here are some ideas that can help you fit more physical activity into your schedule:</p><ul><li><strong>Make a list</strong> of ways you can move more throughout the day</li><li><strong>Mark your activity</strong> plans on your calendar</li><li><strong>Set reminders</strong> on your phone</li><li><strong>Track your activity</strong> with your phone or a fitness tracker</li><li><strong>Build in support</strong> — ask a friend or family member to help you stay on track</li></ul>',
      },
      {
        id: 'mm-10',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>When it comes to physical activity, remember that it’s not a sprint (get it?!) — find the pace that works for you.</p><p>So what’s the first small step you’ll take to <strong>move more</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'mm-10-A',
            copy: '<p><strong>Take a walk or roll</strong> around the neighborhood after dinner at least once this week</p>',
          },
          {
            id: 'mm-10-B',
            copy: '<p><strong>Try out some movement breaks at work</strong> a few times this week</p>',
          },
          {
            id: 'mm-10-C',
            copy: '<p><strong>Start tracking my physical activity</strong> daily</p>',
          },
          {
            id: 'mm-10-D',
            copy: '<p><strong>Walk in place or hold a plank during commercial breaks</strong> while watching TV</p>',
          },
          {
            id: 'mm-10-E',
            copy: '<p><strong>Try out a few online fitness classes</strong> over the next few weeks</p>',
          },
          {
            id: 'mm-10-F',
            copy: '<p><strong>Take a fitness class</strong> at a local community recreation center or YMCA</p>',
          },
          {
            id: 'mm-10-G',
            copy: "<p><strong><a href='https://health.gov/moveyourway/activity-planner' target='_blank'>Create a personalized weekly activity plan</a></strong></p>",
          },
        ],
      },
    ],
  },
  [TopicKeys.EAT_HEALTHY]: {
    title: 'Eat Healthy',
    id: 'eat-healthy',
    sectionPrefix: 'eh',
    intro:
      '<p>Healthy eating is key to a healthy heart — but it’s not always easy.</p><p>The good news is that you can start with small changes to find a healthy eating pattern that works for you.</p>',
    heroImg: 'hero-eat-healthy.jpg',
    heroAlt: 'A man and a younger boy grocery shopping',
    sections: [
      {
        id: 'eh-01',
        type: 'checklist',
        title: 'What’s on Your Plate?',
        copy: '<p>Let’s start with the different kinds of healthy food groups! Think about what you ate yesterday, and pick all the foods you remember eating:</p>',
        answers: [
          {
            id: 'eh-01-fruits',
            copy: 'Fruits (like apples, oranges, or bananas)',
          },
          {
            id: 'eh-01-vegetables',
            copy: 'Vegetables (like broccoli, spinach, or squash)',
          },
          {
            id: 'eh-01-grains',
            copy: 'Grains (like rice, bread, or pasta)',
          },
          {
            id: 'eh-01-protein',
            copy: 'Protein (like meat, fish, or beans)',
          },
          {
            id: 'eh-01-dairy',
            copy: 'Dairy (like milk, yogurt, or cheese)',
          },
        ],
      },
      {
        id: 'eh-02',
        type: 'flip-all',
        title: 'Find Your Balance',
        copy: '<p>Eating healthy doesn’t mean salads every day — you get to mix it up! Aim for a mix of healthy foods every day to give your body the nutrients it needs.</p><p>Click each below for tips on where to start&hellip;</p>',
        answers: [
          {
            id: 'eh-02-A',
            copy: 'Fruits and vegetables',
            bgImageURL: 'bg-image-eh-02-fruits.jpg',
            result:
              '<ul><li>Fruits and vegetables are great sources of vitamins and fiber!</li><li>A diet rich in vegetables and whole fruits can help lower your risk of heart disease.</li><li>Try to <strong>fill half your plate</strong> with fruits and vegetables.</li><li>Remember that fresh, canned, or frozen options can all help you reach your goal!</li></ul>',
          },
          {
            id: 'eh-02-B',
            copy: 'Whole grains',
            bgImageURL: 'bg-image-eh-02-grains.jpg',
            result:
              '<ul><li>There 2 types of grains:<ul style="padding: 0;"><li>Whole grains (like whole-wheat flour or brown rice) have the entire grain and are rich in dietary fiber, iron, and B vitamins.</li><li>Refined grains (like white flour or white rice) are processed in a way that strips out the fiber and other good stuff.</li></ul></li><li>To help build a healthy eating pattern, try to <strong>make half your grains whole grains</strong>.</li><li>When shopping for grains, look for phrases like “whole grain” or “100% whole wheat.”</li><li>The fiber in whole-grain options can also help you feel fuller and more satisfied. Bonus!</li></ul>',
          },
          {
            id: 'eh-02-C',
            copy: 'Proteins',
            bgImageURL: 'bg-image-eh-02-protein.png',
            result:
              '<ul><li>Protein foods are good for your bones, muscles, and blood! They can also be a great source of nutrients like B vitamins, iron, and zinc.</li><li><strong>Aim for heart-healthy options with less saturated fat</strong> — like lean meats, chicken, turkey, seafood, and eggs.</li><li>Don’t forget plant-based proteins! There are lots of plant-based options — like nuts, lentils, beans, and soy products. These choices can help you cut back on saturated fat.</li></ul>',
          },
        ],
      },
      {
        id: 'eh-04',
        type: 'flip-all',
        title: 'Focus on 4',
        copy: '<p>There’s so much nutrition advice out there — and that can be overwhelming. To cut through the noise, focus on 4 key things for your heart health&hellip;</p>',
        answers: [
          {
            id: 'eh-04-A',
            copy: 'Cut back on sodium (salt)',
            bgImageURL: 'bg-image-eh-04-sodium.jpg',
            result:
              '<ul><li>More than 70% of the sodium we eat comes from processed, pre-packaged foods and restaurant meals (including fast food).</li><li>Salt also hides in things that don’t taste very salty — like sauces, condiments, and tortillas.</li><li>Look for labels that say “low sodium,” “reduced salt,” or “no salt added.”</li></ul>',
          },
          {
            id: 'eh-04-B',
            copy: 'Watch out for added sugars',
            bgImageURL: 'bg-image-eh-04-sugars.jpg',
            result:
              '<ul><li>Added sugars are sweeteners that companies add to foods and drinks — or that you add at home.</li><li>This  also includes honey, maple syrup, and other so-called “natural” sweeteners!</li><li>Added sugars add calories that can cause weight gain, which can increase your risk for developing type 2 diabetes and heart disease.</li><li>Look for labels that say “no added sugars” or that have a % Daily Value (%DV) of 5 or less for added sugars.</li></ul>',
          },
          {
            id: 'eh-04-C',
            copy: 'Choose healthy fats',
            bgImageURL: 'bg-image-eh-04-fats.jpg',
            result:
              '<ul><li>Eating too much saturated fat — like the fat in butter, full-fat milk, and fatty meats — raises your “bad” cholesterol levels (LDL).</li><li>By switching to unsaturated fats (HDL), you can actually lower your cholesterol.</li></ul>',
          },
          {
            id: 'eh-04-D',
            copy: 'Eat more fiber',
            bgImageURL: 'bg-image-eh-04-fiber.jpg',
            result:
              '<ul><li>Good sources of fiber include fruits, vegetables, whole grains (like brown rice or 100% whole-grain bread), and beans (like kidney or black beans).</li><li>Fiber has tons of benefits for your heart health — it can help manage both blood sugar and cholesterol.</li></ul>',
          },
        ],
      },
      {
        id: 'eh-05',
        type: 'multi',
        title: 'Spot the Sneaky Sodium',
        copy: '<p>You’ve learned about some of the most common sources of sneaky sodium. <strong>But can you guess the #1 source of sodium for Americans?</strong></p>',
        answers: [
          {
            id: 'eh-05-A',
            copy: 'Potato chips',
            result:
              '<p><strong>Good guess!</strong> Potato chips can be super salty, so it’s a good idea to cut back or choose low-sodium versions. But the answer is actually <strong class="uppercase">bread and rolls</strong>! The sodium in all the bread we eat can really add up. So make sure to check labels and choose breads and rolls with less sodium.</p>',
          },
          {
            id: 'eh-05-B',
            copy: 'Canned vegetables',
            result:
              '<p><strong>Good guess!</strong> Canned vegetables can be high in sodium — so choose low-sodium versions and rinse them before using. But the answer is actually <strong class="uppercase">bread and rolls</strong>! The sodium in all the bread we eat can really add up. So make sure to check labels and choose breads and rolls with less sodium.</p>',
          },
          {
            id: 'eh-05-C',
            copy: 'Pizza',
            result:
              '<p><strong>Good guess!</strong> Pizza is the #2 source — it has a ton of sodium (and lots of saturated fat). But the answer is actually <strong class="uppercase">bread and rolls</strong>! The sodium in all the bread we eat can really add up. So make sure to check labels and choose breads and rolls with less sodium.</p>',
          },
          {
            id: 'eh-05-D',
            copy: 'Bread and rolls',
            correct: true,
            result:
              '<p><strong>Impressive — you got it!</strong> The sodium in all the bread we eat can really add up. So make sure to check labels and choose breads and rolls with less sodium. (Oh, and in case you were wondering, the other options are also major sources of sodium! So be sure to watch out for them, too.)</p>',
          },
          {
            id: 'eh-05-E',
            copy: 'Processed meats',
            result:
              '<p><strong>Good guess!</strong> Deli meats, sausages, and other processed meats (think: marinated, smoked, canned, or cured) are packed with sodium. But the answer is actually <strong class="uppercase">bread and rolls</strong>! The sodium in all the bread we eat can really add up. So make sure to check labels and choose breads and rolls with less sodium.</p>',
          },
        ],
      },
      {
        id: 'eh-06',
        type: 'flip',
        title: 'Switch to Healthy Fats',
        copy: '<p>When it comes to fats in foods, you may be surprised to hear that it’s not all bad! You need some fat to be healthy — the key is <strong>cut back on saturated fat</strong> (from fatty meats and full-fat dairy) and <strong>get more unsaturated fats</strong> (from avocado, fish, nuts, and olive oil).</p><p>Eat more healthy fats with these simple, healthy swaps. Instead of&hellip;</p>',
        answers: [
          {
            id: 'eh-06-A',
            copy: 'Butter, shortening, or stick margarine',
            result: '<p>Try olive oil, canola oil, or other vegetable oils!</p>',
          },
          {
            id: 'eh-06-B',
            copy: 'Full-fat milk, cheese, or yogurt',
            result: '<p>Try fat-free or low-fat milk, cheese, or yogurt!</p>',
          },
          {
            id: 'eh-06-C',
            copy: 'Ribs, sausage, or other fatty cuts of meat',
            result: '<p>Try fish, lean meat, or skinless chicken or turkey!</p>',
          },
          {
            id: 'eh-06-D',
            copy: 'Cheese and meat appetizers',
            result: '<p>Try other apps like guacamole, olives, or nuts!</p>',
          },
        ],
      },
      {
        id: 'eh-08',
        type: 'flip',
        title: 'Get Cooking',
        copy: '<p>Cooking more at home is a great way to start eating healthier! And you can find ways to make it work for you if you’re&hellip;</p>',
        answers: [
          {
            id: 'eh-08-A',
            copy: 'Short on time',
            bgImageURL: 'bg-image-eh-08-time.png',
            result:
              '<p>Prep once, eat twice! Cook in big batches and eat leftovers the next day or freeze them for later. Use frozen or canned vegetables to save on prep time.</p>',
          },
          {
            id: 'eh-08-B',
            copy: 'Dreading the dishes',
            bgImageURL: 'bg-image-eh-08-dishes.jpg',
            result:
              '<p>Cooking doesn’t have to destroy your kitchen. Search online for “one pot” or “sheet pan” dinner recipes that are quick to prep and easy to clean up after.</p>',
          },
          {
            id: 'eh-08-C',
            copy: 'Stressing about the grocery bill',
            bgImageURL: 'bg-image-eh-08-bill.jpg',
            result:
              '<p>Go for simple staples like rice and beans with a vegetable that’s in season or on sale. Instead of pricier meats, try low-cost proteins like eggs, lentils, or canned fish.</p>',
          },
          {
            id: 'eh-08-D',
            copy: 'Not feeling motivated to cook',
            bgImageURL: 'bg-image-eh-08-motivation.png',
            result:
              '<p>Make it a fun family or social activity. Find a simple recipe to make with your kids. Or invite friends over to try making a new dish together!</p>',
          },
        ],
      },
      {
        id: 'eh-10',
        type: 'static-image',
        title: 'Beyond the Label',
        copy: '<p>Here are a couple of quick tips to keep in mind when reading the Nutrition Facts label:</p><ul><li><strong>Pay attention to serving sizes.</strong> Serving size is not a measurement of how much to eat. It’s a standard used to compare food amounts. So if you eat more than 1 serving, you get more of everything on that label.</li><li><strong>Look at the % Daily Values.</strong> The % Daily Value (DV) breaks down the nutrients in each serving. If the DV is 5% or less, that’s low. If it’s 20% or more, then it’s high. Look for foods that are:<ul><li>Low in sodium, saturated fat and added sugars</li><li>High in fiber and other vitamins</li></ul></li></ul>',
        imageURL: 'static-image-eh-10.jpg',
        imageAlt: 'A woman reading nutrition labels at the grocery store.',
      },
      {
        id: 'eh-11',
        type: 'static-image',
        title: 'Eating Healthy When Eating Out',
        copy: '<p>Let’s be real — you don’t have to cook every meal to eat healthy. When you get takeout or go out to eat, try these tips:</p><ul><li>Choose grilled or baked options instead of fried — and skip the rich sauces</li><li>Split an entrée or dessert with a friend — or take half home as leftovers</li><li>Get a fruit and vegetable on your plate — and aim for different colors</li><li>At fast food chains, skip the super sizing — and ask for extra vegetable toppings</li><li>Drink water, sparkling water, or unsweetened tea instead of soda</li></ul>',
        imageURL: 'static-image-eh-11.jpg',
        imageAlt: 'A plate with avacodo toast shown from above at a table setting.',
      },
      {
        id: 'eh-14',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>So what’s the first small step you’ll take to <strong>eat healthy</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'eh-14-A',
            copy: '<p><strong>Cook at least 1 meal at home</strong> this week</p>',
          },
          {
            id: 'eh-14-B',
            copy: '<p><strong>Shop for lower-sodium versions</strong> of my favorite foods</p>',
          },
          {
            id: 'eh-14-C',
            copy: '<p><strong>Swap out soda or other sugar-sweetened drinks for water</strong> every day this week</p>',
          },
          {
            id: 'eh-14-D',
            copy: '<p><strong>Try at least 1 new fruit or vegetable</strong> every week for a month</p>',
          },
          {
            id: 'eh-14-E',
            copy: "<p><strong><a href='https://www.myplate.gov/myplate-kitchen' target='_blank'>Make a new heart-healthy recipe</a></strong> with a loved one</p>",
          },
          {
            id: 'eh-14-F',
            copy: "<p><strong><a href='https://www.usa.gov/Food-Help' target='_blank'>Find food assistance programs</a></strong> to support my heart-health journey</p>",
          },
          {
            id: 'eh-14-G',
            copy: "<p><strong><a href='https://www.myplate.gov/myplate-plan' target='_blank'>Make a MyPlate Plan</a></strong></p>",
          },
          {
            id: 'eh-14-H',
            copy: '<p>Starting this week, try to <strong>fill half my plate with fruits and vegetables</strong> for 1 meal per day</p>',
          },
        ],
      },
    ],
  },
  [TopicKeys.BLOOD_PRESSURE]: {
    title: 'Control Your Blood Pressure',
    id: 'blood-pressure',
    sectionPrefix: 'bp',
    intro:
      '<p>High blood pressure affects a lot more than just your heart — it can impact your whole body, head to toe.</p><p>The good news is there are lots of things you can do to keep it in a healthy range. And if you get started now, your heart will thank you later.</p>',
    heroImg: 'hero-blood-pressure-new.jpg',
    heroAlt: 'A man checking his blood pressure at home',
    sections: [
      {
        id: 'bp-01',
        type: 'fast-facts',
        title: 'Blood Pressure Fast Facts',
        facts: [
          {
            icon: 'BLOOD_PRESSURE',
            copy: '<p>Blood pressure measures how hard your blood <strong>pushes against your arteries</strong> as it moves from your heart to your other organs.</p>',
          },
          {
            icon: 'BLOOD_PRESSURE',
            copy: '<p>High blood pressure — also called <strong>hypertension</strong> — can make your arteries stiff and more likely to burst or clog.</p>',
          },
          {
            icon: 'BLOOD_PRESSURE',
            copy: '<p>Getting your blood pressure checked is the <strong>only</strong> way to know if it’s high.</p>',
          },
        ],
      },
      {
        id: 'bp-02',
        type: 'multi',
        title: 'Keep It on the Low',
        copy: '<p>You know it’s important to control your blood pressure. <strong>But how does it protect your health?</strong></p>',
        answers: [
          {
            id: 'bp-01-A',
            copy: 'Lowers your risk of heart attack and stroke',
            result:
              '<p><strong>You almost had it!</strong> Controlling your blood pressure is key to preventing heart attack and stroke. But the answer is actually E — all of the above!</p>',
          },
          {
            id: 'bp-01-B',
            copy: 'Lowers your risk of memory loss and dementia ',
            result:
              '<p><strong>You almost had it!</strong> Controlling your blood pressure does help keep your brain healthy. But the answer is actually E — all of the above!</p>',
          },
          {
            id: 'bp-01-C',
            copy: 'Keeps your vision sharp',
            result:
              '<p><strong>You almost had it!</strong> Controlling your blood pressure can prevent damage to blood vessels in your eyes, which can lead to vision loss. But the answer is actually E — all of the above!</p>',
          },
          {
            id: 'bp-01-D',
            copy: 'Helps maintain your sex life',
            result:
              '<p><strong>You almost had it!</strong> Controlling blood pressure can make it easier for men to keep an erection — and it can help both men and women maintain their sex drive. But the answer is actually E — all of the above!</p>',
          },
          {
            id: 'bp-01-E',
            copy: 'All of the above',
            correct: true,
            result:
              '<p><strong>You’re right!</strong> All parts of your body need healthy blood flow to work like they’re supposed to. So controlling your blood pressure gets you all 4 of these health benefits — and many more. Impressive, right?</p>',
          },
        ],
      },
      {
        id: 'bp-03',
        type: 'flip',
        title: 'What’s “Normal” Anyway?',
        copy: '<p>A blood pressure reading of <strong>120/80</strong> or under is considered normal. So what do those 2 numbers mean, anyway? Click each card to find out&hellip;</p>',
        answers: [
          {
            id: 'bp-03-systolic',
            copy: 'Systolic',
            result:
              '<p>Systolic blood pressure (the first number) is the pressure in your arteries when your heart’s beating. Keep it at 120 or below.</p>',
          },
          {
            id: 'bp-03-diastolic',
            copy: 'Diastolic',
            result:
              '<p>Diastolic blood pressure (the second number) is the pressure in your arteries when your heart’s resting between beats. Keep it at 80 or below.</p>',
          },
        ],
      },
      {
        id: 'bp-04',
        type: 'multi',
        title: 'Your BP and You',
        copy: '<p>The first step to lowering your blood pressure is finding out where you stand. <strong>The last time you got your blood pressure checked, was it:</strong></p>',
        answers: [
          {
            id: 'bp-04-normal',
            copy: 'Normal (systolic 120 or below/diastolic 80 or below)',
            correct: true,
            result:
              '<p><strong>That’s great!</strong> Remember, it’s important to keep getting your pressure checked so you can track it over time.</p>',
          },
          {
            id: 'bp-04-elevated',
            copy: 'Elevated (systolic from 120 to 129/diastolic 80 or below)',
            correct: true,
            result:
              '<p><strong>Think of this as a wakeup call</strong> — now’s your chance to get your numbers in check <strong>before</strong> they affect your health!</p>',
          },
          {
            id: 'bp-04-high',
            copy: 'High (systolic 130 or higher/diastolic 80 or higher)',
            correct: true,
            result:
              '<p><strong>Time for a change!</strong> Lowering your blood pressure can be a challenge. But you can do it with small changes — like getting active, managing stress, and quitting smoking.</p>',
          },
          {
            id: 'bp-04-idk',
            copy: 'I don’t know',
            correct: true,
            result:
              '<p><strong>Then now’s the perfect time to find out!</strong> Click the arrow to see 3 easy ways to get your blood pressure checked.</p>',
          },
        ],
      },
      {
        id: 'bp-05',
        type: 'flip',
        title: 'Make a Plan',
        copy: '<p>The only way to know if your blood pressure is high is to get it checked. Here are 3 places you can do that:</p>',
        answers: [
          {
            id: 'bp-05-A',
            copy: 'At your local pharmacy',
            result: 'You can get your blood pressure checked at any pharmacy.',
            bgImageURL: 'bg-image-bp-05-pharmacy.png',
          },
          {
            id: 'bp-05-B',
            copy: 'At a doctor’s office',
            result: 'A health care professional can check your blood pressure the next time you go in.',
            bgImageURL: 'bg-image-bp-05-doctor.jpg',
          },
          {
            id: 'bp-05-C',
            copy: 'At home',
            result: 'Ask a health care professional how you can check your blood pressure with a cuff at home.',
            bgImageURL: 'bg-image-bp-05-home.jpg',
          },
        ],
      },
      {
        id: 'bp-06',
        type: 'static-extralong',
        title: 'Get Ready to Check Your Blood Pressure',
        copy: '<p>Before you get your blood pressure checked, here are a few tips to keep in mind <strong>before</strong> you check your blood pressure:</p><ul><li>Don’t eat, drink or work out for 30 minutes leading up to the check</li><li>Go to the restroom (yep, a full bladder can affect your BP!)</li><li>Sit in a comfy chair that supports your back for 5 minutes — and stay there for the check</li><li>Do something relaxing like deep breathing or listen to music to ease your nerves</li></ul>',
      },
      {
        id: 'bp-07',
        type: 'video',
        title: 'Watch Out for Sneaky Sodium',
        copy: '<p>How does sodium affect your blood pressure? Watch this short video clip to find out&hellip;</p>',
        youtubeId: '9NICmCyvcek',
        afterCopy:
          '<p>View a <a href="https://www.youtube.com/watch?v=j_p1UlFvbqM">version of this video with an audio description</a>.</p>',
      },
      {
        id: 'bp-08',
        type: 'flip-all',
        title: 'Small Steps, Big BP Benefits',
        copy: '<p>Learn how these 4 small steps can lower your risk of high blood pressure&hellip;</p>',
        answers: [
          {
            id: 'bp-08-active',
            copy: 'Get active',
            bgImageURL: 'bg-image-bp-08-active.png',
            result:
              '<ul><li>Experts recommend that adults get 2 hours and 30 minutes of physical activity a week. Just remember that you don’t have to do it all at once!</li></ul>',
          },
          {
            id: 'bp-08-salt',
            copy: 'Cut back on sodium (salt)',
            bgImageURL: 'bg-image-bp-08-salt.png',
            result:
              '<ul><li>When you eat too much salt, your body pulls more water into your bloodstream and your blood pressure goes up. That’s why eating less sodium is good for your heart.</li><li>Experts recommend that you <strong>eat less than 2,300 mg of sodium</strong> a day. That’s equal to about 1 teaspoon of salt.</li></ul>',
          },
          {
            id: 'bp-08-meds',
            copy: 'Take medicines your doctor prescribes',
            bgImageURL: 'bg-image-bp-08-meds.png',
            result:
              "<ul><li>Sometimes, healthy lifestyle changes aren't enough. That's where medicines come in - they can lower your blood pressure when other steps aren't getting the job done.</li></ul>",
          },
          {
            id: 'bp-08-stress',
            copy: 'Find strategies to stress less',
            bgImageURL: 'bg-image-bp-08-stress.png',
            result:
              '<ul><li>Stress can lead to higher blood pressure.</li><li>Finding ways to <strong>manage your stress</strong> can improve your overall health and wellness — and lower your risk of heart disease.</li></ul>',
          },
        ],
      },
      {
        id: 'bp-12',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>This is your heart health, your way. So what’s the first small step you’ll take to <strong>control your blood pressure</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'bp-12-A',
            copy: '<p><strong>Make an appointment</strong> to get my blood pressure checked today</p>',
          },
          {
            id: 'bp-12-B',
            copy: '<p><strong>Go for a walk or roll</strong> around the block every day this week</p>',
          },
          {
            id: 'bp-12-C',
            copy: '<p><strong>Season dinner with spices and herbs</strong> instead of salt at least once this week</p>',
          },
          {
            id: 'bp-12-D',
            copy: '<p><strong>Take my blood pressure medicine</strong> on schedule every day</p>',
          },
          {
            id: 'bp-12-E',
            copy: "<p><strong><a href='https://www.cdc.gov/heartdisease/docs/My_First_Blood_Pressure_Visit.pdf' target='_blank'>Use this worksheet</a></strong> to prep for my next blood pressure visit</p>",
          },
          {
            id: 'bp-12-F',
            copy: "<p><strong><a href='https://www.validatebp.org/' target='_blank'>Find a validated blood pressure cuff</a></strong> to use at home</p>",
          },
        ],
      },
    ],
  },
  [TopicKeys.CHOLESTEROL]: {
    title: 'Manage Your Cholesterol',
    id: 'cholesterol',
    sectionPrefix: 'ch',
    intro:
      '<p>Cholesterol isn’t all bad — in fact, your liver makes all the cholesterol you need. But sometimes it can make too much, and that’s bad news for your heart.</p><p>The good news? There are lots of ways to keep your cholesterol in a healthy range. And you can choose how to get started.</p>',
    heroImg: 'hero-cholesterol.jpg',
    heroAlt: 'Man cooking a meal in the kitchen with his daughter',
    sections: [
      {
        id: 'ch-01',
        type: 'fast-facts',
        title: 'Chole&shy;sterol Fast Facts',
        facts: [
          {
            icon: 'CHOLESTEROL_FACTS',
            copy: '<p>Cholesterol is a waxy, fatty substance that your body makes — it’s also in some foods</p>',
          },
          {
            icon: 'CHOLESTEROL_FACTS',
            copy: '<p>Your body uses cholesterol to build cells, make hormones, and even digest food</p>',
          },
          {
            icon: 'CHOLESTEROL_FACTS',
            copy: '<p>When there’s too much cholesterol in your blood, it can build up as plaque in your blood vessels. That can lead to heart attack and stroke</p>',
          },
          {
            icon: 'CHOLESTEROL_FACTS',
            copy: '<p>The only way to know if you have high cholesterol is to get it checked</p>',
          },
        ],
      },
      {
        id: 'ch-02',
        type: 'multi',
        title: 'Own Your Numbers',
        copy: '<p><strong>When’s the last time you got your cholesterol checked?</strong></p>',
        answers: [
          {
            id: 'ch-02-never',
            copy: 'I’ve never had it checked',
            correct: true,
            result:
              '<p><strong>Then now is the perfect time to start!</strong> Experts recommend that you get your cholesterol checked every 4 to 6 years. Remember, the only way to know if you have high cholesterol is to get it checked.</p>',
          },
          {
            id: 'ch-02-4y',
            copy: 'At least once in the last 4 years',
            correct: true,
            result:
              '<p><strong>That’s great!</strong> Experts recommend that you get your cholesterol checked every 4 to 6 years. But if you’ve had a high cholesterol reading, you may need to get it checked more often. Ask your doctor if it’s time for your next cholesterol test.</p>',
          },
          {
            id: 'ch-02-6y',
            copy: 'At least once in the last 6 years',
            correct: true,
            result:
              '<p><strong>That’s great!</strong> Experts recommend that you get your cholesterol checked every 4 to 6 years. But if you’ve had a high cholesterol reading, you may need to get it checked more often. Ask your doctor if it’s time for your next cholesterol test.</p>',
          },
          {
            id: 'ch-02-6+',
            copy: 'More than 6 years ago',
            correct: true,
            result:
              '<p><strong>Then it’s time to do it again!</strong> Experts recommend that you get your cholesterol checked every 4 to 6 years. Talk with a doctor and schedule an appointment to get your cholesterol checked.</p>',
          },
          {
            id: 'ch-02-IDK',
            copy: 'I don’t know',
            correct: true,
            result:
              '<p><strong>If you don’t know, don’t stress — just talk to a doctor!</strong> They can help you figure out if it’s time for a test. Keep in mind that experts recommend you get your cholesterol checked every 4 to 6 years.</p>',
          },
        ],
      },
      {
        id: 'ch-03',
        type: 'static',
        title: 'Fast Forward',
        copy: '<p>Getting your cholesterol checked is easy. The test is called a lipid panel — a simple blood test that measures the fat molecules (called lipids) in your blood.</p><p>You may need to fast before you get the test. <strong>Fasting</strong> means you can’t eat or drink anything (except water) for 8 to 12 hours before the test.</p>',
      },
      {
        id: 'ch-04',
        type: 'multi',
        title: 'Rate the Risk',
        copy: '<p><strong>Which of these things can raise your risk for high cholesterol?</strong></p>',
        answers: [
          {
            id: 'ch-04-A',
            copy: 'Eating too much saturated fat',
            result:
              '<p><strong>Great guess</strong> — too much saturated fat from foods like meat, butter, full-fat dairy, and pastries can definitely raise your cholesterol. But the answer is <strong>all of the above</strong>!</p>',
          },
          {
            id: 'ch-04-B',
            copy: 'Not getting enough physical activity',
            result:
              '<p><strong>Great guess</strong> — it’s true that not getting enough activity can lead to high cholesterol. But the answer is <strong>all of the above</strong>!</p>',
          },
          {
            id: 'ch-04-C',
            copy: 'Smoking',
            result:
              '<p><strong>Great guess</strong> — smoking is definitely bad news for your cholesterol levels. But the answer is <strong>all of the above</strong>!</p>',
          },
          {
            id: 'ch-04-D',
            copy: 'Having a family history of high cholesterol',
            result:
              '<p><strong>Great guess</strong> — it’s true that you’re more likely to have high cholesterol if someone else in your family does. But the answer is <strong>all of the above</strong>!</p>',
          },
          {
            id: 'ch-04-E',
            copy: 'All of the above',
            correct: true,
            result:
              '<p><strong>You really know your stuff!</strong> All 4 of these things can raise your cholesterol. The good news is that you can control 3 of them with healthy changes. And if anyone in your family has high cholesterol, ask your doctor what that means for you.</p>',
          },
        ],
      },
      {
        id: 'ch-05',
        type: 'static-long',
        title: 'What About Chole&shy;sterol in Food?',
        copy: '<p>There are 2 types of cholesterol</p><ul><li><strong>“Good” cholesterol</strong> is called HDL (high-density lipoprotein). To eat more HDL — try whole grains, nuts, avocado, and beans.</li><li><strong>“Bad” cholesterol</strong> is called LDL (low-density lipoprotein). To eat less LDL — avoid eating too much fried foods, sugary foods and beverages, and processed meats.</li></ul><p><strong>Tip:</strong> To keep HDL and LDL straight, remember the <strong>H</strong> in HDL stands for “<strong>H</strong>ealthy” and you want it “<strong>H</strong>igher.” And the <strong>L</strong> in LDL stands for “<strong>L</strong>ousy” and you want it “<strong>L</strong>ower.”</p>',
      },
      {
        id: 'ch-07',
        type: 'flip',
        title: 'Small Steps, Big Benefits',
        copy: '<p>Managing cholesterol doesn’t take big changes. It just takes a little heart.</p><p>Learn how these small steps can help get your cholesterol into a healthy range&hellip;</p>',
        answers: [
          {
            id: 'ch-07-unsaturate',
            copy: 'Switch to healthier fats',
            bgImageURL: 'bg-image-ch-07-switch.png',
            result:
              '<p>Switching to healthier fats makes a big difference! And they’re tasty. Instead of fatty meats and full-fat dairy, try eating more fish, avocados, olive oil, and nuts (like walnuts and almonds).</p>',
          },
          {
            id: 'ch-07-fiber',
            copy: 'Eat more fiber',
            bgImageURL: 'bg-image-ch-07-eatfiber.png',
            result:
              '<p>Fiber is magic — it helps with digestion and lowers cholesterol. So load up on whole grains, beans, lentils, whole fruits, and vegetables.</p>',
          },
          {
            id: 'ch-07-active',
            copy: 'Get a little more active',
            bgImageURL: 'bg-image-ch-07-getactive.png',
            result:
              '<p>Physical activity can help you lower your cholesterol levels and feel your best. Find a way that works for you! Take a walk, turn on some music and dance … whatever gets you moving.</p>',
          },
          {
            id: 'ch-07-quit',
            copy: 'Quit smoking',
            bgImageURL: 'bg-image-ch-07-quitsmoking.png',
            result:
              '<p>Smoking really increases your risk for heart problems. It damages your blood vessels — throughout the body. If you smoke, quitting is a great place to start your heart-health journey.</p>',
          },
          {
            id: 'ch-07-meds',
            copy: 'Take medicines your doctor prescribes',
            bgImageURL: 'bg-image-ch-07-takemeds.png',
            result:
              '<p>Some people can manage cholesterol with activity and eating changes alone — but many people need to take medicines, too. If your doctor has prescribed cholesterol medicine, always follow the instructions for taking them.</p>',
          },
        ],
      },
      {
        id: 'ch-09',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>How you manage your cholesterol is up to you! So what’s the first small step you’ll take to <strong>manage your cholesterol</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'ch-09-A',
            copy: '<p><strong>Schedule a doctor visit to get my cholesterol checked</strong> every 4 to 6 years (or more often if you have high cholesterol)</p>',
          },
          {
            id: 'ch-09-B',
            copy: '<p><strong>Get in a few extra steps</strong> by taking the stairs instead of the elevator — or park farther away, when going out</p>',
          },
          {
            id: 'ch-09-C',
            copy: '<p><strong>Cook all my meals with olive oil instead of butter</strong> this week</p>',
          },
          {
            id: 'ch-09-D',
            copy: '<p><strong>Choose a quit date</strong> to stop smoking today</p>',
          },
          {
            id: 'ch-09-E',
            copy: '<p><strong>Take my cholesterol medicine</strong> on schedule everyday</p>',
          },
          {
            id: 'ch-09-F',
            copy: "<p><strong>Find out if I can get free or low-cost cholesterol checks</strong> at a <a href='https://findahealthcenter.hrsa.gov/' target='_blank'>local health center</a></p>",
          },
          {
            id: 'ch-09-G',
            copy: "<p><strong>Use <a href='https://ccccalculator.ccctracker.com/' target='_blank'>this heart-health calculator</a><strong> to understand my risk for heart attack or stroke</p>",
          },
        ],
      },
    ],
  },
  [TopicKeys.BLOOD_SUGAR]: {
    title: 'Manage Your Blood Sugar',
    id: 'blood-sugar',
    sectionPrefix: 'bs',
    intro:
      '<p>High blood sugar weakens blood vessels and damages the nerves that control your heart. And having diabetes nearly <strong>doubles</strong> your risk for heart disease.</p><p>The good news is that small changes can make a big difference.</p>',
    heroImg: 'hero-blood-sugar.jpg',
    heroAlt: 'Woman pouring a smoothie into a glass',
    sections: [
      {
        id: 'bs-01',
        type: 'fast-facts',
        title: 'Blood Sugar Fast Facts',
        facts: [
          {
            icon: 'BLOOD_SUGAR',
            copy: '<p>Your body breaks down the carbs you eat into blood glucose (blood sugar) — and a hormone called insulin moves that sugar into your cells, where you use it as energy</p>',
          },
          {
            icon: 'BLOOD_SUGAR',
            copy: '<p>Everyone’s blood sugar goes up and down a bit — but diabetes makes your blood sugar stay so high for so long that it does serious damage to your body</p>',
          },
          {
            icon: 'BLOOD_SUGAR',
            copy: '<p>Type 1 diabetes means your body doesn’t make any insulin. Type 2 means your body doesn’t use insulin the way it should</p>',
          },
        ],
      },
      {
        id: 'bs-02',
        type: 'flip-all-image-back',
        title: 'Keep It in Check',
        copy: '<p>Keeping up with your blood sugar levels will not only help your heart — it protects your whole body. Learn more about how blood sugar impacts your&hellip;</p>',
        answers: [
          {
            id: 'bs-02-mind',
            copy: 'Mind',
            result:
              'Diabetes nearly doubles your risk of dementia, so managing blood sugar is key to keeping your mind sharp.',
            imageURL: 'static-image-bs-02-mind.jpg',
            imageAlt: 'A woman practicing yoga in a park with others in the background.',
          },
          {
            id: 'bs-02-vision',
            copy: 'Vision',
            result:
              'High blood sugar can damage blood vessels in your eyes, causing vision loss and even blindness. Controlling your sugar can help protect your sight.',
            imageURL: 'static-image-bs-02-vision.jpg',
            imageAlt: 'A raspberry parfait.',
          },
          {
            id: 'bs-02-feet',
            copy: 'Feet',
            result:
              'High blood sugar can cause painful nerve damage — especially in your feet. Managing your blood sugar can protect your nerves and prevent serious foot infections that make it hard to walk.',
            imageURL: 'static-image-bs-02-feet.jpg',
            imageAlt: 'A man walking a dog on a sidewalk.',
          },
          {
            id: 'bs-02-sex',
            copy: 'Sex Life',
            result:
              'Diabetes can lower sex drive in both men and women. It can also cause vaginal dryness and make it harder for men to keep an erection. So staying on top of your blood sugar helps you maintain your sex life!',
            imageURL: 'static-image-bs-02-sex-life.jpg',
            imageAlt: 'A man and woman jogging together on a street.',
          },
        ],
      },
      {
        id: 'bs-03',
        type: 'static-image',
        title: 'Check Up on It',
        copy: "<p>More than 1 in 3 American adults has <strong>prediabetes</strong> — that’s when your blood sugar is too high, but not quite high enough to be diabetes (at least not yet).</p><p>It doesn’t cause any symptoms, so most people don't know they have it. The good news is that you can stop it from becoming type 2 diabetes. The first step is getting your blood sugar checked.</p>",
        imageURL: 'static-image-bs-03.jpg',
        imageAlt: 'A person consulting with their doctor',
      },
      {
        id: 'bs-04',
        type: 'multi',
        title: 'Own Your Numbers',
        copy: '<p>How often do you need to check your blood sugar? That depends! <strong>Choose your situation to find out:</strong></p>',
        answers: [
          {
            id: 'bs-04-overweight',
            copy: 'I’m between ages 35 to 70 and I’m overweight',
            correct: true,
            result:
              '<strong>Get tested for type 2 diabetes</strong> — and most insurance plans are required to cover these tests. Ask your doctor to check your blood sugar.',
          },
          {
            id: 'bs-04-prediabetes',
            copy: 'I’ve been diagnosed with prediabetes',
            correct: true,
            result: '<strong>Get a follow-up test in 3 to 6 months</strong> to keep an eye on your blood sugar levels.',
          },
          {
            id: 'bs-04-diabetes',
            copy: 'I’ve been diagnosed with diabetes',
            correct: true,
            result:
              '<strong>Check your blood sugar regularly</strong> — probably several times a day. Talk with a doctor about exactly when and how to check your sugar at home.',
          },
          {
            id: 'bs-04-none',
            copy: 'None of these apply to me',
            correct: true,
            result:
              '<strong>Ask a doctor how often you need to get your blood sugar checked.</strong> In general, experts recommend that healthy people get checked every 3 years — a doctor can help you figure out what’s right for you.',
          },
        ],
      },
      {
        id: 'bs-05',
        type: 'static-image',
        title: 'Take Meds If You Need Them',
        copy: '<p>Some people can manage their sugar with healthy eating and physical activity — but lots of people need insulin or other medicines, too.</p><p>These medicines are a powerful tool to protect yourself from the damage that high blood sugar can cause. So if your doctor says you need them, try to think about it as a positive thing for your health — you’re about to get your blood sugar under control!</p>',
        imageURL: 'static-image-bs-05.jpg',
        imageAlt: 'A Patient showing their medication to a doctor via their computer in a video conference',
      },
      {
        id: 'bs-06',
        type: 'flip',
        title: 'Team Up',
        copy: '<p>Managing diabetes is a team effort — you don’t have to do it alone. Learn how each member of your care team can help&hellip;</p>',
        answers: [
          {
            id: 'bs-06-doctor',
            copy: 'Doctor',
            bgImageURL: 'bg-image-bs-06-doctor.png',
            result:
              'Doctors and other medical professionals can track your blood sugar levels and prescribes medicines you need.',
          },
          {
            id: 'bs-06-specialist',
            copy: 'Diabetes education specialist',
            bgImageURL: 'bg-image-bs-06-diabetes.png',
            result:
              'Diabetes education specialists can help you learn to check your blood sugar at home and take medicines as prescribed.',
          },
          {
            id: 'bs-06-dietitian',
            copy: 'Registered dietitian',
            bgImageURL: 'bg-image-bs-06-dietitian.png',
            result:
              'Registered dietitians can help you plan healthy meals and understand how food affects your blood sugar.',
          },
        ],
      },
      {
        id: 'bs-08',
        type: 'fast-facts',
        title: 'Take It 1 Plate at a Time',
        copy: '<p>Try the <strong>plate method</strong> to eat healthy with diabetes — no weighing or counting required! Just fill up your plate like so:</p>',
        facts: [
          {
            icon: 'BLOOD_SUGAR_PLATE_1',
            copy: '<p><strong>Half with non-starchy veggies</strong> like broccoli, squash, or carrots</p>',
          },
          {
            icon: 'BLOOD_SUGAR_PLATE_2',
            copy: '<p><strong>A quarter with lean proteins</strong> like chicken, fish, or beans</p>',
          },
          {
            icon: 'BLOOD_SUGAR_PLATE_3',
            copy: '<p><strong>A quarter with carbs</strong> — including whole grains like brown rice, starchy veggies like potatoes, or fruits like apple slices</p>',
          },
        ],
        afterCopy: '<p>And to drink, <strong>choose water</strong> or another unsweetened drink.</p>',
      },
      {
        id: 'bs-09',
        type: 'multi',
        title: 'Stay on the Level',
        copy: '<p>Throughout the day, your blood sugar can rise and fall. But it’s important to keep it as level as possible. <strong>Which of these things can cause blood sugar spikes?</strong></p>',
        answers: [
          {
            id: 'bs-09-breakfast',
            copy: 'Eating Breakfast',
            result:
              '<p><strong>Nope!</strong> Making time for a morning meal can actually prevent blood sugar spikes later in the day. The <strong>correct answer</strong> is drinking coffee (or other caffeinated drinks), which can cause blood sugar to rise.</p>',
          },
          {
            id: 'bs-09-coffee',
            copy: 'Drinking coffee',
            correct: true,
            result:
              '<p><strong>You got it!</strong> Some people have blood sugar spikes after drinking coffee or other caffeinated drinks.</p>',
          },
          {
            id: 'bs-09-water',
            copy: 'Drinking water',
            result:
              '<p><strong>Nope!</strong> It’s actually the opposite — staying hydrated helps your body get rid of extra sugar in your blood. The <strong>correct answer</strong> is drinking coffee (or other caffeinated drinks), which can cause blood sugar to rise.</p>',
          },
        ],
      },
      {
        id: 'bs-10',
        type: 'static-long',
        title: 'Small Steps, Big Benefits',
        copy: '<p>Did you know physical activity can <strong>lower your blood sugar right away</strong>? It’s true — try checking your sugar before and after you take a walk or do a workout video. You’ll probably see a drop.</p><p>That’s one reason that being active is key to managing diabetes. Walking more is a great way to start!</p>',
      },
      {
        id: 'bs-12',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>You can manage your blood sugar your way. So what’s the first small step you’ll take to <strong>manage your blood sugar</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'bs-12-A',
            copy: '<p><strong>Get my blood sugar checked</strong> — or check it myself at home</p>',
          },
          {
            id: 'bs-12-B',
            copy: "<p><strong>Take the <a href='https://www.cdc.gov/prediabetes/risktest/index.html' target='_blank'>prediabetes risk assessment</a></strong> to understand my personal risk</p>",
          },
          {
            id: 'bs-12-C',
            copy: '<p><strong>Find 10 minutes to fit in some physical activity</strong> every day</p>',
          },
          {
            id: 'bs-12-D',
            copy: '<p><strong>Take my insulin or other diabetes medicines</strong> on schedule every day</p>',
          },
          {
            id: 'bs-12-E',
            copy: "<p><strong><a href='https://www.diabetesfoodhub.org/' target='_blank'>Find heart-healthy recipes</a></strong> to keep my blood sugar under control</p>",
          },
          {
            id: 'bs-12-F',
            copy: "<p><strong><a href='https://myplate-prod.azureedge.us/sites/default/files/2022-01/SSwMP%2520Mini-Poster_English_Final2022.pdf' target='_blank'>Try out the Diabetes Plate Method</a></strong> a couple times a week</p>",
          },
          {
            id: 'bs-12-G',
            copy: "<p><strong><a href='https://www.diabetes.org/tools-support/health-insurance' target='_blank'>Find resources to help pay for diabetes care</a></strong>, like testing supplies and medicines</p>",
          },
          {
            id: 'bs-12-H',
            copy: "<p><strong><a href='https://www.cdc.gov/diabetes/prevention/find-a-program.html' target='_blank'>Find a Diabetes Prevention Program</a></strong> near me</p>",
          },
        ],
      },
    ],
  },
  [TopicKeys.WORK_WITH_A_DOCTOR]: {
    title: 'Work with a Doctor',
    id: 'work-with-a-doctor',
    sectionPrefix: 'wd',
    intro:
      '<p>There are lots of heart-healthy steps you can take on your own, but there are also times when you need to team up with a professional.</p>',
    heroImg: 'hero-doctor.jpg',
    heroAlt: 'Male patient talking with a female doctor in a doctor’s office',
    sections: [
      {
        id: 'wd-01',
        type: 'fast-facts',
        title: 'How Pro&shy;fessionals Can Help',
        copy: '<p>Working with a doctor and other medical professionals can go a long way toward helping you get your heart health on track. Doctors can help you:</p>',
        facts: [
          {
            icon: 'DOCTOR_QUESTION',
            copy: '<p><strong>Boost your knowledge.</strong> They can help you understand your personal risk for heart attack and stroke — and share ways to lower it.</p>',
          },
          {
            icon: 'DOCTOR_ANSWER',
            copy: '<p><strong>Know your status.</strong> They can check your blood pressure, blood sugar, and cholesterol — and keep track of your numbers over time. This helps them and catch problems early, before they cause a heart attack or stroke.</p>',
          },
          {
            icon: 'DOCTOR_QUESTION',
            copy: '<p><strong>Come up with a game plan.</strong> They can look at the big picture and help you make a heart-health plan that’s right for you. They can also share tools and resources to help you stay on track.</p>',
          },
          {
            icon: 'DOCTOR_ANSWER',
            copy: '<p><strong>Make life-saving connections.</strong> They can refer you to a cardiologist (heart doctor) or another specialist if you need one.</p>',
          },
        ],
      },
      {
        id: 'wd-03',
        type: 'flip-all',
        title: 'Think Outside the Docs',
        copy: '<p>Primary care doctors with “MD” after their name aren’t the only ones who can help protect your heart health. For example, your health care team may include&hellip;</p>',
        answers: [
          {
            id: 'wd-03-A',
            copy: 'Nurse practitioners (NPs) & physician’s assistants (PAs)',
            result:
              '<p>Nurse practitioners (NPs) and physician’s assistants (PAs) are great choices for primary care. If you’re looking for a primary care doctor, consider NPs and PAs.</p>',
          },
          {
            id: 'wd-03-B',
            copy: 'Pharmacists',
            result:
              '<p>Pharmacists help you understand when and how to take medicines. The next time you pick up a prescription, take time to chat with your pharmacist and ask any questions you have.</p>',
          },
          {
            id: 'wd-03-C',
            copy: 'Specialists',
            result:
              '<p>Specialists (like cardiologists or diabetes educators) help when you’ve been diagnosed with conditions like heart disease or diabetes. Your primary care doctor can refer you to the specialists you need.</p>',
          },
          {
            id: 'wd-03-D',
            copy: 'Therapists',
            result:
              '<p>Therapists or counselors help with stress and anxiety, which can be bad news for your heart. Your primary care doctor can refer you to a therapist if you need one.</p>',
          },
        ],
      },
      {
        id: 'wd-04',
        type: 'flip',
        title: 'An Rx for the Heart',
        copy: '<p>The journey to better heart health is different for everyone. And sometimes, it can include medicines that help keep your heart healthy.</p><p>Here are some things to keep in mind when working with a medical professional on a treatment plan&hellip;</p>',
        answers: [
          {
            id: 'wd-04-A',
            copy: 'Keep track of all your medicines',
            bgImageURL: 'bg-image-wd-04-keeptrack.png',
            result:
              '<p>Keep an updated list of your medicines and share it with your doctor. That way, your doctor can make sure that your heart medicine is safe to take with the other drugs you’re taking.</p>',
          },
          {
            id: 'wd-04-B',
            copy: 'Take medicines as prescribed',
            bgImageURL: 'bg-image-wd-04-takemed.png',
            result:
              '<p>Remember, your treatment plan takes your unique needs into account. Be sure to follow the doctor’s instructions for taking your heart medicine — and don’t stop taking it even if you’re feeling better.</p>',
          },
          {
            id: 'wd-04-C',
            copy: 'Think of it as a team effort',
            bgImageURL: 'bg-image-wd-04-thinkteam.png',
            result:
              '<p>You and your medical professional are on the same team! Try to keep that in mind as you work together to find the right treatment plan for you. Be honest, ask lots of questions, and get to work — together!</p>',
          },
        ],
      },
      {
        id: 'wd-05',
        type: 'static-long',
        title: 'Ask All the Questions',
        copy: '<p>Remember, a doctor visit is <strong>your</strong> time to get a handle on your health. Try writing down your questions ahead of time — and don’t shy away from asking them all! For example:</p><ul><li>What do my blood pressure and cholesterol numbers mean?</li><li>What types of foods should I eat or avoid?</li><li>How much physical activity do I need?</li><li>What’s the number 1 step I need to take for my heart health?</li><li>Why do I need this medicine? Are there any side effects to watch for?</li></ul>',
      },
      {
        id: 'wd-06',
        type: 'static',
        title: 'Get Ready',
        copy: '<p>Medical visits can be super quick — so a little prep goes a long way. Try these tips:</p><ul><li><strong>Write it down.</strong> Jot down questions and concerns ahead of time so you don’t forget — and take notes during the visit.</li><li><strong>Ask for forms ahead of time.</strong> Before you see a new doctor, ask the office to send the medical history forms ahead of time — that way you won’t be rushed.</li><li><strong>Gather what you need.</strong> Bring your insurance cards, a list of medicines you take, and a list of other doctors you’ve seen.</li></ul>',
      },
      {
        id: 'wd-07',
        type: 'flip-all',
        title: 'Make Your Voice Heard',
        copy: '<p>Medical Professionals can be distracted or forgetful just like everyone else — and they’re often very busy! Make sure you’re getting the attention and care you deserve. Here are some common issues and strategies for overcoming them&hellip;</p>',
        answers: [
          {
            id: 'wd-07-A',
            copy: 'Feeling Brushed Off',
            result:
              '<h4>Is your medical professional brushing off your concerns?</h4><p>Don’t just let it go — repeat yourself and explain why you’re worried about the issue. The doctor may be a medical expert, but no one knows your body better than you. Remember, part of a doctor’s job is to listen to you — and you deserve that at every visit.</p>',
          },
          {
            id: 'wd-07-B',
            copy: 'Feeling Rushed',
            result:
              '<h4>Are you feeling rushed during medical visits?</h4><p>Try writing down 2 or 3 of your most important questions and sharing the list with the doctor. This can help you and the medical prefessional work together to address your key concerns. Remember, you have a right to care that makes you feel supported.</p>',
          },
          {
            id: 'wd-07-C',
            copy: 'Lack of Follow-Up',
            result:
              '<h4>Is your medical professional failing to follow up on something?</h4><p>You can reach out to your medical professional after the appointment or bring it up yourself at your next visit! This is your health, and no one knows it better than you. Remember, you and your medical professional are working together as a team — and they need to do their part.</p>',
          },
          {
            id: 'wd-07-D',
            copy: 'Feeling Confused',
            result:
              '<h4>Are you feeling confused or concerned by recommendations about your health?</h4><p>Ask your medical professional to explain again — or get a second opinion. Sometimes, you may need to talk to someone else to find what works best for you. Remember, everyone deserves quality health care.</p>',
          },
        ],
      },
      {
        id: 'wd-09',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>You can take this process at your own pace and your way! So what’s the first small step you’ll take to <strong>work with a medical professional</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'wd-09-A',
            copy: '<p><strong>Ask a friend or family member</strong> to recommend someone</p>',
          },
          {
            id: 'wd-09-B',
            copy: '<p><strong>Make an appointment</strong> to see a medical professional</p>',
          },
          {
            id: 'wd-09-C',
            copy: '<p><strong>Write down questions</strong> I have before my next appointment</p>',
          },
          {
            id: 'wd-09-D',
            copy: '<p><strong>Talk with a pharmacist</strong> about my medicines</p>',
          },
          {
            id: 'wd-09-E',
            copy: "<p><strong><a href='https://findahealthcenter.hrsa.gov/' target='_blank'>Find a local health center</a></strong> near me</p>",
          },
          {
            id: 'wd-09-F',
            copy: "<p><strong><a href='https://www.healthcare.gov/' target='_blank'>Explore health insurance options</a></strong></p>",
          },
        ],
      },
    ],
  },
  [TopicKeys.STRESS_LESS]: {
    title: 'Stress Less',
    id: 'stress-less',
    sectionPrefix: 'sl',
    intro:
      '<p>Life can be stressful. Work, finances, health problems, parenting&hellip; the list goes on! You can’t always control the things that stress you out. But <strong>you can control how you manage your stress</strong>.</p>',
    heroImg: 'hero-stress-less.jpg',
    heroAlt: 'Woman doing yoga in a living room',
    sections: [
      {
        id: 'sl-01',
        type: 'static-image',
        title: 'Stress: The Good, the Bad, and the Chronic',
        copy: '<p>There are different kinds of stress, and they’re not all bad. Sometimes, stress is our body’s natural way of warning us about a not-so-great situation. And sometimes, it can give you the energy you need to start a new task or solve a problem.</p><p>But when you’re dealing with chronic — or long-term — stress, your body stays in “fight-or-flight” mode all the time. This makes your heart rate speed up and your blood pressure rise, which can increase your risk of heart attack and stroke.</p>',
        imageURL: 'static-image-sl-01.jpg',
        imageAlt: 'A woman doing breathing exercises with her eyes closed.',
      },
      {
        id: 'sl-02',
        type: 'flip',
        title: 'Stress and My Health',
        copy: '<p>Stress can cause many changes in your body that affect your overall health and wellness. Learn more about how stress can affect your&hellip;</p>',
        answers: [
          {
            id: 'sl-02-physical',
            copy: 'Physical health',
            bgImageURL: 'bg-image-sl-02-physicalhealth.png',
            result:
              '<p>Stress can lead to headaches, upset stomach, muscle aches, and low energy. It can also affect your sleeping and eating patterns. Chronic stress can also take a toll on your heart — it’s linked to a higher risk of heart attack and stroke.</p>',
          },
          {
            id: 'sl-02-mental',
            copy: 'Mental health',
            bgImageURL: 'bg-image-sl-02-mentalhealth.png',
            result:
              '<p>Stress can make you feel overwhelmed and tired. It can also make it harder for you to stay motivated, concentrate on tasks, and be creative. Over time, chronic stress can also raise your risk of depression.</p>',
          },
          {
            id: 'sl-02-emotional',
            copy: 'Emotional wellness',
            bgImageURL: 'bg-image-sl-02-emotionalwellness.png',
            result:
              '<p>Stress can make you feel sad, irritable, or angry. And those feelings can make you more likely to do things like smoke, drink alcohol, or eat less healthy foods — which can take a real toll on your heart.</p>',
          },
          {
            id: 'sl-02-social',
            copy: 'Social life',
            bgImageURL: 'bg-image-sl-02-sociallife.png',
            result:
              '<p>Stress can distract you from connecting with friends and family and make it harder to do the things you usually love.</p>',
          },
        ],
      },
      {
        id: 'sl-04',
        type: 'multi',
        title: 'Stress & Me',
        copy: '<p>Think about your life over the last month. <strong>How often would you say you’ve felt stressed?</strong></p>',
        answers: [
          {
            id: 'sl-04-never',
            copy: 'Never',
            correct: true,
            result:
              "<p><strong>Amazing job managing the stress of everyday life</strong> — your heart thanks you! And remember, it's okay if something comes up that does stress you out. Just take some time to unwind and relax when you can.</p>",
          },
          {
            id: 'sl-04-sometimes',
            copy: 'Sometimes',
            correct: true,
            result:
              '<p><strong>Most people are stressed sometimes, so don’t, well&hellip; stress!</strong> Remember stress can come and go — and finding healthy ways to cope with stressors is a great first step to better heart and overall health.</p>',
          },
          {
            id: 'sl-04-often',
            copy: 'Often',
            correct: true,
            result:
              '<p><strong>If you’re stressed a lot of the time, it can take a toll on your body</strong> — including your heart. Take some time this week to think about what’s stressing you out — you might even want to write down the reasons to help you think it through. It’s also a good idea to reach out to someone you trust for support.</p>',
          },
          {
            id: 'sl-04-allways',
            copy: 'All the time',
            correct: true,
            result:
              '<p><strong>You may be experiencing chronic stress</strong>, which can be tough on your heart. Take some time this week to think about what’s stressing you out — you might even want to write down the reasons to help you think it through. It’s also a good idea to reach out to someone you trust for support.</p><p>And you may want to talk to a trained professional, like a counselor, who can teach you strategies to manage stress.</p>',
          },
        ],
      },
      {
        id: 'sl-05',
        type: 'flip-all',
        title: 'Knock Out Stress Fast',
        copy: '<p>When you’re stressed, there are coping strategies that can help you feel better in the moment. Here are some small steps that can make a big difference — and fast&hellip;</p>',
        answers: [
          {
            id: 'sl-05-breathe',
            copy: 'Breathe',
            bgImageURL: 'bg-image-sl-05-breathe.png',
            result:
              '<ul><li>Mindful, deep breathing can set off your body’s relaxation response — and you can do it anywhere.</li><li>Try 4-7-8 breathing — breathe in for 4 seconds, hold for 7 seconds, and breathe out for 8 seconds. This easy exercise can slow your breathing down and instantly make you calmer.</li></ul>',
          },
          {
            id: 'sl-05-stretch',
            copy: 'Stretch',
            bgImageURL: 'bg-image-sl-05-stretch.png',
            result:
              '<ul><li>Stress can make your body feel tight and stiff, so stretching can help.</li><li>Start by stretching your arms, back, or neck while breathing deeply. Hold each stretch for about 30 to 60 seconds — and feel the relaxation set in!</li></ul>',
          },
          {
            id: 'sl-05-meditate',
            copy: 'Meditate',
            bgImageURL: 'bg-image-sl-05-meditate.png',
            result:
              '<ul><li>Meditation is a practice that can help clear your mind. It can include deep breathing, sitting quietly, and focusing your attention on an object, sound, or phrase.</li><li>Taking a few minutes to stop, clear your mind, and focus on your body can help you fight stress. It can also lower your blood pressure — and your risk for anxiety, depression, and insomnia.</li></ul>',
          },
          {
            id: 'sl-05-unwind',
            copy: 'Unwind',
            bgImageURL: 'bg-image-sl-05-unwind.png',
            result:
              '<ul><li>Take a mental break from the situation and do something that makes you feel good — like listening to music, taking a walk, or calling a friend for a chat.</li><li>This won’t make stress go away, but it can make you feel better and think more positively.</li></ul>',
          },
        ],
      },
      {
        id: 'sl-06',
        type: 'static',
        title: 'Stay Less Stress&shy;ed',
        copy: '<p>Since stress can come and go, it’s important to find ways to manage your stress levels every day. Here are some ways that you can do <strong>more</strong> to stress less:</p><ul><li><strong>Move more.</strong> Physical activity can lower your stress level right away.</li><li><strong>Sleep more.</strong> A good night’s sleep of 7 hours or more is key to controlling your stress.</li><li><strong>Connect more.</strong> Spending time with friends and family can be a huge stress reliever. And feeling connected to others can help you feel calmer.</li><li><strong>Unplug more.</strong> Take a screen break by turning off your electronic devices.</li><li><strong>Journal more.</strong> It might help to stop, reflect, and write about what going on for you.</li></ul>',
      },
      {
        id: 'sl-08',
        type: 'static-image',
        title: 'Speak Up',
        copy: '<p>Here’s something you might not know: research shows that connecting with others can help you deal with stress. Whether it’s friends, family, your church community, or a mental health professional — getting support from people you trust can go a long way toward helping you manage stress.</p><p>So try connecting with people who lift you up! And remember, it’s always okay to ask for support.</p>',
        imageURL: 'static-image-sl-08-b.jpg',
        imageAlt: 'Two men laughing and smiling at each other.',
      },
      {
        id: 'sl-10',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>How you manage your stress is up to you! So what’s the first small step you’ll take to <strong>stress less</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'sl-10-A',
            copy: '<p><strong>Take a walk or roll</strong> around the block, at least 3 times a week</p>',
          },
          {
            id: 'sl-10-B',
            copy: '<p><strong>Do screen-free family mealtimes</strong> during weeknights</p>',
          },
          {
            id: 'sl-10-C',
            copy: '<p><strong>Call or video chat</strong> with someone I love every weekend</p>',
          },
          {
            id: 'sl-10-D',
            copy: '<p><strong>Listen to a meditation book, video, or podcast</strong> while I get ready in the morning</p>',
          },
          {
            id: 'sl-10-E',
            copy: '<p>Pick 1 evening a week to <strong>do a calming activity</strong> — like knitting, painting, or journaling</p>',
          },
          {
            id: 'sl-10-F',
            copy: "<p><strong>Build a <a href='https://www.heart.org/en/healthy-living/healthy-lifestyle/sleep/how-to-sleep-better-with-a-bedtime-routine' target='_blank'>sleep routine</a></strong> to help unwind before bed every night</p>",
          },
          {
            id: 'sl-10-G',
            copy: "<p>Take a minute to <strong><a href='https://www.youtube.com/watch?v=1Q4ECKfu_2Q' target='_blank'>practice gratitude</a></strong> everyday</p>",
          },
          {
            id: 'sl-10-H',
            copy: "<p><strong>Connect with a health care professional</strong> to <a href='https://www.nimh.nih.gov/health/publications/tips-for-talking-with-your-health-care-provider' target='_blank'>start the conversation</a> about my mental health this week</p>",
          },
        ],
      },
    ],
  },
  [TopicKeys.QUIT_SMOKING]: {
    title: 'Quit Smoking',
    id: 'quit-smoking',
    sectionPrefix: 'qs',
    intro:
      '<p>Quitting smoking isn’t easy, but it’s possible. It might take several tries before you quit for good. But <strong>you can do it</strong> — and the benefits for your health will be huge.</p>',
    heroImg: 'hero-quit-smoking.jpg',
    heroAlt: "A date on the calendar marked 'quit smoking'",
    sections: [
      {
        id: 'qs-01',
        type: 'fast-facts',
        title: 'Smoking and Health',
        facts: [
          {
            icon: 'QUIT_SMOKING',
            copy: '<p>Smoking is the leading cause of preventable of death in the United States. Every year, smoking and secondhand smoke cause more than 480,000 deaths.</p>',
          },
          {
            icon: 'QUIT_SMOKING',
            copy: '<p>Smoking harms almost every organ in the body, causing serious health problems — like cancer, heart disease, stroke, lung diseases, and diabetes.</p>',
          },
          {
            icon: 'QUIT_SMOKING',
            copy: '<p>There are more than 7,000 chemicals in cigarette smoke, and many of them can affect heart health. For example, smoking can raise your risk of having a heart attack by impacting your cholesterol levels, making your blood sticky and more likely to clot, and causing your blood vessel walls to thicken.</p>',
          },
        ],
      },
      {
        id: 'qs-02',
        type: 'static',
        title: 'Quit for Your Heart',
        copy: '<p>There are lots of benefits to quitting smoking, so let’s get right to the, well&hellip; heart of it! Quitting smoking lowers your risk of heart disease, heart attack, and stroke.</p><p>And if you already have heart disease, quitting smoking can lower your risk of having a heart attack or dying from heart disease.</p><p>That’s why it’s so important to show your heart some love by quitting smoking.</p>',
      },
      {
        id: 'qs-03',
        type: 'flip-all',
        title: 'Quit for Your Health',
        copy: '<p>Smoking doesn’t just affect your lungs and your heart — it harms your whole body. The good news? Your whole body benefits when you quit!</p><p>Learn how quitting smoking can help you&hellip;</p>',
        afterCopy: 'And those are just a few examples of benefits — there are lots more!',
        answers: [
          {
            id: 'qs-03-A',
            copy: 'Breathe easier',
            result:
              '<p>When you smoke, you breathe in toxins that harm your airway and lungs. Though you can’t undo the harm smoking has done to your lungs, quitting can stop it from getting worse. And within a few months, you should be breathing better while doing activities like walking up a flight of stairs.</p>',
          },
          {
            id: 'qs-03-B',
            copy: 'Get sick less often',
            result:
              '<p>The tar and nicotine in cigarette smoke can harm your immune system, making it easier for you to get sick. Quitting smoking can help your body fight infections, meaning you’re less likely to pick up lung infections or that cold that’s going around.</p>',
          },
          {
            id: 'qs-03-C',
            copy: 'Keep your eyes healthy',
            result:
              '<p>When you smoke, your eyes come in contact with toxins that can increase your chances of developing some eye conditions, like age-related macular degeneration and cataracts. Quitting smoking may lower your risk for these conditions.</p>',
          },
          {
            id: 'qs-03-D',
            copy: 'Feel better about yourself',
            result:
              '<p>Quitting smoking frees up time and energy for other things. Whether you pick up a new hobby or spend more time with people you love, quitting smoking can help you feel better about you.</p>',
          },
        ],
      },
      {
        id: 'qs-05',
        type: 'flip-all',
        title: 'I Quit!',
        copy: '<p>Now that you have your “why” for quitting, it’s time to focus on your “I” — which is <strong>you</strong>! The decision to quit smoking starts with you and these 4 steps:</p>',
        answers: [
          {
            id: 'qs-05-A',
            copy: 'Pick your quit date',
            result:
              '<ul><li>Pick a date in the next 2 weeks and mark it on your calendar. Try to choose a less busy day in your schedule.</li><li>Use the time before your quit date to get ready. You can plan to quit all at once — or slowly reduce how much you smoke until you stop completely on your quit date.</li></ul>',
          },
          {
            id: 'qs-05-B',
            copy: 'Get the support you need',
            result:
              '<ul><li>People who get counseling to stop smoking are more likely to quit for good. So don’t go it alone — reach out for help!</li><li>There are special counselors who are trained to support people who want to quit. They can help you make a quit plan, stay on track, and cope with triggers and slips along the way.</li><li>You can also talk with a doctor or mental health professional to find support.</li></ul>',
          },
          {
            id: 'qs-05-C',
            copy: 'Consider using a quit-smoking medicine',
            result:
              '<ul><li>Quit-smoking medicines can double your chances of quitting successfully, and there are several options to try.</li><li>Nicotine replacement therapy (NRT) is a type of quit-smoking medicine. You can buy 3 types of NRT — gum, lozenges, and patches — online or over the counter at your local drugstore.</li><li>Doctors can also prescribe pills to help you quit smoking. You may need to start taking these pills a week or 2 before your quit date. Talk with a doctor or pharmacist about which medicine options are best for you.</li></ul>',
          },
          {
            id: 'qs-05-D',
            copy: 'Trash your triggers',
            result:
              '<ul><li>On your quit date, get rid of all your cigarettes and everything else that reminds you of smoking. Remove ashtrays, lighters, and matches from your home and car.</li><li>Try putting NRT gum or lozenges in your glove compartment, coat pockets, and any other places you used to keep cigarettes or lighters.</li></ul>',
          },
        ],
      },
      {
        id: 'qs-06',
        type: 'static',
        title: 'Double Up',
        copy: "<p>When it comes to quitting smoking, there’s no single right way to do it. But there is a way to increase your chances of success: use quit-smoking medicines <strong>and</strong> get counseling.</p><p>Here are some free resources that can help you quit:</p><ul><li>Call 1-800-QUIT-NOW (1-800-784-8669) to get <a href='https://www.cdc.gov/tobacco/campaign/tips/quit-smoking/quitline/index.html' target='_blank'>expert phone counseling support</a>.</li><li>Check out the <a href='https://livehelp.cancer.gov/app/chat/chat_launch' target='_blank'>LiveHelp chat service</a> to talk online with a trained counselor.</li><li>Text QUITNOW (English) or DÉJELO YA (Spanish) to 333888 for <a href='https://www.cdc.gov/tobacco/campaign/tips/quit-smoking/national-texting-portal.html' target='_blank'>text message support</a> to help you stay motivated.</li><li>Use the <a href='https://smokefree.gov/tools-tips/apps/quitstart' target='_blank'>quitSTART smartphone app</a> to track your progress and get helpful tips.</li><li>Join a <a href='https://smokefree.gov/about-us/social' target='_blank'>smokefree social media community</a> to share your experiences and connect with other people who are trying to quit.</li></ul>",
      },
      {
        id: 'qs-07',
        type: 'flip',
        title: 'You Can Handle It',
        copy: '<p>Let’s be real: Staying smokefree probably won’t always be easy. But there are tried-and-true strategies that can help! Just try to focus on managing&hellip;</p>',
        answers: [
          {
            id: 'qs-07-A',
            copy: 'Withdrawal symptoms',
            result:
              '<p>Many people have withdrawal symptoms, which can be hard. The good news is these symptoms usually get better a few weeks after quitting, and there are healthy ways to manage symptoms.</p>',
          },
          {
            id: 'qs-07-B',
            copy: 'Cravings',
            result:
              '<p>Cravings can be tough, but they fade over time — so try to think about the bigger picture. Keep track of things that trigger your cravings and find healthy ways to cope.</p>',
          },
          {
            id: 'qs-07-C',
            copy: 'Self-doubt',
            result:
              '<p>If you don’t stay smokefree this time, don’t let it get you down. Remember, you’re  doing something that’s hard! It’s very common to need several tries to quit for good. <strong>The key is to learn from each attempt and always try again.</strong></p>',
          },
        ],
      },
      {
        id: 'qs-08',
        type: 'flip',
        title: 'The Time Is Now',
        copy: '<p>You probably know that quitting smoking lowers your risk of health problems like lung cancer. But there’s so much more to look forward to! See how ditching the cigarettes can benefit your heart in&hellip;</p>',
        answers: [
          {
            id: 'qs-08-A',
            copy: '20 minutes',
            result: '<p>Your blood pressure and heart rate go down. That’s just 20 minutes!</p>',
          },
          {
            id: 'qs-08-B',
            copy: '1 day',
            result: '<p>There’s no more nicotine in your blood.</p>',
          },
          {
            id: 'qs-08-C',
            copy: '1 to 2 years',
            result: '<p>Your risk of having a heart attack drops sharply.</p>',
          },
          {
            id: 'qs-08-D',
            copy: '3 to 6 years',
            result:
              '<p>Your added risk of the most common kind of heart disease (called coronary heart disease) is cut in half.</p>',
          },
          {
            id: 'qs-08-E',
            copy: '5 to 10 years',
            result: '<p>Your risk of stroke goes down.</p>',
          },
          {
            id: 'qs-08-F',
            copy: '15 years',
            result:
              '<p>Your risk of coronary heart disease goes back to about the same as someone who doesn’t smoke.</p>',
          },
        ],
      },
      {
        id: 'qs-11',
        type: 'checklist',
        title: 'My Small Step',
        copy: '<p>Congrats on committing to this key step for your heart health! What’s the first small step you’ll take to <strong>quit smoking</strong>?</p><p>I will:</p>',
        answers: [
          {
            id: 'qs-11-A',
            copy: '<p><strong>Pick my quit date</strong> in the next few weeks</p>',
          },
          {
            id: 'qs-11-B',
            copy: '<p><strong>Make an appointment</strong> with my doctor to learn about strategies that can help me quit</p>',
          },
          {
            id: 'qs-11-C',
            copy: "<p><strong>Call 1-800-QUIT-NOW (1-800-784-8669)</strong> to <a href='https://www.cdc.gov/tobacco/campaign/tips/quit-smoking/quitline/index.html' target='_blank'>get expert phone counseling support</a></p>",
          },
          {
            id: 'qs-11-D',
            copy: "<p><strong>Use the <a href='https://livehelp.cancer.gov/app/chat/chat_launch' target='_blank'>LiveHelp chat service</a></strong> to talk online with a trained counselor</p>",
          },
          {
            id: 'qs-11-E',
            copy: "<p><strong>Download the <a href='https://smokefree.gov/tools-tips/apps/quitstart' target='_blank'>quitSTART smartphone app</a></strong> to track my progress and get helpful tips</p>",
          },
          {
            id: 'qs-11-F',
            copy: "<p><strong>Text QUITNOW (English) or DÉJELO YA (Spanish)</strong> to 333888 for <a href='https://www.cdc.gov/tobacco/campaign/tips/quit-smoking/national-texting-portal.html' target='_blank'>text message support</a></p>",
          },
          {
            id: 'qs-11-G',
            copy: "<p><strong>Join a <a href='https://smokefree.gov/about-us/social' target='_blank'>smokefree social media community</a></strong> to share my experiences and connect with others who are trying to quit</p>",
          },
        ],
      },
    ],
  },
};
