import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Navbar } from '..';
import { HistoryContext } from '../../providers';

export const WelcomeBack = () => {
  const { setCodename } = useContext(HistoryContext);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.target as HTMLFormElement);
    const newUserCodename = data.get('codename');
    if (newUserCodename) {
      console.log(newUserCodename.toString());
      setCodename(newUserCodename.toString());

      navigate('/');
    } else {
      console.log('no codename to set');
    }
  };

  return (
    <>
      <Navbar />

      <div className="flex flex-col min-h-screen gap-4 justify-center items-center">
        <h3 className="text-3xl">Welcome back!</h3>
        <form className="flex flex-col gap-4 items-center" onSubmit={handleSubmit}>
          <h3 className="text-2xl">Enter your unique code</h3>
          <input
            type="text"
            name="codename"
            className="text-black bg-white text-3xl font-loos font-bold w-32 p-4 text-center"
          />
          <button type="submit">
            <Button type="cta">Let’s Go!</Button>
          </button>
        </form>
      </div>
    </>
  );
};
