import { Fragment, useContext, useMemo } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { CodeForm, Icon, IconKeys, Navbar, SvgElement } from '..';
import { TOPICS } from '../../data/topics';
import { HistoryContext } from '../../providers';

export const MainMenu = () => {
  const { orderedTopics, progress } = useContext(HistoryContext);

  const finishedSections = useMemo(() => {
    const finishedSections = new Set<string>();

    const unfinishedSections = new Set<string>();
    // First add all sections
    for (const topicId in TOPICS) {
      for (const section of TOPICS[topicId].sections) {
        unfinishedSections.add(section.id);
      }
    }
    // Then remove the finished sections
    for (const section of progress) {
      unfinishedSections.delete(section.section);
    }

    // Grab all unique prefixes to the section ids that are unfinished
    const unfinishedPrefixes = new Set(Array.from(unfinishedSections).map((x) => x.split('-')[0]));

    for (const topicId in TOPICS) {
      if (!unfinishedPrefixes.has(TOPICS[topicId].sectionPrefix)) {
        finishedSections.add(TOPICS[topicId].id);
      }
    }

    return finishedSections;
  }, [progress]);

  // const [promoOpen, setPromoOpen] = useState(false);
  // const onHandlePromoDialogOpen = () => {
  //   setPromoOpen(true);
  // };
  // const onHandlePromoDialogClose = () => {
  //   setPromoOpen(false);
  // };
  // let promo: ReactNode = null;
  if (finishedSections.size > 0) {
    // promo = (
    //   <div className="mb-12">
    //     {/* <PromoDialogCta onClick={onHandlePromoDialogOpen} /> */}
    //     <PromoDialogCta />
    //     <hr className="mt-12" />
    //   </div>
    // );
  }

  return (
    <>
      <Navbar />
      <div className="p-4 pt-20 max-w-xl lg:max-w-5xl mx-auto">
        <h1 className="text-3xl mt-6 mb-12 pr-20 md:pr-0 lg:text-center">Where do you want to jump in?</h1>
        <div className="relative">
          <div className="hidden lg:block absolute inset-16">
            <SvgElement type="map-bg-curve" />
          </div>
          <div className="block lg:hidden absolute inset-[4.5rem] border-l border-white"></div>
          <ul className="relative grid grid-cols-1 lg:grid-cols-6 lg:gap-y-8">
            {orderedTopics.map((subject, index) => {
              const MapItem = () => (
                <Link
                  key={subject.id}
                  to={`/topic/${subject.id}`}
                  className={clsx('col-span-2', {
                    'order-1': index === 0,
                    'order-2': index === 1,
                    'order-3': index === 2,
                    'order-4 lg:order-5': index === 3,
                    'order-5 lg:order-4': index === 4,
                    'order-6': index === 5,
                    'order-7': index === 6,
                    'order-8': index === 7,
                  })}
                >
                  <li className="flex items-center lg:flex-col gap-4 pb-4 group">
                    <div className="w-[6.25rem] lg:w-[9.25rem] aspect-square border lg:border-2 border-white bg-black group-hover:bg-primary-1 rounded-full flex justify-center items-center">
                      <div className="w-16 aspect-square fill-white">
                        <Icon icon={subject.id} />
                      </div>
                    </div>
                    <h3 className="flex-1 flex items-center gap-2">
                      {finishedSections.has(subject.id) && (
                        <div className="w-8 aspect-square fill-primary-2">
                          <Icon icon={IconKeys.CIRCLE_CHECK} />
                        </div>
                      )}
                      <span>{subject.name}</span>
                    </h3>
                    <div className="lg:hidden">&gt;</div>
                  </li>
                </Link>
              );

              const Space = () => <div className="hidden lg:block lg:col-span-1 order-4">&nbsp;</div>;

              if (index === 3) {
                return (
                  <Fragment key={index}>
                    <Space />
                    <MapItem />
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={index}>
                    <MapItem />
                  </Fragment>
                );
              }
            })}
          </ul>
        </div>

        <div className="my-40">
          {/* {promo} */}
          {/* {promoOpen && <PromoDialog onClose={onHandlePromoDialogClose} />} */}
          <CodeForm />
        </div>
      </div>
    </>
  );
};
