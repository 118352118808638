import { PropsWithChildren, useContext, useState } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';

import { Button, Icon, IconKeys, TopicIconWidget } from '../../components';
import { FactsIcon, FactsIconKeys } from '../../components/layout';
import styles from '../../components/topics/topic-section.module.css';
import { AnswerProps, SectionProps, TopicKeys } from '../../data/topics';
import { CompleteEvent, HistoryContext } from '../../providers';

const gridVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const answerVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

export const TopicSection = ({
  section,
  topicSlug,
  nextSectionId,
  index,
}: {
  section: SectionProps;
  topicSlug: TopicKeys;
  nextSectionId: string;
  index: number;
}) => {
  const { progress, completeEvent } = useContext(HistoryContext);
  const [allFlipped, setAllFlipped] = useState(false);
  const [answer, setAnswer] = useState<AnswerProps | null>(null);
  let sectionContent;

  const handleClick = (answer: AnswerProps) => {
    setAnswer(answer);
    setAllFlipped(true);
    completeEvent({ section: section.id, answer: answer.id, points: 20 });
  };

  switch (section.type) {
    case 'static':
    case 'static-long':
    case 'static-extralong': {
      sectionContent = (
        <div className="flex flex-col md:flex-row gap-y-4 gap-x-8">
          <div
            className={clsx({
              'md:w-1/3': section.type === 'static',
              'md:w-1/2': section.type === 'static-long',
              'md:w-3/5': section.type === 'static-extralong',
            })}
          >
            <TopicIconWidget topicSlug={topicSlug} />
            <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4" />
          </div>
          {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} className="md:flex-1" />}
        </div>
      );
      break;
    }

    case 'static-image': {
      sectionContent = (
        <div className="flex flex-col md:flex-row md:items-stretch">
          <div className="md:w-1/2">
            <img src={`/images/${section.imageURL}`} alt={section.imageAlt} className="object-cover w-full h-full" />
          </div>
          <div className="md:flex-1 p-6 md:p-20">
            {section.title && (
              <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4" />
            )}
            {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} />}
          </div>
        </div>
      );
      break;
    }

    case 'fast-facts': {
      sectionContent = (
        <div className="flex flex-col md:flex-row gap-y-4 gap-x-8">
          <div className="md:min-w-[33%] md:max-w-min">
            <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4" />
            <TopicIconWidget topicSlug={topicSlug} />
          </div>
          <div className="flex-1 flex flex-col gap-4 divide-y divide-black">
            {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} className="md:flex-1" />}
            {section.facts &&
              section.facts.map((fact, index) => (
                <div className="flex py-2 flex-row gap-4 items-center text-xl" key={index}>
                  {fact.icon && <FactsIcon icon={fact.icon as FactsIconKeys} />}
                  <div dangerouslySetInnerHTML={{ __html: fact.copy }} className="flex-1" />
                </div>
              ))}
            {section.afterCopy && (
              <div dangerouslySetInnerHTML={{ __html: section.afterCopy }} className="md:flex-1 pt-4" />
            )}
          </div>
        </div>
      );
      break;
    }

    case 'flip': {
      // Flip answers
      const answers = section.answers?.length || 0;
      sectionContent = (
        <div className="flex flex-col">
          <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4 text-center" />
          {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} />}
          <motion.div
            variants={gridVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            className={clsx('grid gap-4 mt-12', {
              'grid-cols-1 sm:grid-cols-2': answers === 2,
              'grid-cols-1 sm:grid-cols-2 md:grid-cols-3': answers > 2,
            })}
          >
            {section.answers?.map((answer) => <FlipAnswer answer={answer} sectionId={section.id} key={answer.id} />)}
          </motion.div>
          {section.afterCopy && <div className="mt-6" dangerouslySetInnerHTML={{ __html: section.afterCopy }} />}
        </div>
      );
      break;
    }

    case 'flip-all':
    case 'flip-all-image-back': {
      // Flip All answers
      const flipAllAnswers = section.answers?.length || 0;
      sectionContent = (
        <div className={clsx(allFlipped && 'pointer-events-none', 'flex flex-col', styles.backface)}>
          <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4 text-center" />
          {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} />}
          <motion.div
            variants={gridVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            className={clsx('grid gap-4 mt-12', {
              'grid-cols-2': flipAllAnswers === 2,
              'grid-cols-2 md:grid-cols-3': flipAllAnswers % 3 === 0 || flipAllAnswers % 5 === 0,
              'grid-cols-2 md:grid-cols-4': flipAllAnswers % 4 === 0,
            })}
          >
            {section.answers?.map((answer) => (
              <FlipAllAnswer answer={answer} key={answer.id} onClick={() => handleClick(answer)} />
            ))}
          </motion.div>
          {section.afterCopy && <div className="mt-6" dangerouslySetInnerHTML={{ __html: section.afterCopy }} />}
        </div>
      );
      break;
    }

    case 'video': {
      // YouTube video
      sectionContent = (
        <div className="flex flex-col gap-8">
          <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4 text-center" />
          {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} />}
          <div className="aspect-video">
            <iframe
              width="100%"
              height="100%"
              src={'https://www.youtube-nocookie.com/embed/' + section.youtubeId}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          {section.afterCopy && <div dangerouslySetInnerHTML={{ __html: section.afterCopy }} />}
        </div>
      );
      break;
    }

    case 'checklist': {
      // Checklist
      sectionContent = (
        <div className="flex flex-col gap-4">
          <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4 text-center" />
          {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} />}
          <div className="flex flex-col gap-2">
            {section.answers?.map((answer) => (
              <div className="flex items-center gap-4" key={answer.id}>
                <div className="custom-checkbox">
                  <input type="checkbox" id={answer.id} className="appearance-none absolute w-8 h-8" />
                  <div className="w-8 h-8 rounded-full bg-white border border-neutral-4 fill-primary-1">
                    <div className="invisible">
                      <Icon icon={IconKeys.CIRCLE_CHECK} />
                    </div>
                  </div>
                </div>
                <label htmlFor={answer.id} className="flex-1" dangerouslySetInnerHTML={{ __html: answer.copy }} />
              </div>
            ))}
          </div>
        </div>
      );
      break;
    }

    case 'multi': {
      // Multiple Choice
      sectionContent = (
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:flex-1">
            <TopicIconWidget topicSlug={topicSlug} />
            <h3 dangerouslySetInnerHTML={{ __html: section.title }} className="text-6xl uppercase my-4" />
            {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy }} />}
          </div>
          <motion.div
            variants={gridVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            className="md:flex-1 flex flex-col justify-end gap-4"
          >
            {section.answers?.map((answer, index) => (
              <SectionAnswer answer={answer} sectionId={section.id} index={index} key={answer.id} />
            ))}
          </motion.div>
        </div>
      );
      break;
    }
  }

  const answerIndex = progress.findIndex((e) => e.section === section.id);
  const answered = answerIndex > -1;
  const answerId = answered ? progress[answerIndex].answer : null;
  const sectionResult = section.answers?.find((a) => a.id === answerId)?.result;
  const answerIsCorrect = section.answers?.find((a) => a.id === answerId)?.correct;

  return (
    <div
      id={`section-${section.id}`}
      className={clsx('p-4 pb-16 md:py-20 md:px-4 flex flex-col md:justify-center snap-start min-h-screen', {
        'bg-primary-1': index % 3 === 0,
        'bg-black': index % 3 === 1,
        'bg-secondary': index % 3 === 2,
      })}
    >
      <div
        // flip-card
        className={clsx(styles.perspective)}
      >
        <div
          // flip-card-inner
          className={clsx(
            allFlipped && styles.flippedY,
            allFlipped && 'bg-transparent',
            styles.perserve3d,
            'relative bg-neutral-1 text-black text-lg w-full md:max-w-5xl mx-auto rounded-tl-3xl rounded-br-3xl shadow-lg transition duration-700',
            section.type !== 'static-image' ? 'p-6 md:p-20' : 'overflow-hidden',
          )}
        >
          {/* flip-card-front */}
          {sectionContent}
          {sectionResult && [null, 'multi'].includes(section.type || null) && (
            <div
              className={clsx(
                {
                  'bg-green-600': answerIsCorrect,
                  'bg-secondary-2': !answerIsCorrect,
                },
                'text-white rounded-xl p-4 mt-12',
              )}
              dangerouslySetInnerHTML={{ __html: sectionResult }}
            />
          )}
          {section.type === 'flip-all' && (
            <button
              // flip-card-back
              className={clsx(
                styles.flippedY,
                styles.backface,
                'absolute inset-0 bg-white text-black p-6 pt-24 pb-10 md:p-20',
                'md:rounded-tl-3xl md:rounded-br-3xl shadow-lg',
              )}
              onClick={() => setAllFlipped(false)}
            >
              <div className="block sm:hidden w-full text-center text-sm text-secondary-2 mb-8">(tap to flip back)</div>
              <div className="w-full h-full flex flex-col md:flex-row gap-y-4 gap-x-8">
                {answer?.result && (
                  <div className="w-full md:w-1/2">
                    <div className="flex justify-center md:justify-start">
                      <TopicIconWidget topicSlug={topicSlug} />
                    </div>
                    <h2 className="text-4xl md:text-6xl uppercase md:text-left">{answer?.copy}</h2>
                  </div>
                )}
                {answer?.result && (
                  <div
                    className="flex-1 overflow-y-auto text-left"
                    dangerouslySetInnerHTML={{ __html: answer?.result }}
                  />
                )}
                <div className="w-20 h-20 absolute top-0 right-0 rotate-90 bg-white shadow-lg">
                  <div
                    style={{
                      width: 0,
                      height: 0,
                      borderTop: '5rem solid rgb(191 191 191)',
                      borderRight: '5rem solid transparent',
                    }}
                  />
                </div>
              </div>
              <div className="hidden sm:block w-full text-center text-sm text-secondary-2">(click to flip back)</div>
            </button>
          )}
          {section.type === 'flip-all-image-back' && (
            <button
              // flip-card-back (w/ image)
              className={clsx(
                styles.flippedY,
                styles.backface,
                'absolute inset-0 bg-white text-black',
                'md:rounded-tl-3xl md:rounded-br-3xl shadow-lg overflow-hidden',
              )}
              onClick={() => setAllFlipped(false)}
            >
              <div className="w-full h-full flex flex-col md:flex-row gap-y-4 gap-x-8">
                {answer?.imageURL && (
                  <div className="md:w-1/2">
                    <img
                      src={`/images/${answer.imageURL}`}
                      alt={answer.imageAlt}
                      className="object-cover w-full h-full aspect-video md:aspect-auto"
                    />
                  </div>
                )}
                {answer?.result && (
                  <div className="flex-1 flex flex-col gap-8 p-6 py-10 md:p-12">
                    <div className="w-full">
                      <div className="flex justify-center md:justify-start">
                        <TopicIconWidget topicSlug={topicSlug} />
                      </div>
                      <h2 className="text-4xl md:text-6xl uppercase md:text-left">{answer?.copy}</h2>
                    </div>
                    <div className="overflow-y-auto text-left" dangerouslySetInnerHTML={{ __html: answer?.result }} />
                    <div className="w-full text-center text-sm text-secondary-2">
                      (<span className="inline sm:hidden">tap</span>
                      <span className="hidden sm:inline">click</span> to flip back)
                    </div>
                  </div>
                )}
                <div className="w-20 h-20 absolute top-0 right-0 rotate-90 bg-white shadow-lg">
                  <div
                    style={{
                      width: 0,
                      height: 0,
                      borderTop: '5rem solid rgb(191 191 191)',
                      borderRight: '5rem solid transparent',
                    }}
                  />
                </div>
              </div>
            </button>
          )}
          <div className={clsx('block md:hidden text-center my-12', styles.backface)}>
            <NextLink nextSectionId={nextSectionId} section={section} completeEvent={completeEvent}>
              <Button type="white">
                <div className="w-8 text-primary-1">
                  <Icon icon={answered ? IconKeys.CHECK : IconKeys.ARROW_DOWN} />
                </div>
              </Button>
            </NextLink>
          </div>
        </div>
        <div className="text-center my-8 hidden md:block">
          <NextLink nextSectionId={nextSectionId} section={section} completeEvent={completeEvent}>
            <Button type="white">
              <div className="w-8 text-primary-1">
                <Icon icon={answered ? IconKeys.CHECK : IconKeys.ARROW_DOWN} />
              </div>
            </Button>
          </NextLink>
        </div>
      </div>
    </div>
  );
};

interface NextLinkProps {
  nextSectionId: string;
  section: SectionProps;
  completeEvent: (completeEvent: CompleteEvent) => void;
}
const NextLink = ({ children, nextSectionId, section, completeEvent }: PropsWithChildren<NextLinkProps>) => (
  <HashLink
    to={`#section-${nextSectionId}`}
    onClick={() => {
      if (
        ['static', 'static-long', 'static-extralong', 'static-image', 'fast-facts', 'video', 'checklist'].includes(
          section.type || '',
        )
      ) {
        completeEvent({
          section: section.id,
          points: 20,
        });
      }
    }}
  >
    {children}
  </HashLink>
);

const FlipAnswer = ({ answer, sectionId }: { answer: AnswerProps; sectionId: string }) => {
  const { completeEvent } = useContext(HistoryContext);

  const [flipped, setFlipped] = useState(false);

  const handleFlip = (event: CompleteEvent) => {
    completeEvent(event);
    setFlipped(!flipped);
  };

  return (
    <motion.div
      variants={answerVariants}
      className={clsx({ [styles.flipped]: flipped }, styles.flipcard)}
      onClick={() =>
        handleFlip({
          section: sectionId,
          answer: answer.id,
          points: 20,
        })
      }
    >
      <div className={styles.flipcardInner}>
        <div
          className={clsx(
            styles.flipcardFront,
            answer.bgImageURL ? 'text-white bg-cover bg-blend-soft-light bg-gray-600' : 'bg-white',
          )}
          style={{ backgroundImage: answer.bgImageURL && `url(/images/${answer.bgImageURL})` }}
        >
          {answer.copy}
        </div>
        <div className={styles.flipcardBack}>
          <div className={styles.flipcardResult} dangerouslySetInnerHTML={{ __html: answer.result || '' }} />
        </div>
      </div>
    </motion.div>
  );
};

const FlipAllAnswer = ({ answer, onClick }: { answer: AnswerProps; onClick: () => void }) => {
  return (
    <motion.div
      variants={answerVariants}
      className={clsx(
        'w-full aspect-square text-lg leading-6 sm:text-xl lg:text-2xl font-loos font-bold p-4 text-center rounded-tl-3xl rounded-br-3xl shadow hover:shadow-lg cursor-pointer flex flex-col justify-center overflow-hidden',
        answer.bgImageURL ? 'text-white bg-cover bg-blend-soft-light bg-gray-600' : 'bg-white',
      )}
      style={{ backgroundImage: answer.bgImageURL && `url(/images/${answer.bgImageURL})` }}
      onClick={onClick}
      role="button"
    >
      {answer.copy}
    </motion.div>
  );
};

const SectionAnswer = ({ answer, sectionId, index }: { answer: AnswerProps; sectionId: string; index: number }) => {
  const { progress, completeEvent } = useContext(HistoryContext);
  const answered = progress.findIndex((e) => e.section === sectionId && e.answer === answer.id) > -1;

  return (
    <motion.div
      variants={answerVariants}
      className="bg-white flex items-center gap-4 p-4 text-base font-bold rounded-tl-3xl rounded-br-3xl shadow-xl hover:shadow-lg group cursor-pointer select-none"
      onClick={() =>
        completeEvent({
          section: sectionId,
          answer: answer.id,
          points: 20,
        })
      }
    >
      <div
        className={clsx(
          'flex-none border-2 border-black flex justify-center items-center rounded-full w-8 aspect-square',
          {
            'bg-green-600 text-white': answer.correct && answered,
            'bg-red-600 text-white': !answer.correct && answered,
            'group-hover:bg-primary-1': !answered,
          },
        )}
      >
        {(index + 10).toString(36).toUpperCase()}
      </div>
      <div>{answer.copy}</div>
    </motion.div>
  );
};
