import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { Button, Navbar, SubjectButton } from '..';
import { SUBJECTS } from '../../App';
import { TOPICS } from '../../data/topics';
import { HistoryContext } from '../../providers';

export const TrophyRoom = () => {
  const { progress } = useContext(HistoryContext);

  const completedTopics = SUBJECTS.filter((subject) => {
    const completedSections = progress.filter((e) => e.section.startsWith(subject.answerKey)).length;
    const totalSections = TOPICS[subject.id].sections.length;

    return completedSections === totalSections && totalSections > 0;
  });

  return (
    <>
      <Navbar />
      <div className="p-4 pt-20 max-w-xl lg:max-w-5xl mx-auto">
        <div className="mt-20 text-center">
          <Link to="/">
            <Button type="cta">Return to your journey</Button>
          </Link>
        </div>
        <div className="bg-neutral-1 text-black rounded-tl-3xl rounded-br-3xl mt-20 text-center p-12">
          <h1 className="text-3xl text-center">Trophy Room</h1>

          {completedTopics.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2">
              {completedTopics.map((topic) => (
                <div className="p-12" key={topic.id}>
                  <SubjectButton subject={topic} trophy />
                </div>
              ))}
            </div>
          ) : (
            <p className="my-8">
              You have not yet completed any{' '}
              <Link to="/" className="underline">
                topics
              </Link>
              . Complete each topic's questions and return to see your progress.
            </p>
          )}
        </div>
      </div>
    </>
  );
};
