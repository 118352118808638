import { useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Button, Icon, Navbar, TopicNext, TopicSection } from '..';
import { TOPICS, TopicKeys, TopicProps } from '../../data/topics';

export const TopicPage = () => {
  const { topicSlug } = useParams<{ topicSlug: string }>();
  if (!topicSlug) return null;
  const topic: TopicProps = TOPICS[topicSlug];

  return (
    <>
      <div className="pb-20 snap-start">
        <Navbar />
      </div>
      <TopicIntro topic={topic} topicSlug={topicSlug as TopicKeys} />
      {topic.sections.map((section, index) => {
        const nextSectionId = topic.sections[index + 1]?.id || 'next';

        return (
          <TopicSection
            section={section}
            topicSlug={topicSlug as TopicKeys}
            nextSectionId={nextSectionId}
            index={index}
            key={section.id}
          />
        );
      })}
      <TopicNext currentTopic={topic} />
    </>
  );
};

const TopicIntro = ({ topic, topicSlug }: { topic: TopicProps; topicSlug: TopicKeys }) => {
  const firstSectionId = topic.sections[0]?.id;

  return (
    <div className="flex flex-col md:flex-row md:max-w-5xl mx-auto md:my-10 justify-center overflow-y-hidden">
      <div className="flex-1 mx-auto pt-4 pl-4 md:pl-0 pr-16 md:pr-0">
        <h1 className="text-6xl md:text-[5.25rem] md:leading-[4.5rem] mb-3 md:mr-10 uppercase break-words">
          {topic.title}
        </h1>

        {topic.intro && (
          <div className="relative z-30 bg-white text-black text-lg mx-auto md:mt-10 p-7 pb-12 md:pb-7 rounded-tl-3xl rounded-br-3xl md:translate-x-20">
            <div dangerouslySetInnerHTML={{ __html: topic.intro }} />

            <div className="absolute md:hidden -bottom-10 right-10">
              <TopicIconWidget topicSlug={topicSlug} />
            </div>

            <div className="hidden md:block mt-4">
              <CtaButton to={firstSectionId} />
            </div>
          </div>
        )}
      </div>

      <div className="flex-1">
        <div className="relative w-full mx-auto overflow-visible -mt-5 md:-mt-0">
          <img src={`/images/${topic.heroImg}`} alt={topic.heroAlt} className="rounded-tl-3xl rounded-br-3xl shadow" />
          <div className="absolute md:hidden bottom-6 left-1/2 -translate-x-1/2">
            <CtaButton to={firstSectionId} />
          </div>
          <div className="hidden md:block absolute top-5 -left-10">
            <TopicIconWidget topicSlug={topicSlug} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CtaButton = ({ to }: { to: string }) => (
  <HashLink to={`#section-${to}`}>
    <Button type="cta">Let’s&nbsp;Jump&nbsp;In!</Button>
  </HashLink>
);

export const TopicIconWidget = ({ topicSlug }: { topicSlug: TopicKeys }) => (
  <div className="w-20 aspect-square bg-primary-1 rounded-tl-3xl rounded-br-3xl flex justify-center items-center">
    <div className="w-14">
      <Icon icon={topicSlug} />
    </div>
  </div>
);
