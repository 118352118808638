import { useContext } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Navbar, SubjectButton } from '..';
import { SUBJECTS } from '../../App';
import { HistoryContext } from '../../providers';

export const OnboardingWizard = () => (
  <>
    <div className="relative bg-cover bg-center bg-[url('../public/images/hero-onboarding.jpg')] text-white">
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <Navbar />
      <div className="relative min-h-screen h-full p-4 flex flex-col justify-between">
        <div></div>
        <div className="md:max-w-5xl mx-auto">
          <p className="bg-white w-72 md:w-3/5 text-black p-4 rounded-tl-2xl rounded-br-2xl text-lg md:text-3xl shadow-xl">
            Everyone’s journey to better heart health is unique. It’s about figuring out what works best for you. Let’s
            get started.
          </p>
          <h1 className="text-6xl md:text-8xl font-bold md:w-5/6 mt-8 text-shadow-dk">
            It’s about living to your beat.
          </h1>
        </div>
        <div className="text-center p-4">
          <Link to="/code" className="underline">
            Already have a code name?
          </Link>
        </div>
      </div>
    </div>

    <div className="min-h-screen flex flex-col justify-center">
      <div className="mx-auto md:max-w-4xl w-full px-4 flex flex-col gap-4 text-center">
        <h3 className="text-3xl">I want to learn how to:</h3>
        <h4 className="text-xl">(Select all that apply)</h4>
        <PreferenceForm />
      </div>
    </div>
  </>
);

const PreferenceForm = () => {
  const { setPreferences } = useContext(HistoryContext);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setTimeout(() => {
      const data = new FormData(event.target as HTMLFormElement);

      const filteredSubjects = SUBJECTS.filter((subject) => {
        return data.get(subject.id) === 'on';
      }).map((subject) => subject.id);

      setPreferences({ onboarded: true, interests: filteredSubjects });
    }, 2500);

    // once the preference is set, the `/loading` route will no longer be valid
    // and you will be redirected to the default root route in ReturningUserRouter
    navigate('/loading', { replace: true });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 grid-cols-2 md:grid-cols-4">
        {SUBJECTS.map((subject) => (
          <div key={subject.id}>
            <input type="checkbox" id={subject.id} name={subject.id} className="invisible absolute" />
            <label htmlFor={subject.id}>
              <SubjectButton subject={subject} color={subject.color} choice />
            </label>
          </div>
        ))}
      </div>
      <div className="text-center p-8">
        <button
          type="submit"
          className="relative bg-white text-primary-2 rounded-3xl px-7 py-4 font-loos font-bold text-xl"
        >
          Continue
        </button>
      </div>
    </form>
  );
};
