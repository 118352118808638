import { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import {
  Icon,
  IconKeys,
  MainMenu,
  MyFitnessPalThankYou,
  OnboardingWizard,
  TopicPage,
  TrophyRoom,
  WelcomeBack,
} from './components';
import { TopicKeys } from './data/topics';
import { HistoryContext, HistoryProvider } from './providers';

export default function App() {
  return (
    <BrowserRouter>
      <AnalyticsProvider>
        <HistoryProvider>
          <RouterChoice />
        </HistoryProvider>
      </AnalyticsProvider>
    </BrowserRouter>
  );
}

const AnalyticsProvider = (props: PropsWithChildren<Record<never, never>>) => {
  const location = useLocation();
  useEffect(() => {
    // @ts-expect-error gtag isn't globally typed
    gtag('set', 'page_path', location.pathname + location.search + location.hash);
    // @ts-expect-error gtag isn't globally typed
    gtag('event', 'page_view');
  }, [location.pathname, location.search, location.hash]);
  return <>{props.children}</>;
};

const RouterChoice = () => {
  const history = useContext(HistoryContext);

  let mainRouter;

  if (history.preferences.onboarded) {
    mainRouter = <ReturningUserRouter />;
  } else {
    mainRouter = <NewUserRouter />;
  }

  return mainRouter;
};

const NewUserRouter = () => (
  <Routes>
    <Route path="" element={<OnboardingWizard />} />
    <Route path="/loading" element={<LoadingScreen />} />
    <Route path="/code" element={<WelcomeBack />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

const ReturningUserRouter = () => (
  <>
    <Routes>
      <Route path="" element={<MainMenu />} />
      <Route path="/topic/:topicSlug" element={<TopicPage />} />
      <Route path="/trophies" element={<TrophyRoom />} />
      <Route path="/my-fitness-pal-thank-you" element={<MyFitnessPalThankYou />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
    <GameMenus />
  </>
);

const GameMenus = () => (
  <div className="fixed right-0 top-24 flex flex-col gap-2.5 z-50">
    <HeartPoints />
    <MapLink />
  </div>
);

const HeartPoints = () => {
  const { progress } = useContext(HistoryContext);
  const [points, setPoints] = useState(0);
  const initialPoints = 0;
  useEffect(() => {
    setPoints(progress.map((e) => e.points).reduce((prev, curr) => prev + curr, initialPoints));
  }, [progress]);
  return (
    <HashLink to="/trophies#">
      <div className="bg-secondary-2 text-white w-[5.5rem] h-20 translate-x-2 hover:translate-x-0 transition cursor-pointer select-none rounded-l-xl pl-4 pr-6 py-2 flex flex-col gap-2 justify-center items-center text-2xl font-loos leading-none shadow-xl">
        <div className="w-6">
          <Icon icon={IconKeys.HEART_POINT} />
        </div>
        {points}
      </div>
    </HashLink>
  );
};

const MapLink = () => (
  <HashLink to="/#">
    <div className="bg-primary-1 text-white w-[5.5rem] h-14 translate-x-2 hover:translate-x-0 transition cursor-pointer select-none rounded-l-xl pl-4 pr-6 py-2 flex flex-col justify-center items-center shadow-xl">
      <div className="w-7">
        <Icon icon={IconKeys.MAP} />
      </div>
    </div>
  </HashLink>
);

export interface SubjectProps {
  id: TopicKeys;
  name: string;
  choice: string;
  color: string;
  answerKey: string;
}
export const SUBJECTS: SubjectProps[] = [
  {
    id: TopicKeys.BLOOD_PRESSURE,
    name: 'Blood Pressure',
    choice: 'Control my blood pressure',
    color: 'bg-primary-1',
    answerKey: 'bp',
  },
  {
    id: TopicKeys.MOVE_MORE,
    name: 'Move More',
    choice: 'Move more',
    color: 'bg-primary-2',
    answerKey: 'mm',
  },
  {
    id: TopicKeys.CHOLESTEROL,
    name: 'Cholesterol',
    choice: 'Manage my cholesterol',
    color: 'bg-primary-2 md:bg-primary-1',
    answerKey: 'ch',
  },
  {
    id: TopicKeys.EAT_HEALTHY,
    name: 'Eat Healthy',
    choice: 'Eat healthier',
    color: 'bg-primary-1 md:bg-primary-2',
    answerKey: 'eh',
  },
  {
    id: TopicKeys.STRESS_LESS,
    name: 'Stress Less',
    choice: 'Stress less',
    color: 'bg-primary-1 md:bg-primary-2',
    answerKey: 'sl',
  },
  {
    id: TopicKeys.WORK_WITH_A_DOCTOR,
    name: 'Work with a Doctor',
    choice: 'Work with a doctor',
    color: 'bg-primary-2 md:bg-primary-1',
    answerKey: 'wd',
  },
  {
    id: TopicKeys.BLOOD_SUGAR,
    name: 'Blood Sugar',
    choice: 'Manage my blood sugar',
    color: 'bg-primary-2',
    answerKey: 'bs',
  },
  {
    id: TopicKeys.QUIT_SMOKING,
    name: 'Quit Smoking',
    choice: 'Quit Smoking',
    color: 'bg-primary-1',
    answerKey: 'qs',
  },
];

const LoadingScreen = () => (
  <div className="flex justify-center items-center min-h-screen mx-4">
    <h3 className="text-3xl text-center">Preparing your heart health journey&hellip;</h3>
  </div>
);
