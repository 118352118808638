import { Link } from 'react-router-dom';

import { Button, Navbar } from '..';

export const MyFitnessPalThankYou = () => {
  return (
    <>
      <Navbar />
      <div className="p-4 pt-20 max-w-xl lg:max-w-5xl mx-auto">
        <div className="mt-20 text-center">
          <img src={require('../../assets/logo-myfitnesspal.png')} className="w-96 mb-12 mx-auto" />

          <h1 className="mb-8 text-center text-4xl">Stay tuned!</h1>
          <h2 className="mb-12 text-3xl text-center font-normal">
            Your code will be emailed to you in 2-3 business days.
          </h2>
          <Link to="/">
            <Button type="cta">Return to your journey</Button>
          </Link>
        </div>
      </div>
    </>
  );
};
