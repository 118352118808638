import { TopicKeys } from '../../data/topics';

export enum IconKeys {
  BLOOD_DROP = 'blood-drop',
  CIRCLE_CHECK = 'circle-check',
  CHECK = 'check',
  ARROW_DOWN = 'arrow-down',
  HEART_POINT = 'heart-point',
  MAP = 'map',
  RIBBON = 'ribbon',
  CARET_DOWN = 'caret-down',
  HAMBURGER = 'hamburger',
  HEART = 'heart',
}

interface IconProps {
  icon: TopicKeys | IconKeys;
}

export const Icon = ({ icon }: IconProps) => {
  switch (icon) {
    case IconKeys.BLOOD_DROP:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100">
          <defs>
            <clipPath id="clip-path">
              <path id="Rectangle_1925" d="M0 0h38v57H0z" data-name="Rectangle 1925" transform="translate(0 -.331)" />
            </clipPath>
            <clipPath id="clip-blood-drop">
              <path d="M0 0h100v100H0z" />
            </clipPath>
          </defs>
          <g id="blood-drop" clipPath="url(#clip-blood-drop)">
            <g id="Group_1436" data-name="Group 1436" transform="translate(31 22.331)">
              <g id="Group_1175" clipPath="url(#clip-path)" data-name="Group 1175">
                <path
                  id="Path_708"
                  d="M18.734.007a1.89 1.89 0 0 0-1.457.9c-3.971 6.499-8.238 13.579-11.55 20.371S0 34.527 0 40.089c0 9.427 8.64 16.588 18.892 16.588s18.892-7.161 18.892-16.588c0-5.562-2.414-12.02-5.727-18.811S24.477 7.407 20.506.912a1.889 1.889 0 0 0-1.771-.905Zm.157 5.569c3.469 5.715 7.009 11.671 9.781 17.354C31.877 29.5 34 35.671 34 40.088 34 47.2 27.42 52.9 18.891 52.9S3.778 47.2 3.778 40.088c0-4.418 2.128-10.588 5.333-17.158 2.772-5.684 6.311-11.64 9.78-17.356Zm-.02 15.171A1.889 1.889 0 0 0 17 22.674v3.778h-3.776a1.853 1.853 0 0 0-.2 0 1.892 1.892 0 1 0 .2 3.778H17v3.778a1.889 1.889 0 1 0 3.778 0V30.23h3.778a1.889 1.889 0 1 0 0-3.778H20.78v-3.778a1.89 1.89 0 0 0-1.909-1.928Zm-5.844 16.41a1.892 1.892 0 1 0 .2 3.778h11.332a1.889 1.889 0 1 0 0-3.778H13.224a1.85 1.85 0 0 0-.197-.001Z"
                  data-name="Path 708"
                />
              </g>
            </g>
          </g>
        </svg>
      );

    case TopicKeys.EAT_HEALTHY:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M43.38,21.75a20.47,20.47,0,0,0,2.94-5.12c.57-1.48-.48-2.81-1.32-3.88-.4-.51-1.07-1.36-1-1.63.62-1.46,1.32-4.59,0-7.07A6.42,6.42,0,0,0,39.15.9a10.53,10.53,0,0,0-11.42,5,6.84,6.84,0,0,0-3.87-.65c-3.64.64-6.83,6.42-8.43,9.85A12.11,12.11,0,0,1,13.49,13a6.06,6.06,0,0,0,.32-5.24C12.94,5.7,10.36,2.88,3.08,1.34L1.81,1.07l.07,1.31c.28,5,1.73,8.56,4.31,10.47a7.4,7.4,0,0,0,4.44,1.44,8.62,8.62,0,0,0,1.23-.09A13.6,13.6,0,0,0,14.61,17l0,.09a7.58,7.58,0,0,0-4.29,3.4c-.83-1.79-2.7-4.92-5.84-5.16l-.45,0-.32.3a6.17,6.17,0,0,0-1.55,6.18H.71v1a24.59,24.59,0,0,0,.85,6.42v1.21h.37A23.87,23.87,0,0,0,8.55,40.6v6.71H40V40.16h0a24.1,24.1,0,0,0,7.37-17.41v-1Zm-36-10.51c-1.83-1.35-3-4-3.39-7.63,4.27,1.09,7.09,2.83,8,5a4.1,4.1,0,0,1-.3,3.64A5.54,5.54,0,0,1,7.38,11.24Zm16.83-4a5.64,5.64,0,0,1,3.38.87l1,.67.46-1.13c.1-.24,2.54-6,9.73-4.74A4.58,4.58,0,0,1,42.27,5c1,1.88.3,4.49-.06,5.34-.59,1.36.42,2.63,1.22,3.65.53.67,1.19,1.5,1,1.92a18.68,18.68,0,0,1-3.7,5.84H38a4.13,4.13,0,0,0,.27-.84c.3-1.52-.24-3.18-1.61-4.94l-.4-.51-.63.15a8.33,8.33,0,0,0-6.14,6.14H23.37c-.74-2-2.59-4.65-5.86-5v-.72l-.31,0C19,12.19,21.82,7.62,24.21,7.2ZM36.36,20.5a2.19,2.19,0,0,1-.74,1.25h-4a6.35,6.35,0,0,1,3.91-4A3.94,3.94,0,0,1,36.36,20.5Zm-20-1.75a4.9,4.9,0,0,1,4.86,3H11.85A5.46,5.46,0,0,1,16.32,18.75ZM4.73,17.37c1.95.46,3.34,2.93,3.94,4.38H4.21A4.44,4.44,0,0,1,4.73,17.37Zm5.82,27.94V41.16H38v4.15Zm27.52-6.15H9.93a21.83,21.83,0,0,1-5.88-8.78H29.46v-2h-26a22.32,22.32,0,0,1-.7-4.63H41.5l0,0,0,0h3.69A22.08,22.08,0,0,1,38.07,39.16Z" />
          <rect x="33.23" y="28.38" width="6.58" height="2" />
          <rect x="20.4" y="13.11" width="6.79" height="2" transform="translate(-2.29 23.32) rotate(-50.11)" />
          <rect x="31.03" y="11.2" width="7.47" height="2" transform="translate(-0.57 22.66) rotate(-35.82)" />
          <rect x="33.52" y="6.25" width="3.31" height="2" transform="translate(1.14 18.72) rotate(-30.27)" />
        </svg>
      );

    case TopicKeys.MOVE_MORE:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M33,10a4.76,4.76,0,1,1,4.75-4.76A4.76,4.76,0,0,1,33,10Zm0-7.51a2.76,2.76,0,1,0,2.75,2.75A2.75,2.75,0,0,0,33,2.44Z" />
          <path d="M32.92,20.41c5.28,0,11.83-2.67,14.28-9.79a1,1,0,0,0-.32-1.1,1,1,0,0,0-1.14-.09c-11.46,6.79-17.61.47-17.87.18l-.06,0,0-.06c-3.44-2.71-8.15-3.7-13.27-2.78C8.76,7.76,3.29,11.09.89,15A1,1,0,0,0,1,16.2a1,1,0,0,0,1.14.28c8.85-3.54,13.28-4,16.5-3.29-4.42,5.13-5.71,7.3-4.74,9.24a1.86,1.86,0,0,0,.32.44c.92,4.29-1.32,9.09-4.2,13.18-4.33.67-8.29,1.05-8.35,1.05a1,1,0,0,0-.9,1v8.46a1,1,0,0,0,.57.9.94.94,0,0,0,.43.1,1,1,0,0,0,.64-.24,58.9,58.9,0,0,0,8.77-9.42c2.23-.36,4.51-.8,6.4-1.3,2.5-.67,5.08-3.4,7.3-6.51,2.1,1.42,3.95,2.78,4.23,3.4,1.06,2.29,4.95,13.29,5,13.4a1,1,0,0,0,.94.67h8.9a1,1,0,0,0,.47-1.89c-2.56-1.35-5-3.07-5.24-3.66-.08-.35-.36-1.24-.79-2.6-.6-1.91-1.42-4.52-2.15-7-.67-2.3-3.05-5.41-6.95-9.52.57-1.1,1-2,1.32-2.64A13.49,13.49,0,0,0,32.92,20.41ZM2.75,44.3V39c1.12-.11,3.18-.34,5.53-.67A59.34,59.34,0,0,1,2.75,44.3ZM5.07,13.23A20.9,20.9,0,0,1,14.84,8.7c4.54-.82,8.67,0,11.64,2.36A12.47,12.47,0,0,0,31.78,14c2.71.77,6.9,1,12.3-1.44-3.29,5.29-9.74,6.53-13.85,5.56h-.31a.51.51,0,0,0-.17,0,.62.62,0,0,0-.19.07l-.15.09-.15.13a.91.91,0,0,0-.11.16s0,.05-.06.08c-.32.71-.77,1.62-1.3,2.64-2.1-2.14-4.55-4.52-7.34-7.18l.69-.79a1,1,0,0,0,.22-.91,1,1,0,0,0-.63-.69C17.4,10.51,13.33,10.33,5.07,13.23ZM17,34.66c-1.29.35-2.78.66-4.32.94,2.16-3.44,3.77-7.28,3.69-11,1,.66,2.24,1.49,3.92,2.56.84.53,1.83,1.17,2.84,1.83C21.16,31.8,19,34.15,17,34.66Zm7.26-7.34-2.89-1.86c-2-1.29-4.48-2.86-5.4-3.6a10.28,10.28,0,0,0-.35-1.07c.26-1.05,1.49-2.76,3.49-5.14,2.61,2.49,5.28,5.07,7.64,7.51C26.05,24.5,25.2,25.93,24.3,27.32Zm10,5.6c.74,2.55,1.56,5.17,2.16,7.09.39,1.23.69,2.2.76,2.49.24.94,1.56,2.06,3,3.06H35.73c-.88-2.46-3.87-10.85-4.82-12.91-.47-1-2.22-2.39-4.93-4.21.84-1.28,1.6-2.56,2.27-3.75C31.37,28,33.73,31,34.29,32.92Z" />
        </svg>
      );

    case TopicKeys.STRESS_LESS:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M46.18,47.38H1.82a1,1,0,0,1,0-2H46.18a1,1,0,0,1,0,2Z" />
          <path d="M43.12,43.87H4.88a1,1,0,0,1,0-2H43.12a1,1,0,0,1,0,2Z" />
          <path d="M24,11.62a5.5,5.5,0,1,1,5.5-5.5A5.51,5.51,0,0,1,24,11.62Zm0-9a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,24,2.62Z" />
          <path d="M11.8,43.76l-.23,0c-2.2-.52-3.62-1.83-3.9-3.59a4.81,4.81,0,0,1,2.46-4.71l7.44-4.54V19.77a1,1,0,0,1,2,0V31.45a1,1,0,0,1-.48.85s-6.26,3.83-7.94,4.84a2.86,2.86,0,0,0-1.5,2.7c.14.93,1,1.62,2.38,1.95a1,1,0,0,1,.74,1.2A1,1,0,0,1,11.8,43.76Z" />
          <path d="M36.2,43.76a1,1,0,0,1-1-.77,1,1,0,0,1,.74-1.2c1.39-.33,2.24-1,2.38-1.95a2.86,2.86,0,0,0-1.5-2.7c-1.68-1-7.94-4.84-7.94-4.84a1,1,0,0,1-.48-.85V19.77a1,1,0,0,1,2,0V30.89c1.49.91,6,3.7,7.45,4.54a4.82,4.82,0,0,1,2.45,4.71c-.28,1.76-1.7,3.07-3.9,3.59Z" />
          <path d="M38.23,43.75H20.1a4,4,0,1,1,0-8h7.57a1,1,0,1,1,0,2H20.1a2,2,0,1,0,0,4H38.23a1,1,0,0,1,0,2Z" />
          <path d="M11,36.66a3,3,0,0,1-.42,0,3.41,3.41,0,0,1-2.25-1.28,3.37,3.37,0,0,1,.6-4.73l3.91-2.58v-9a5.5,5.5,0,0,1,5.51-5.48h11.3a5.51,5.51,0,0,1,5.52,5.48v9L39,30.58a3.4,3.4,0,0,1,.66,4.78,3.36,3.36,0,0,1-4.73.59,1,1,0,0,1-.18-1.4,1,1,0,0,1,1.41-.18,1.3,1.3,0,0,0,1,.28,1.35,1.35,0,0,0,.91-.52,1.38,1.38,0,0,0-.24-1.92l-4.24-2.79a1,1,0,0,1-.44-.83V19.08a3.5,3.5,0,0,0-3.52-3.48H18.35a3.5,3.5,0,0,0-3.51,3.48v9.51a1,1,0,0,1-.45.83l-4.3,2.83a1.37,1.37,0,0,0-.18,1.88,1.39,1.39,0,0,0,1.93.25A1,1,0,0,1,13.07,36,3.33,3.33,0,0,1,11,36.66Z" />
        </svg>
      );

    case TopicKeys.BLOOD_PRESSURE:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M35.94,36.09V21.63a1,1,0,0,0-1-1H10.65a1,1,0,0,0-1,1v5.92a8.22,8.22,0,0,1,1-16.38h18a9.55,9.55,0,1,0-.06-2H10.64a10.22,10.22,0,0,0-1,20.4v6.52a1,1,0,0,0,1,1H34.94A1,1,0,0,0,35.94,36.09ZM37.05,2.4V3.62a1,1,0,0,0,2,0V2.4a7.53,7.53,0,0,1,6.46,6.47H44.19a1,1,0,0,0,0,2h1.32a7.53,7.53,0,0,1-6.46,6.46V16a1,1,0,1,0-2,0v1.31a7.53,7.53,0,0,1-6.46-6.46h1.2a1,1,0,0,0,0-2h-1.2A7.53,7.53,0,0,1,37.05,2.4ZM33.94,35.09H11.65V22.63H33.94Z" />
          <path d="M37.07,26.66a1,1,0,0,0,0,2,7.11,7.11,0,0,1,0,14.22H28.71a3.37,3.37,0,0,0-3.17-2.26H18.36A3.38,3.38,0,0,0,15,44v.29a3.38,3.38,0,0,0,3.38,3.38h7.18a3.38,3.38,0,0,0,3.32-2.79h8.21a9.11,9.11,0,0,0,0-18.22ZM26.92,44.29a1.38,1.38,0,0,1-1.38,1.38H18.36A1.38,1.38,0,0,1,17,44.29V44a1.38,1.38,0,0,1,1.38-1.38h7.18A1.38,1.38,0,0,1,26.92,44Z" />
          <path d="M38.11,10.82a1,1,0,0,0,.69-.28l3.28-3.1a1,1,0,0,0,0-1.42A1,1,0,0,0,40.7,6L37.43,9.09a1,1,0,0,0,0,1.42A1,1,0,0,0,38.11,10.82Z" />
          <path d="M22.26,32.46a.75.75,0,0,0,1.07,0h0l2.77-2.78a2.72,2.72,0,0,0-3.31-4.26,2.72,2.72,0,0,0-3.31,4.26Zm-1.7-5.54h0a1.21,1.21,0,0,1,.84-.35,1.19,1.19,0,0,1,.84.35.77.77,0,0,0,1.08,0h0A1.2,1.2,0,0,1,25,28.6L22.8,30.83,20.57,28.6A1.19,1.19,0,0,1,20.56,26.92Z" />
        </svg>
      );

    case TopicKeys.CHOLESTEROL:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M14.74,43.84a2.94,2.94,0,1,1,2.94-2.94A2.94,2.94,0,0,1,14.74,43.84Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,14.74,39.93Z" />
          <path d="M23.2,35.16a2.94,2.94,0,1,1,2.94-2.94A2.94,2.94,0,0,1,23.2,35.16Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,23.2,31.25Z" />
          <path d="M18.39,27.57a2.94,2.94,0,1,1,2.94-2.93A2.93,2.93,0,0,1,18.39,27.57Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,18.39,23.66Z" />
          <path d="M18.42,16.22a2.94,2.94,0,1,1,2.93-2.93A2.94,2.94,0,0,1,18.42,16.22Zm0-3.9a1,1,0,1,0,1,1A1,1,0,0,0,18.42,12.32Z" />
          <path d="M11.63,9.71a2.94,2.94,0,1,1,2.93-2.93A2.94,2.94,0,0,1,11.63,9.71Zm0-3.9a1,1,0,0,0,0,1.94,1,1,0,1,0,0-1.94Z" />
          <path d="M25.18,22.73a2.94,2.94,0,1,1,2.94-2.93A2.94,2.94,0,0,1,25.18,22.73Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,25.18,18.82Z" />
          <path d="M32.18,13.45a2.94,2.94,0,1,1,2.94-2.94A2.94,2.94,0,0,1,32.18,13.45Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,32.18,9.54Z" />
          <path d="M41.07,14.43A2.94,2.94,0,1,1,44,11.49,2.94,2.94,0,0,1,41.07,14.43Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,41.07,10.52Z" />
          <path d="M43.61,7.45a2.94,2.94,0,1,1,2.93-2.94A2.94,2.94,0,0,1,43.61,7.45Zm0-3.91a1,1,0,1,0,1,1A1,1,0,0,0,43.61,3.54Z" />
          <path d="M2.7,45.5a1,1,0,0,1-.78-.38,1,1,0,0,1,.18-1.38c5.74-4.37,8.89-9.57,9.37-15.45,1-11.83-9.12-22.71-9.22-22.82A1,1,0,1,1,3.67,4.12c.44.47,10.8,11.61,9.76,24.32C12.9,34.91,9.49,40.58,3.29,45.3A.92.92,0,0,1,2.7,45.5Z" />
          <path d="M22.3,47.59a1,1,0,0,1-.66-.25A1,1,0,0,1,21.57,46c.1-.1,9.62-10.8,6.91-21.38a1,1,0,0,1,.35-1A79.36,79.36,0,0,1,44.9,14.08a1,1,0,1,1,.75,1.81,78.37,78.37,0,0,0-15.12,8.83C33,36.1,23.44,46.81,23,47.27A1,1,0,0,1,22.3,47.59Z" />
          <path d="M44.89,36.52v2.66H43.3V36.52H41V35.08H43.3V32.53h1.59v2.55H47.2v1.44Z" />
          <path d="M4.7,17v2.66H3.11V17H.8V15.55H3.11V13H4.7v2.54H7V17Z" />
          <path d="M4.7,35.11v2.65H3.11V35.11H.8V33.66H3.11V31.12H4.7v2.54H7v1.45Z" />
          <path d="M38.1,27.18v2.66H36.5V27.18H34.19V25.74H36.5V23.19h1.6v2.55h2.31v1.44Z" />
          <path d="M33.57,42.44v2.65H32V42.44H29.67V41H32V38.45h1.59V41h2.31v1.45Z" />
          <path d="M24.07,11.75l-.17,0a1,1,0,0,1-.69-.49c-2.46-4.51-5.6-9.25-5.64-9.3A1,1,0,1,1,19.21.86S22,5,24.35,9.24A50.9,50.9,0,0,1,39.79.45a1,1,0,0,1,.59,1.88,49.59,49.59,0,0,0-15.67,9.18A1,1,0,0,1,24.07,11.75Z" />
          <rect x="43.39" y="21.74" width="3.79" height="1.96" transform="translate(20.59 66.96) rotate(-87.48)" />
          <rect x="14.85" y="32.54" width="3.26" height="1.96" transform="translate(-20.87 33.1) rotate(-63.18)" />
          <rect x="18.46" y="40.39" width="4.36" height="1.96" transform="translate(-25.57 39.96) rotate(-61.73)" />
          <rect x="15.08" y="16.52" width="1.96" height="3.54" transform="translate(-7.52 12.27) rotate(-34.39)" />
          <rect
            x="15.85"
            y="4.06"
            width="1.96"
            height="3.54"
            transform="matrix(0.83, -0.56, 0.56, 0.83, -0.35, 10.52)"
          />
          <rect x="31.74" y="15.21" width="3.66" height="1.96" transform="translate(-3.18 22.4) rotate(-35.34)" />
          <rect x="39.53" y="42.51" width="1.96" height="3.4" transform="translate(-4.02 84.36) rotate(-89.55)" />
          <rect
            x="5.17"
            y="24.69"
            width="3.03"
            height="1.96"
            transform="matrix(0.85, -0.52, 0.52, 0.85, -12.36, 7.21)"
          />
        </svg>
      );

    case TopicKeys.BLOOD_SUGAR:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M41.81,21.74a1,1,0,0,0-1,1V39.23a1,1,0,0,0,2,0V22.74A1,1,0,0,0,41.81,21.74Z" />
          <path d="M36,10.75a1,1,0,0,0,1-1v-4a1,1,0,0,0-2,0v4A1,1,0,0,0,36,10.75Z" />
          <path d="M17.76,37.79H10.07a1,1,0,0,0,0,2h7.69a1,1,0,0,0,0-2Z" />
          <path d="M29.87,37.79H22.78a1,1,0,0,0,0,2h7.09a1,1,0,0,0,0-2Z" />
          <path d="M45.1,9.83A9,9,0,0,0,28.62,4.69H12.22A9.33,9.33,0,0,0,2.9,14V37.9a9.33,9.33,0,0,0,9.32,9.32H28.67A9.33,9.33,0,0,0,38,37.9V18.66A9.06,9.06,0,0,0,45.1,9.83Zm-9-7a7,7,0,1,1-7,7.05A7.05,7.05,0,0,1,36.06,2.78Zm-4.94,30H10.75v-20H27.52A9.06,9.06,0,0,0,31,17.31ZM28.67,45.22H12.22A7.33,7.33,0,0,1,4.9,37.9V14a7.32,7.32,0,0,1,7.32-7.32H27.59A8.84,8.84,0,0,0,27,9.83c0,.32,0,.63,0,.94H9.75a1,1,0,0,0-1,1v22a1,1,0,0,0,1,1H32.12a1,1,0,0,0,1-1L33,18.33a9.06,9.06,0,0,0,3,.54v19A7.33,7.33,0,0,1,28.67,45.22Z" />
          <path d="M36,15.23a1.7,1.7,0,1,0-1.7-1.7A1.7,1.7,0,0,0,36,15.23Z" />
          <path d="M20.22,15.65c-2.41,2.41-5,6.53-5,9.43a5.68,5.68,0,0,0,11.36,0c0-3.08-2.91-7.37-5-9.43A1,1,0,0,0,20.22,15.65Zm.71,13.11a3.68,3.68,0,0,1-3.68-3.68c0-2,1.8-5.11,3.68-7.26,1.89,2.17,3.68,5.33,3.68,7.26A3.68,3.68,0,0,1,20.93,28.76Z" />
        </svg>
      );

    case TopicKeys.QUIT_SMOKING:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M48,24A24,24,0,0,0,7,7.1l0,0-.7.78,0,0-.61.68h0a24,24,0,0,0,35.72,32h0l.39-.43.07-.09.87-1,0,0A23.9,23.9,0,0,0,48,24ZM24,2A22,22,0,0,1,41.16,37.74L33.64,31h5.17V23H24.71L8.46,8.44A21.94,21.94,0,0,1,24,2ZM18.25,25H24l4.46,4H18.25Zm-2,4H9.5V25h6.75Zm10.69-4h9.87v4h-5.4ZM24,46A22,22,0,0,1,7.12,9.91L21.72,23H7.5v8H30.64l9.2,8.24A21.94,21.94,0,0,1,24,46Z" />
          <rect x="39.5" y="23" width="2" height="8" />
          <path d="M30,11a4.94,4.94,0,0,0,1.22,3.64,4.83,4.83,0,0,0,3.7,1.13h.26c1.24,0,3.82,0,3.82,5.23h2c0-3.28-1-7.19-5.8-7.23h-.29a3.27,3.27,0,0,1-2.28-.54A3.13,3.13,0,0,1,32,11c0-2.05-.84-4.5-4.87-4.5H23.62v2h3.51C29.2,8.5,30,9.2,30,11Z" />
          <path d="M29.52,16c-.77-4.7-4.26-5-4.47-5H20.2v2H25c.21,0,2.08.29,2.58,3.31.59,3.63,4.54,4,7.45,4h1l0-2C31.55,18.32,29.8,17.72,29.52,16Z" />
          <rect x="18.21" y="6.5" width="3.88" height="2" />
        </svg>
      );

    case TopicKeys.WORK_WITH_A_DOCTOR:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
          <path d="M36,19.74a.93.93,0,0,1-.62-.24l-3.57-3.19H25.75a.93.93,0,0,1-.93-.93V1.77a.93.93,0,0,1,.93-.93H46.28a.91.91,0,0,1,.66.28.94.94,0,0,1,.27.67L46.9,15.4a.94.94,0,0,1-.93.91H40.32l-3.7,3.2A.88.88,0,0,1,36,19.74Zm-9.33-5.29h5.49a.89.89,0,0,1,.62.24L36,17.57l3.35-2.89a.9.9,0,0,1,.6-.23h5.09L45.33,2.7H26.68Z" />
          <path d="M41.69,9.23H35.41a.93.93,0,1,1,0-1.86h6.28a.93.93,0,0,1,0,1.86Z" />
          <path d="M32.59,9.23H29a.93.93,0,1,1,0-1.86h3.59a.93.93,0,0,1,0,1.86Z" />
          <path d="M22.25,47.16H1.72a.93.93,0,0,1-.93-.93V32.62a.93.93,0,0,1,.93-.93H7.81l3.38-2.81a.92.92,0,0,1,1.14,0l3.92,2.85h5.69a.93.93,0,0,1,.93.91l.31,13.61a.93.93,0,0,1-.26.67A1,1,0,0,1,22.25,47.16ZM2.65,45.3H21.3L21,33.55H16a.91.91,0,0,1-.55-.18l-3.58-2.6L8.74,33.33a.9.9,0,0,1-.59.22H2.65Z" />
          <path d="M18.33,40.32H12a.93.93,0,0,1,0-1.86h6.29a.93.93,0,0,1,0,1.86Z" />
          <path d="M9.23,40.32H5.64a.93.93,0,0,1,0-1.86H9.23a.93.93,0,0,1,0,1.86Z" />
          <path d="M13.86,27.68H3.5A1.38,1.38,0,0,1,2.12,26.3V11.83a10.19,10.19,0,0,1,10-10.27,10,10,0,0,1,10.08,9.21l1.92,5.91a1.38,1.38,0,0,1-.2,1.24,1.39,1.39,0,0,1-1.11.57h-.7a6,6,0,0,1-5.82,4.73l-1,0V26.3A1.38,1.38,0,0,1,13.86,27.68ZM4,25.82h9.4V22.76a1.38,1.38,0,0,1,1.36-1.37l1.5,0a4.09,4.09,0,0,0,4.1-3.86l.05-.87h1.73L20.33,11a8.21,8.21,0,0,0-8.16-7.6h-.06A8.32,8.32,0,0,0,4,11.83Z" />
          <path d="M18.19,10.92s0,.18,0,.2V11s0,.06,0,.09,0,.1,0,.14-.09.18,0,.1l-.05.08a.75.75,0,0,1-.07.12c-.09.13,0,0,0,0s-.11.12-.13.14-.08.1-.1.09.09-.07,0,0l-.06,0-.13.09-.07,0c-.07,0-.05,0,0,0s-.21.07-.27.08l-.09,0c.1,0,.12,0,0,0H17c-.14,0,.15,0,0,0s-.26,0-.29-.08.11,0,0,0l0,0-.16-.1,0,0c-.06,0-.05,0,0,0l-.1-.09a1.31,1.31,0,0,0-.11-.12l0,.05-.06-.07s-.15-.22-.13-.25,0,.11,0,0l0-.09,0-.14a.64.64,0,0,1,0-.07c0-.05,0,0,0,.07s0-.16,0-.2a.7.7,0,0,0-1.4,0,2.52,2.52,0,0,0,1.65,2.33,2.46,2.46,0,0,0,2.67-.7,2.56,2.56,0,0,0,.62-1.63.7.7,0,0,0-1.4,0Z" />
          <path d="M17.88,19.2l-.11.1c-.09.08.15-.1,0,0l-.23.14c-.14.07.14-.05,0,0l-.13,0a1.31,1.31,0,0,1-.27.06l.18,0a1.62,1.62,0,0,1-.44,0l.18,0a1.31,1.31,0,0,1-.27-.06l-.13,0c-.12,0,.17.08,0,0l-.23-.14c-.15-.1.09.08,0,0l-.11-.1a.7.7,0,0,0-1,1,2.46,2.46,0,0,0,3.48,0,.7.7,0,0,0,0-1,.71.71,0,0,0-1,0Z" />
          <path d="M44.39,47.16H34a1.38,1.38,0,0,1-1.38-1.38V42.72l-1,0A6,6,0,0,1,25.81,38h-.69A1.38,1.38,0,0,1,24,37.4a1.4,1.4,0,0,1-.2-1.24l1.92-5.91a10.06,10.06,0,0,1,10-9.21h.07a10.19,10.19,0,0,1,10,10.27V45.78A1.38,1.38,0,0,1,44.39,47.16ZM34.51,45.3h9.41v-14a8.33,8.33,0,0,0-8.13-8.41h-.06a8.2,8.2,0,0,0-8.16,7.6l-.05.22-1.74,5.39H27.5l.05.88a4.1,4.1,0,0,0,4.08,3.86l1.52,0a1.39,1.39,0,0,1,1.36,1.38Zm-8.94-8.56Z" />
          <path d="M28.31,30.41A2.49,2.49,0,0,0,30,32.73a2.46,2.46,0,0,0,2.67-.7,2.55,2.55,0,0,0,.62-1.62.71.71,0,0,0-.7-.7.72.72,0,0,0-.7.7s0,.17,0,.19l0-.09a.36.36,0,0,0,0,.1l0,.13s-.09.18,0,.1,0,.06-.05.08a.75.75,0,0,1-.07.12c-.09.13,0,0,0,0s-.11.12-.13.13-.08.1-.1.09.09-.07.05,0l-.06,0-.13.09-.07,0c-.06,0-.05,0,0,0s-.21.07-.27.08l-.09,0c.1,0,.12,0,0,0h-.3c-.14,0,.16,0,0,0s-.26,0-.29-.09.11,0,0,0l0,0-.16-.1,0,0,0,0s-.09-.08-.1-.09a1.31,1.31,0,0,0-.11-.12l0,.05L29.9,31s-.15-.21-.13-.24,0,.1,0,0l0-.09,0-.13a.64.64,0,0,0,0-.07c0-.06,0,0,0,.06s0-.16,0-.19a.7.7,0,1,0-1.39,0Z" />
          <path d="M29,39.66a2.46,2.46,0,0,0,1.74.73,2.49,2.49,0,0,0,1.75-.7.7.7,0,1,0-1-1l-.11.1c-.09.08.15-.1,0,0l-.21.12c-.17.08.12,0,0,0l-.1,0-.27.06L31,39a2.57,2.57,0,0,1-.48,0l.19,0-.27,0-.13-.05c-.11,0,.17.08,0,0a1.88,1.88,0,0,1-.24-.14c-.1-.06.14.12,0,0l-.11-.1a.69.69,0,0,0-1,0,.71.71,0,0,0,0,1Z" />
        </svg>
      );

    case IconKeys.CIRCLE_CHECK:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.875 34.875">
          <path d="M34.875 17.437A17.438 17.438 0 1 1 17.437 0a17.437 17.437 0 0 1 17.438 17.437ZM15.42 26.67l12.938-12.933a1.125 1.125 0 0 0 0-1.591l-1.591-1.595a1.125 1.125 0 0 0-1.591 0L14.625 21.102l-4.926-4.926a1.125 1.125 0 0 0-1.591 0l-1.592 1.59a1.125 1.125 0 0 0 0 1.591l7.313 7.313a1.125 1.125 0 0 0 1.591 0Z" />
        </svg>
      );

    case IconKeys.CHECK:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      );

    case IconKeys.ARROW_DOWN:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path fill="currentColor" d="m0 5 10 10L20 5Z" />
        </svg>
      );

    case IconKeys.HEART_POINT:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.396 20.157">
          <path
            fill="currentColor"
            d="m11.2 20.157-1.624-1.45C3.807 13.577 0 10.194 0 6.042A6.042 6.042 0 0 1 6.159 0 6.76 6.76 0 0 1 11.2 2.3 6.76 6.76 0 0 1 16.237 0 6.042 6.042 0 0 1 22.4 6.042c0 4.152-3.807 7.535-9.574 12.676Z"
            data-name="↳Color"
          />
        </svg>
      );

    case IconKeys.MAP:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.704 27.704">
          <path
            fill="currentColor"
            d="m26.935 0-.246.046-8.22 3.186L9.235 0 .554 2.924A.776.776 0 0 0 0 3.663v23.272a.762.762 0 0 0 .77.77l.246-.046 8.219-3.186 9.234 3.227 8.681-2.92a.776.776 0 0 0 .554-.739V.77a.762.762 0 0 0-.769-.77ZM10.774 3.8l6.156 2.156V23.9l-6.156-2.155Zm-7.7 1.524L7.7 3.771v18.007l-4.622 1.786Zm21.552 17.055-4.617 1.555V5.941l4.617-1.785Z"
            data-name="Path 9713"
          />
        </svg>
      );

    case IconKeys.RIBBON:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.195 32.264">
          <path
            fill="currentColor"
            d="M18.15 22.578c.881-.51 1.1-1.893 1.82-2.608s2.1-.938 2.608-1.819.01-2.177.276-3.169c.256-.961 1.289-1.843 1.289-2.883s-1.093-1.923-1.347-2.882c-.265-.992.228-2.3-.275-3.169s-1.892-1.1-2.608-1.819-.938-2.1-1.819-2.608-2.177-.01-3.169-.276C14.021 1.088 13.138 0 12.099 0s-1.922 1.088-2.88 1.345c-1 .265-2.3-.227-3.171.275s-1.1 1.893-1.819 2.608-2.1.938-2.608 1.819-.01 2.177-.275 3.166c-.257.964-1.345 1.846-1.345 2.886s1.088 1.923 1.345 2.882c.265.992-.228 2.3.275 3.169s1.893 1.1 2.607 1.82.938 2.1 1.82 2.609 2.176.01 3.168.275c.962.254 1.844 1.344 2.884 1.344s1.923-1.088 2.882-1.345c.992-.262 2.296.229 3.168-.275ZM7.058 12.099a5.041 5.041 0 1 1 5.041 5.041 5.037 5.037 0 0 1-5.041-5.041Zm-6.949 15.2a1.512 1.512 0 0 0 1.7 2.042l2.848-.569 1.35 2.664a1.5 1.5 0 0 0 1.346.83h.064a1.494 1.494 0 0 0 1.338-.938l2.117-5.178a14.078 14.078 0 0 1-8.709-4.03L.108 27.296Zm21.921-5.175a14.064 14.064 0 0 1-8.709 4.03l2.116 5.172a1.5 1.5 0 0 0 1.338.938c.024-.057-.008 0 .068 0a1.493 1.493 0 0 0 1.345-.828l1.35-2.664 2.848.569a1.512 1.512 0 0 0 1.7-2.042Z"
            data-name="Path 10233"
          />
        </svg>
      );

    case IconKeys.CARET_DOWN:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor">
          <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
        </svg>
      );

    case IconKeys.HAMBURGER:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 18" fill="currentColor">
          <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2">
            <path d="M1 1h35" data-name="Line 26" />
            <path d="M1 9h35" data-name="Line 27" />
            <path d="M1 17h35" data-name="Line 28" />
          </g>
        </svg>
      );

    case IconKeys.HEART:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.743 12.904" fill="currentColor">
          <path d="M13.2.931a4.337 4.337 0 0 0-5.828.432A4.334 4.334 0 0 0 1.544.931a4.357 4.357 0 0 0-.305 6.37l5.05 5.145a1.51 1.51 0 0 0 1.083.458 1.5 1.5 0 0 0 1.083-.455l5.05-5.145A4.36 4.36 0 0 0 13.2.931Zm-.68 5.4-5.05 5.145a.118.118 0 0 1-.2 0L2.223 6.33a2.97 2.97 0 0 1 .21-4.339 2.92 2.92 0 0 1 3.931.3l1.008 1.03 1.007-1.028a2.918 2.918 0 0 1 3.931-.305 2.982 2.982 0 0 1 .21 4.342Z" />
        </svg>
      );

    default:
      return null;
  }
};
