import { ReactNode } from 'react';

import clsx from 'clsx';

interface ButtonProps {
  type: 'primary' | 'secondary' | 'cta' | 'white' | 'minimal';
  children: ReactNode;
  size?: 'md' | 'lg';
  disabled?: boolean;
}
export const Button = ({ type, children, size = 'lg', disabled = false }: ButtonProps) => {
  return (
    <button
      className={clsx('inline mx-auto rounded-full text-white uppercase font-bold disabled:opacity-75', {
        'text-2xl font-loos leading-none': size === 'lg',
        'text-sm': size === 'md',
        'bg-gradient-to-b from-gradient-start to-gradient-stop': type === 'cta',
        'bg-primary-1 shadow-lg': type === 'primary',
        'bg-primary-3': type === 'secondary',
        'bg-white text-primary-1 py-3 px-6 shadow-lg': type === 'white',
        'rounded-bl-none px-12 pt-3 pb-4': type !== 'white',
      })}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
