export enum FactsIconKeys {
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  BLOOD_SUGAR_PLATE_1 = 'BLOOD_SUGAR_PLATE_1',
  BLOOD_SUGAR_PLATE_2 = 'BLOOD_SUGAR_PLATE_2',
  BLOOD_SUGAR_PLATE_3 = 'BLOOD_SUGAR_PLATE_3',
  BLOOD_SUGAR = 'BLOOD_SUGAR',
  CHOLESTEROL_FACTS = 'CHOLESTEROL_FACTS',
  DOCTOR_ANSWER = 'DOCTOR_ANSWER',
  DOCTOR_QUESTION = 'DOCTOR_QUESTION',
  EAT_HEALTY_SMOOTHIE_1 = 'EAT_HEALTY_SMOOTHIE_1',
  EAT_HEALTY_SMOOTHIE_2 = 'EAT_HEALTY_SMOOTHIE_2',
  EAT_HEALTY_SMOOTHIE_3 = 'EAT_HEALTY_SMOOTHIE_3',
  QUIT_SMOKING = 'QUIT_SMOKING',
}

interface FactsIconProps {
  icon: FactsIconKeys;
}

export const FactsIcon: React.FC<FactsIconProps> = ({ icon }) => {
  let svg;

  switch (icon) {
    case FactsIconKeys.BLOOD_PRESSURE:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="b">
              <path d="M0 0h34.363v51.546H0z" data-name="Rectangle 1925" />
            </clipPath>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)" data-name="Group 1176">
            <g clipPath="url(#b)" data-name="Group 1175" transform="translate(18.818 10.227)">
              <path
                d="M16.941.305a1.71 1.71 0 0 0-1.317.818c-3.592 5.876-7.45 12.276-10.445 18.418S0 31.522 0 36.551c0 8.524 7.813 15 17.084 15s17.084-6.476 17.084-15c0-5.03-2.183-10.87-5.179-17.011S22.135 6.999 18.543 1.124a1.709 1.709 0 0 0-1.6-.819Zm.142 5.036c3.137 5.168 6.338 10.558 8.845 15.694 2.9 5.941 4.822 11.521 4.822 15.516 0 6.428-5.955 11.584-13.667 11.584S3.416 42.978 3.416 36.551c0-3.995 1.925-9.575 4.823-15.516 2.507-5.14 5.707-10.526 8.844-15.695Zm-.018 13.719a1.708 1.708 0 0 0-1.69 1.739v3.416h-3.417a1.676 1.676 0 0 0-.178 0 1.711 1.711 0 1 0 .178 3.417h3.417v3.417a1.709 1.709 0 1 0 3.417 0v-3.413h3.417a1.708 1.708 0 1 0 0-3.417h-3.418v-3.42a1.709 1.709 0 0 0-1.726-1.744ZM11.78 33.899a1.711 1.711 0 1 0 .178 3.417h10.25a1.708 1.708 0 1 0 0-3.417H11.78Z"
                data-name="Path 708"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.BLOOD_SUGAR_PLATE_1:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="b">
              <path fill="none" d="M0 0h22.35v62.988H0z" data-name="Rectangle 3115" />
            </clipPath>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)" data-name="Group 2992">
            <g clipPath="url(#b)" data-name="Group 2985" transform="rotate(30 15.179 80.07)">
              <path
                d="m19.266 14.501 2.048-10.238a1.017 1.017 0 0 0-.75-1.186L16.5 2.061a1.016 1.016 0 0 0-1.242.787l-1.035 5.179V1.016A1.017 1.017 0 0 0 13.207.001H9.143a1.017 1.017 0 0 0-1.016 1.015v7.011l-1.036-5.18a1.016 1.016 0 0 0-1.242-.786L1.785 3.077a1.016 1.016 0 0 0-.75 1.186l2.049 10.238A9.119 9.119 0 0 0 0 21.335a.922.922 0 0 0 .02.2l7.8 39a3.054 3.054 0 0 0 2.989 2.45h.732a3.055 3.055 0 0 0 2.989-2.45l7.8-39a1.017 1.017 0 0 0 .019-.2 9.115 9.115 0 0 0-3.083-6.834ZM17.042 4.292l2.091.522-1.685 8.427a9.073 9.073 0 0 0-2.041-.771Zm-4.851 7.9h-2.032V2.032h2.032ZM3.217 4.814l2.091-.522 1.636 8.179a9.084 9.084 0 0 0-2.041.771Zm9.32 55.324a1.019 1.019 0 0 1-1 .817h-.727a1.019 1.019 0 0 1-1-.817L7.537 48.765h2.622v-2.032H7.132l-1.219-6.1h4.246v-2.032H5.506l-1.219-6.1h5.872v-2.023H3.881l-1.219-6.1h7.5V22.35h-7.9l-.224-1.113a6.956 6.956 0 0 1 .079-.918h6.01v-2.032h-5.4a7.113 7.113 0 0 1 6.417-4.064h4.064a7.118 7.118 0 0 1 7.032 6.1h-6.017v2.032h5.872l-.813 4.064h-7.091v2.032h6.685l-1.219 6.1h-5.466v2.032h5.059l-1.219 6.1h-3.84v2.018h3.434l-1.219 6.1h-2.215v2.032H14Z"
                data-name="Path 10315"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.BLOOD_SUGAR_PLATE_2:
      svg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="72"
          height="72"
          viewBox="0 0 72 72"
        >
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_3116" data-name="Rectangle 3116" width="56.37" height="31.92" fill="none" />
            </clipPath>
            <clipPath id="clip-Bloodsugar_plate2">
              <rect width="72" height="72" />
            </clipPath>
          </defs>
          <g id="Bloodsugar_plate2" clipPath="url(#clip-Bloodsugar_plate2)">
            <g id="Group_2991" data-name="Group 2991" transform="translate(7.815 20.039)">
              <g id="Group_2987" data-name="Group 2987" transform="translate(0 0)" clipPath="url(#clip-path)">
                <path
                  id="Path_10316"
                  data-name="Path 10316"
                  d="M63.7,114.974a1.226,1.226,0,1,1-1.226-1.226,1.226,1.226,0,0,1,1.226,1.226"
                  transform="translate(-52.67 -97.811)"
                />
                <path
                  id="Path_10317"
                  data-name="Path 10317"
                  d="M29.963,3.495a1.226,1.226,0,0,0,0-2.207A12.128,12.128,0,0,0,24.519,0,12.259,12.259,0,0,0,12.37,10.655C5.455,11.9,0,14.529,0,18.39c0,5.578,11.365,8.582,22.067,8.582A12.173,12.173,0,0,0,37.281,30.65a1.225,1.225,0,0,0,.013-2.195,9.812,9.812,0,0,1-3.335-2.721,22.581,22.581,0,0,0,7.356-2.955,11.034,11.034,0,0,0,14.294,5.9,1.226,1.226,0,0,0,0-2.268,8.582,8.582,0,0,1,0-15.938,1.226,1.226,0,0,0,0-2.268,11.033,11.033,0,0,0-14.294,5.9c-3.31-2.329-9.44-3.9-16.49-4.23a9.71,9.71,0,0,1,5.137-6.375ZM12.137,23.613c-6.13-1.226-9.685-3.457-9.685-5.222s3.531-4,9.685-5.222a10.787,10.787,0,0,1,0,10.445Zm21.9,5.566a9.807,9.807,0,0,1-8.741-2.293,53.876,53.876,0,0,0,6.13-.686,12.116,12.116,0,0,0,2.611,2.979Zm7-12.186h0a1.238,1.238,0,0,0,2.121-.539,8.582,8.582,0,0,1,8.741-6.645,11.034,11.034,0,0,0,0,17.164,8.581,8.581,0,0,1-8.753-6.632,1.227,1.227,0,0,0-.9-.907,1.3,1.3,0,0,0-.306,0,1.224,1.224,0,0,0-.907.4c-2.035,2.231-8.84,4.683-18.965,4.683a53.485,53.485,0,0,1-7.356-.49,13.484,13.484,0,0,0,0-11.279,53.507,53.507,0,0,1,7.356-.49c10.126,0,16.931,2.452,18.965,4.732ZM22.324,9.809h-.257a57.6,57.6,0,0,0-7.147.454,9.807,9.807,0,0,1,9.6-7.809,9.7,9.7,0,0,1,2.145.245,12.165,12.165,0,0,0-4.34,7.111Z"
                  transform="translate(0 -0.001)"
                />
              </g>
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.BLOOD_SUGAR_PLATE_3:
      svg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="72"
          height="72"
          viewBox="0 0 72 72"
        >
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_3117" data-name="Rectangle 3117" width="62.964" height="37.29" fill="none" />
            </clipPath>
            <clipPath id="clip-Bloodsugar_plate3">
              <rect width="72" height="72" />
            </clipPath>
          </defs>
          <g id="Bloodsugar_plate3" clipPath="url(#clip-Bloodsugar_plate3)">
            <g id="Group_2993" data-name="Group 2993" transform="translate(4.518 17.355)">
              <g id="Group_2989" data-name="Group 2989" transform="translate(0 0)" clipPath="url(#clip-path)">
                <path
                  id="Path_10318"
                  data-name="Path 10318"
                  d="M376.024,93.523c0-1.656,1.119-2.459,2.551-1.867a5.871,5.871,0,0,1,1.663,1.032,25.571,25.571,0,0,1,6.852,10.042,5.482,5.482,0,0,1,.28,2.245,1.539,1.539,0,0,1-2.038,1.37,5.131,5.131,0,0,1-1.781-.931,25.622,25.622,0,0,1-7.169-10.133,6.79,6.79,0,0,1-.3-1.14,4.356,4.356,0,0,1-.055-.618m9.045,10.281c-.643-3.042-4.693-8.6-6.751-9.63a23.058,23.058,0,0,0,6.751,9.63"
                  transform="translate(-326.412 -79.395)"
                />
                <path
                  id="Path_10319"
                  data-name="Path 10319"
                  d="M207.986,130.293a19.547,19.547,0,0,1,2.287.749,20.178,20.178,0,0,1,7.8,6.38,7.686,7.686,0,0,1,1.271,2.69,1.882,1.882,0,0,1-2.247,2.338,8.737,8.737,0,0,1-2.55-.792,20.3,20.3,0,0,1-7.671-6.633,6.951,6.951,0,0,1-.958-2.064,2.035,2.035,0,0,1,2.068-2.669m9.118,9.881c-1.014-2.9-7.089-7.565-8.928-7.462.563,2.264,6.37,7.248,8.928,7.462"
                  transform="translate(-178.665 -113.103)"
                />
                <path
                  id="Path_10320"
                  data-name="Path 10320"
                  d="M39.329,100.312a19.124,19.124,0,0,1-.622,2.2,23.649,23.649,0,0,1-5.325,8.207,8.281,8.281,0,0,1-2.165,1.563,1.671,1.671,0,0,1-2.566-1.56,7.3,7.3,0,0,1,.486-2.619,23.715,23.715,0,0,1,5.569-8.584,7.452,7.452,0,0,1,1.907-1.372c1.462-.719,2.7.125,2.716,2.17m-2.264.13c-2.389,1.634-6.082,7.421-6.043,9.432a20.772,20.772,0,0,0,6.043-9.432"
                  transform="translate(-24.871 -84.974)"
                />
                <path
                  id="Path_10321"
                  data-name="Path 10321"
                  d="M322.619,26.9a11.4,11.4,0,0,1,1.66.324,1.726,1.726,0,0,1,.965,2.608,3.97,3.97,0,0,1-.957,1.152A18.928,18.928,0,0,1,313.42,35.1a4.229,4.229,0,0,1-1.72-.33,1.628,1.628,0,0,1-.876-2.335,5.06,5.06,0,0,1,1.128-1.424c2.259-2,7.628-4,10.668-4.107m-9.272,5.9c2.978.071,8.445-2.071,9.465-3.671a17.16,17.16,0,0,0-9.465,3.671"
                  transform="translate(-269.635 -23.353)"
                />
                <path
                  id="Path_10322"
                  data-name="Path 10322"
                  d="M364.176,200.2a21.6,21.6,0,0,1,7.812,1.563,7.475,7.475,0,0,1,1.652.928c1.536,1.186,1.3,2.9-.541,3.512a10.428,10.428,0,0,1-3.092.485,20.215,20.215,0,0,1-8.272-1.54,7.124,7.124,0,0,1-1.891-1.154,1.763,1.763,0,0,1,.538-3.179,26.247,26.247,0,0,1,3.76-.779l.034.164m7.709,3.986a15.788,15.788,0,0,0-9.967-1.511,15.975,15.975,0,0,0,9.967,1.511"
                  transform="translate(-311.688 -173.64)"
                />
                <path
                  id="Path_10323"
                  data-name="Path 10323"
                  d="M124.3,48.834a11.61,11.61,0,0,1,1.988.4,1.753,1.753,0,0,1,.794,2.88,6.638,6.638,0,0,1-1.749,1.46,20.43,20.43,0,0,1-9.587,2.671,6.014,6.014,0,0,1-2.244-.386,1.69,1.69,0,0,1-.771-2.777,6.623,6.623,0,0,1,1.786-1.511,21.733,21.733,0,0,1,9.783-2.733m-9.19,5.106a15.963,15.963,0,0,0,9.659-2.724,15.748,15.748,0,0,0-9.659,2.724"
                  transform="translate(-97.467 -42.391)"
                />
                <path
                  id="Path_10324"
                  data-name="Path 10324"
                  d="M205.578.168A21.041,21.041,0,0,1,213.634,1.8a6.453,6.453,0,0,1,1.845,1.218,1.707,1.707,0,0,1-.471,2.925,8.656,8.656,0,0,1-2.912.673,20.667,20.667,0,0,1-8.75-1.439,7.25,7.25,0,0,1-2.066-1.24A1.752,1.752,0,0,1,201.788.8a25.655,25.655,0,0,1,3.752-.8l.039.168m7.871,4.091c-2.339-1.54-8.057-2.386-10.227-1.548A17.714,17.714,0,0,0,213.449,4.26"
                  transform="translate(-174.054)"
                />
                <path
                  id="Path_10325"
                  data-name="Path 10325"
                  d="M248.849,241.742c-1.787-.3-3.583-.548-5.356-.909a4.349,4.349,0,0,1-1.633-.8,1.712,1.712,0,0,1,.014-2.932,8.867,8.867,0,0,1,2.9-1.141,20.394,20.394,0,0,1,9.064.167,6.552,6.552,0,0,1,1.977.846,1.8,1.8,0,0,1,0,3.189,8.15,8.15,0,0,1-3.722,1.185c-1.063.139-2.14.176-3.21.258l-.034.142m-5-3.187a16.141,16.141,0,0,0,10.169-.007,16.431,16.431,0,0,0-10.169.007"
                  transform="translate(-209.238 -204.452)"
                />
                <path
                  id="Path_10326"
                  data-name="Path 10326"
                  d="M39.977,222.815c-1.786-.294-3.579-.559-5.356-.9a4.18,4.18,0,0,1-1.424-.648,1.8,1.8,0,0,1,0-3.248,8.713,8.713,0,0,1,2.686-.988,20.956,20.956,0,0,1,9.064.159,7.5,7.5,0,0,1,1.535.566,2.076,2.076,0,0,1,1.394,1.9,1.945,1.945,0,0,1-1.387,1.828,19.934,19.934,0,0,1-3.335.929,29.8,29.8,0,0,1-3.141.26l-.034.142m-5.011-3.187a16.16,16.16,0,0,0,10.154,0,16.327,16.327,0,0,0-10.154,0"
                  transform="translate(-27.921 -188.032)"
                />
                <path
                  id="Path_10327"
                  data-name="Path 10327"
                  d="M98.474,162.489a24.344,24.344,0,0,1,7.48,1.543,7.474,7.474,0,0,1,2.022,1.187,1.7,1.7,0,0,1-.408,3,10.534,10.534,0,0,1-3.429.747,20.425,20.425,0,0,1-8.263-1.268,8.823,8.823,0,0,1-1.681-.879,2.006,2.006,0,0,1-1.073-1.965,1.928,1.928,0,0,1,1.558-1.682c1.245-.309,2.528-.462,3.8-.681m7.587,4.071c-2.406-1.485-8.63-2.249-10.254-1.293a18.11,18.11,0,0,0,10.254,1.293"
                  transform="translate(-80.822 -141.051)"
                />
                <path
                  id="Path_10328"
                  data-name="Path 10328"
                  d="M240.615,58.3c0-2.117,1.511-3.077,3.337-2.186a9.629,9.629,0,0,1,3.27,2.9,15.2,15.2,0,0,1,3.437,7.13,4.229,4.229,0,0,1-.07,1.493,1.857,1.857,0,0,1-2.336,1.455,4.806,4.806,0,0,1-1.771-.8,16.9,16.9,0,0,1-5.687-8.709,7.132,7.132,0,0,1-.157-.905,3.533,3.533,0,0,1-.023-.373m7.846,8.553c.1-2.909-4.064-8.607-5.591-8.678-.058,2.482,3.25,7.656,5.591,8.678"
                  transform="translate(-208.869 -48.418)"
                />
                <path
                  id="Path_10329"
                  data-name="Path 10329"
                  d="M330.567,136.159a3.176,3.176,0,1,1-3.177,3.176,3.171,3.171,0,0,1,3.177-3.176"
                  transform="translate(-284.195 -118.194)"
                />
                <path
                  id="Path_10330"
                  data-name="Path 10330"
                  d="M430.08,55.473a2.73,2.73,0,1,1,2.747-2.709,2.755,2.755,0,0,1-2.747,2.709"
                  transform="translate(-370.96 -43.415)"
                />
                <path
                  id="Path_10331"
                  data-name="Path 10331"
                  d="M179.747,236.167a2.223,2.223,0,1,1-2.237-2.2,2.227,2.227,0,0,1,2.237,2.2"
                  transform="translate(-152.173 -203.101)"
                />
                <path
                  id="Path_10332"
                  data-name="Path 10332"
                  d="M150.6,9.66a2.227,2.227,0,0,1-2.191,2.246,2.249,2.249,0,0,1-2.261-2.254,2.216,2.216,0,0,1,2.236-2.2,2.187,2.187,0,0,1,2.216,2.2"
                  transform="translate(-126.869 -6.472)"
                />
                <path
                  id="Path_10333"
                  data-name="Path 10333"
                  d="M165.812,116.571a2.2,2.2,0,1,1,2.233-2.189,2.182,2.182,0,0,1-2.233,2.189"
                  transform="translate(-142.044 -97.379)"
                />
                <path
                  id="Path_10334"
                  data-name="Path 10334"
                  d="M55.706,40.787a1.883,1.883,0,1,1,1.869,1.879,1.861,1.861,0,0,1-1.869-1.879"
                  transform="translate(-48.356 -33.753)"
                />
                <path
                  id="Path_10335"
                  data-name="Path 10335"
                  d="M1.544,96.893a1.553,1.553,0,1,1,.05-3.105,1.553,1.553,0,0,1-.05,3.105"
                  transform="translate(0 -81.413)"
                />
                <path
                  id="Path_10336"
                  data-name="Path 10336"
                  d="M400.16,121.648a23.059,23.059,0,0,1-6.751-9.63c2.058,1.033,6.108,6.588,6.751,9.63"
                  transform="translate(-341.504 -97.239)"
                  fill="#fff"
                />
                <path
                  id="Path_10337"
                  data-name="Path 10337"
                  d="M232.6,156.082c-2.558-.214-8.365-5.2-8.928-7.462,1.839-.1,7.915,4.558,8.928,7.462"
                  transform="translate(-194.162 -129.01)"
                  fill="#fff"
                />
                <path
                  id="Path_10338"
                  data-name="Path 10338"
                  d="M52.665,117.231a20.772,20.772,0,0,1-6.043,9.432c-.039-2.011,3.654-7.8,6.043-9.432"
                  transform="translate(-40.471 -101.764)"
                  fill="#fff"
                />
                <path
                  id="Path_10339"
                  data-name="Path 10339"
                  d="M331.31,47.438a17.16,17.16,0,0,1,9.465-3.671c-1.02,1.6-6.487,3.741-9.465,3.671"
                  transform="translate(-287.598 -37.993)"
                  fill="#fff"
                />
                <path
                  id="Path_10340"
                  data-name="Path 10340"
                  d="M390.673,219.955a15.976,15.976,0,0,1-9.967-1.511,15.788,15.788,0,0,1,9.967,1.511"
                  transform="translate(-330.477 -189.412)"
                  fill="#fff"
                />
                <path
                  id="Path_10341"
                  data-name="Path 10341"
                  d="M133.713,69.495a15.748,15.748,0,0,1,9.659-2.724,15.963,15.963,0,0,1-9.659,2.724"
                  transform="translate(-116.071 -57.946)"
                  fill="#fff"
                />
                <path
                  id="Path_10342"
                  data-name="Path 10342"
                  d="M231.3,19.928a17.714,17.714,0,0,1-10.227-1.548c2.17-.838,7.888.009,10.227,1.548"
                  transform="translate(-191.909 -15.668)"
                  fill="#fff"
                />
                <path
                  id="Path_10343"
                  data-name="Path 10343"
                  d="M262.336,253.147a16.431,16.431,0,0,1,10.169-.007,16.141,16.141,0,0,1-10.169.007"
                  transform="translate(-227.724 -219.044)"
                  fill="#fff"
                />
                <path
                  id="Path_10344"
                  data-name="Path 10344"
                  d="M53.4,234.159a16.327,16.327,0,0,1,10.154,0,16.16,16.16,0,0,1-10.154,0"
                  transform="translate(-46.352 -202.564)"
                  fill="#fff"
                />
                <path
                  id="Path_10345"
                  data-name="Path 10345"
                  d="M123.83,182.055a18.11,18.11,0,0,1-10.254-1.293c1.624-.956,7.847-.192,10.254,1.293"
                  transform="translate(-98.591 -156.545)"
                  fill="#fff"
                />
                <path
                  id="Path_10346"
                  data-name="Path 10346"
                  d="M263.3,82.594c-2.341-1.021-5.649-6.2-5.591-8.678,1.527.071,5.693,5.769,5.591,8.678"
                  transform="translate(-223.703 -64.164)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.BLOOD_SUGAR:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)">
            <path d="M45.417 50.338H26.556a1.492 1.492 0 0 0-1.491 1.491v4.81a1.492 1.492 0 0 0 1.491 1.491h18.861a1.492 1.492 0 0 0 1.49-1.491v-4.81a1.492 1.492 0 0 0-1.49-1.491Zm-14.827 6h-3.736v-4.211h3.736Zm9 0h-7.211v-4.211h7.216Zm5.525 0h-3.732v-4.211h3.736Zm-9.128-35.589a.9.9 0 0 0-.751.409c-.634.983-6.195 9.71-6.195 13.555 0 4.18 3.116 7.58 6.946 7.58s6.945-3.4 6.945-7.58c0-3.845-5.56-12.572-6.194-13.555a.9.9 0 0 0-.751-.409Zm0 2.578c.137.224.283.466.432.715h-.864c.152-.25.294-.491.432-.715Zm0 17.178a4.835 4.835 0 0 1-3.455-1.5h6.909a4.834 4.834 0 0 1-3.454 1.5Zm4.645-3.293h-9.29a6.249 6.249 0 0 1-.43-1.5h10.15a6.249 6.249 0 0 1-.43 1.5Zm.42-3.293h-10.13a10.577 10.577 0 0 1 .419-1.5h9.292a10.57 10.57 0 0 1 .418 1.501Zm-1.138-3.293H32.06c.218-.492.454-1 .705-1.5h6.442c.252.506.488 1.009.706 1.501Zm-1.627-3.293h-4.6a65.31 65.31 0 0 1 .828-1.5h2.943c.276.484.549.988.828 1.501Zm12.87-19.82a8.33 8.33 0 0 0-6.643 3.313H25.453A7.461 7.461 0 0 0 18 18.279v38.754a7.461 7.461 0 0 0 7.453 7.453h21.068a7.461 7.461 0 0 0 7.453-7.453V23.708a8.341 8.341 0 0 0-2.817-16.195Zm1.027 49.52a5.67 5.67 0 0 1-5.663 5.659H25.453a5.67 5.67 0 0 1-5.664-5.664V18.279a5.67 5.67 0 0 1 5.664-5.664h18.014a8.113 8.113 0 0 0-.57 4.382H24.46a1.492 1.492 0 0 0-1.49 1.495v26.062a1.492 1.492 0 0 0 1.491 1.491h23.052a1.492 1.492 0 0 0 1.494-1.491V23.915a7.919 7.919 0 0 0 3.181.222Zm-4.969-33.819v21.042H24.758v-25.47H43.35a8.387 8.387 0 0 0 3.865 4.428Zm3.942-.8a6.558 6.558 0 1 1 6.558-6.558 6.566 6.566 0 0 1-6.558 6.562Zm-.894-5.322v-5.34a.895.895 0 1 1 1.789 0v5.34a.895.895 0 1 1-1.789 0Zm2.087 2.665v.208a1.192 1.192 0 1 1-2.385 0v-.208a1.192 1.192 0 1 1 2.385 0Z" />
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.CHOLESTEROL_FACTS:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="b">
              <path fill="none" d="M0 0h39.653v50.956H0z" data-name="Rectangle 3118" />
            </clipPath>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)" data-name="Group 2997">
            <g clipPath="url(#b)" data-name="Group 2996" transform="translate(19.173 10.522)">
              <path
                d="M28.74 41.873a14.056 14.056 0 0 1-11.757 5.675C9.317 47.548 3.4 42.432 3.4 36.042c0-3.966 1.913-9.519 4.795-15.424 2.492-5.109 5.674-10.463 8.792-15.6v-.007c2.744 4.52 5.536 9.209 7.866 13.748a11.792 11.792 0 0 1 3.035-.4q.282 0 .562.013C25.525 12.5 21.856 6.424 18.433.824a1.69 1.69 0 0 0-2.9-.006c-3.569 5.838-7.4 12.2-10.384 18.308S0 31.043 0 36.043c0 8.474 7.767 14.913 16.983 14.913 7.45 0 13.954-4.206 16.172-10.3a11.691 11.691 0 0 1-4.415 1.213"
                data-name="Path 10363"
              />
              <path
                d="M28.444 18.378q-.28-.013-.562-.013a11.77 11.77 0 0 0 0 23.539q.432 0 .857-.031a11.77 11.77 0 0 0-.294-23.5m7.115 18.093-.633-.634a.9.9 0 0 0-.965-.189.889.889 0 0 0-.315.211.906.906 0 0 0 0 1.259l.255.255.385.385q-.235.2-.482.38a9.876 9.876 0 0 1-4 1.766 9.591 9.591 0 0 1-1.015.145v-.922a.906.906 0 0 0-1.811 0v.923a9.919 9.919 0 0 1-5.5-2.295l.64-.639a.905.905 0 1 0-1.28-1.281l-.634.633a9.917 9.917 0 0 1-2.243-5.491h.865a.905.905 0 1 0 0-1.811h-.857a9.915 9.915 0 0 1 2.29-5.436l.579.579a.905.905 0 0 0 1.281-1.28l-.576-.574a9.914 9.914 0 0 1 5.432-2.239v.8a.906.906 0 1 0 1.811 0v-.8q.294.026.585.071a9.907 9.907 0 0 1 4.846 2.168l-.575.575a.906.906 0 0 0 1.259 1.3l.6-.6a9.924 9.924 0 0 1 2.29 5.436h-.858a.905.905 0 0 0 0 1.811h.867a9.917 9.917 0 0 1-2.243 5.492"
                data-name="Path 10364"
              />
              <path
                d="M32.642 25.607a.907.907 0 0 0-1.05-.19.925.925 0 0 0-.23.16l-2.244 2.14a2.663 2.663 0 0 0-.37-.157 2.725 2.725 0 1 0 1.273 4.263 2.777 2.777 0 0 0 .279-.436 2.725 2.725 0 0 0 .062-2.355l1.889-1.8.359-.342a.906.906 0 0 0 .031-1.281m-4.759 5.433a.906.906 0 1 1 .906-.906.906.906 0 0 1-.906.906"
                data-name="Path 10365"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.DOCTOR_ANSWER:
      svg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="72"
          height="72"
          viewBox="0 0 72 72"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_3120"
                data-name="Rectangle 3120"
                width="47"
                height="47"
                transform="translate(0 0.117)"
                fill="none"
              />
            </clipPath>
            <clipPath id="clip-Doctor_Answer">
              <rect width="72" height="72" />
            </clipPath>
          </defs>
          <g id="Doctor_Answer" clipPath="url(#clip-Doctor_Answer)">
            <g id="Group_3001" data-name="Group 3001" transform="translate(13.228 13.11)">
              <g id="Group_3000" data-name="Group 3000" transform="translate(-0.228 -0.228)" clipPath="url(#clip-path)">
                <path
                  id="Path_10367"
                  data-name="Path 10367"
                  d="M24.071,0A22.888,22.888,0,0,0,1.2,22.873,21.967,21.967,0,0,0,4.384,34.391L.218,43.541A2.407,2.407,0,0,0,3.4,46.726l9.149-4.166a23.213,23.213,0,0,0,11.518,3.186A22.873,22.873,0,0,0,24.071,0M34.609,20.749,23.5,31.859a3.353,3.353,0,0,1-4.738,0L12.8,25.9a3.293,3.293,0,0,1,4.656-4.656l3.676,3.676,8.741-8.741a3.255,3.255,0,0,1,4.656,0,3.092,3.092,0,0,1,.082,4.575"
                  transform="translate(0.173 0.173)"
                />
              </g>
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.DOCTOR_QUESTION:
      svg = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="72"
          height="72"
          viewBox="0 0 72 72"
        >
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_3119" data-name="Rectangle 3119" width="45.258" height="46.234" fill="none" />
            </clipPath>
            <clipPath id="clip-Doctor_Question">
              <rect width="72" height="72" />
            </clipPath>
          </defs>
          <g id="Doctor_Question" clipPath="url(#clip-Doctor_Question)">
            <g id="Group_2999" data-name="Group 2999" transform="translate(13.371 12.883)">
              <g id="Group_2998" data-name="Group 2998" clipPath="url(#clip-path)">
                <path
                  id="Path_10366"
                  data-name="Path 10366"
                  d="M22.481,31.877a2.805,2.805,0,1,1-2.844,2.805,2.827,2.827,0,0,1,2.844-2.805m.846-23.762c6.217.1,11.462,6.907,7.224,12.872a8.767,8.767,0,0,1-3.387,2.328c-1.99,1.094-2.561.816-2.561,3.29,0,2.923-4.345,2.9-4.477,0l-.08-1.768a4.679,4.679,0,0,1,2.136-3.8c1.509-1.063,2.376-1.007,3.676-2.649,3.046-3.84-2.516-7.747-6.8-4.784A4.084,4.084,0,0,0,17.3,17.4a2.135,2.135,0,1,1-4.269,0,8.854,8.854,0,0,1,3.5-7.234,11.434,11.434,0,0,1,6.791-2.052M22.98.006a23.125,23.125,0,0,0-6.656.816C7.85,3.142,1.27,10.185.25,18.666S1.407,33.263,5.5,37.793L3.533,43.382c-.68,1.928.537,3.418,2.432,2.642l6.917-2.836a23.33,23.33,0,0,0,7,1.976,22.394,22.394,0,0,0,25.2-19.783A22.4,22.4,0,0,0,25.3.173C24.524.079,23.746.024,22.98.006"
                  transform="translate(0 0)"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.EAT_HEALTY_SMOOTHIE_1:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)">
            <path
              d="M46.254 25.81a6.715 6.715 0 0 0-5.093-3.807.766.766 0 0 0-.826.488L36.27 33.38a.762.762 0 0 0 .306.91 6.7 6.7 0 0 0 9.678-8.48Zm-1.215 4.6a5.179 5.179 0 0 1-6.64 3.088 4.98 4.98 0 0 1-.47-.2l3.606-9.662c.141.039.281.083.419.134a5.177 5.177 0 0 1 3.086 6.641Z"
              data-name="Path 10347"
            />
            <path
              d="m43.291 28.98-.578-.211a.763.763 0 0 0-.523 1.433l.578.211a.763.763 0 1 0 .523-1.433Z"
              data-name="Path 10348"
            />
            <path
              d="M42.256 28.065a.76.76 0 0 0 .4-.116l.753-.472a.763.763 0 0 0-.81-1.292l-.753.472a.762.762 0 0 0 .41 1.408Z"
              data-name="Path 10349"
            />
            <path
              d="M41.661 30.824a.763.763 0 0 0-1.456.453l.246.792a.762.762 0 0 0 .728.536.773.773 0 0 0 .226-.034.763.763 0 0 0 .5-.955Z"
              data-name="Path 10350"
            />
            <path
              d="M30.585 39.396a.763.763 0 0 0-.959.039 6.785 6.785 0 0 0-.978 1.067 6.7 6.7 0 1 0 11.488 6.775.763.763 0 0 0-.236-.931Zm7.634 8.146a5.178 5.178 0 0 1-8.342-6.135q.152-.207.323-.4l8.266 6.17a6.87 6.87 0 0 1-.247.365Z"
              data-name="Path 10351"
            />
            <path d="M31.617 46.464a.763.763 0 0 0 1.229.9l.365-.5a.762.762 0 1 0-1.228-.9Z" data-name="Path 10352" />
            <path
              d="M34.253 47.909a.763.763 0 0 0 1.468-.415l-.242-.855a.763.763 0 0 0-1.468.416Z"
              data-name="Path 10353"
            />
            <path
              d="M29.925 44.554a.762.762 0 0 0 .762.749h.014l.83-.015a.763.763 0 0 0-.027-1.525l-.83.015a.762.762 0 0 0-.749.776Z"
              data-name="Path 10354"
            />
            <path
              d="M43.267 38.277a1.428 1.428 0 0 0-2.016 0l-2.126 2.126a1.427 1.427 0 0 0 0 2.017l2.125 2.125a1.427 1.427 0 0 0 2.017 0l2.125-2.125a1.427 1.427 0 0 0 0-2.016Zm-1.007 5.119-1.985-1.985 1.985-1.985 1.985 1.985Z"
              data-name="Path 10355"
            />
            <path
              d="m32.473 25.722 2.774 1.156a1.427 1.427 0 0 0 1.864-.768l1.155-2.774a1.429 1.429 0 0 0-.769-1.865l-2.774-1.157a1.431 1.431 0 0 0-1.864.769l-1.155 2.775a1.428 1.428 0 0 0 .769 1.864Zm1.756-3.962 2.591 1.08-1.079 2.588-2.591-1.079Z"
              data-name="Path 10356"
            />
            <path
              d="M27.768 31.746a1.419 1.419 0 0 0 1.086-.1l2.665-1.389a1.429 1.429 0 0 0 .6-1.924l-1.39-2.664a1.429 1.429 0 0 0-1.924-.6l-2.661 1.387a1.429 1.429 0 0 0-.6 1.924l1.39 2.664a1.422 1.422 0 0 0 .834.702Zm1.66-5.281 1.3 2.489-2.489 1.3-1.3-2.489Z"
              data-name="Path 10357"
            />
            <path
              d="M19.765 66.496h32.469a.762.762 0 0 0 .762-.762V59.92a.762.762 0 0 0-.762-.762h-.794v-3.14a3.424 3.424 0 0 0-2.844-3.367l1.492-38.634h.589a.762.762 0 0 0 .762-.762V8.929a3.429 3.429 0 0 0-3.426-3.426H23.988a3.43 3.43 0 0 0-3.423 3.426v4.326a.762.762 0 0 0 .762.762h.589l1.489 38.634a3.424 3.424 0 0 0-2.84 3.367v3.14h-.8a.762.762 0 0 0-.762.762v5.814a.764.764 0 0 0 .762.762Zm5.161-13.9-1.288-33.415a21.634 21.634 0 0 0 4.867.492 21.918 21.918 0 0 0 6.8-.993 20.376 20.376 0 0 1 6.388-.936 20.361 20.361 0 0 1 6.394.937l.29.081-1.304 33.83Zm-2.84-43.667a1.9 1.9 0 0 1 1.9-1.9h24.028a1.9 1.9 0 0 1 1.9 1.9v3.564H22.086Zm26.479 5.088-.123 3.177a21.829 21.829 0 0 0-6.744-.977 21.9 21.9 0 0 0-6.8.993 20.394 20.394 0 0 1-6.391.936 19.572 19.572 0 0 1-4.927-.546l-.139-3.584Zm-26.476 42a1.9 1.9 0 0 1 1.9-1.9h24.025a1.9 1.9 0 0 1 1.9 1.9v3.14H39.423a3.7 3.7 0 0 0-7.351 0h-9.986Zm15.854 3.564a2.193 2.193 0 1 1-2.193-2.194 2.2 2.2 0 0 1 2.191 2.194Zm-17.411 1.1h11.682a3.693 3.693 0 0 0 7.066 0h12.192v4.289H20.53Z"
              data-name="Path 10358"
            />
            <path
              d="M30.935 38.862a3.376 3.376 0 0 0 3.422-1.618 5.766 5.766 0 0 1 2.482 2.228.762.762 0 1 0 1.332-.741 7.42 7.42 0 0 0-3.357-2.964 3.384 3.384 0 0 0-2.848-3.605c-1.634-.256-4.79 1.631-5.407 2.01a.758.758 0 0 0-.354.533.766.766 0 0 0 .177.616c.473.546 2.924 3.291 4.553 3.541Zm.8-5.194a1.864 1.864 0 0 1 1.541 1.493 10.94 10.94 0 0 0-1.648-.393.762.762 0 0 0-.208 1.51 10.193 10.193 0 0 1 1.5.373 1.855 1.855 0 0 1-1.751.7c-.643-.1-1.989-1.288-3.006-2.35 1.285-.698 2.925-1.432 3.57-1.334Z"
              data-name="Path 10359"
            />
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.EAT_HEALTY_SMOOTHIE_2:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)">
            <path
              d="M57.131 7.367a.968.968 0 0 0-.8-.417h-3.873a.968.968 0 0 0-.907.628l-5.574 14.865H15.664a.968.968 0 0 0-.968.968v3.873a.968.968 0 0 0 .968.968h1.065l3.417 32.462a4.831 4.831 0 0 0 4.816 4.336h22.076a4.831 4.831 0 0 0 4.815-4.335L55.27 28.25h1.065a.968.968 0 0 0 .968-.968v-3.87a.968.968 0 0 0-.968-.968h-4.413l5.319-14.185a.968.968 0 0 0-.111-.892ZM23.411 24.38h9.685v6.778a.969.969 0 1 1-1.937 0v-1.936a2.905 2.905 0 1 0-5.81 0v7.747a.969.969 0 0 1-1.937 0Zm-6.778 1.937V24.38h4.842v1.937Zm3.675 17.433h5.443a10.679 10.679 0 0 0 0 5.81h-4.832Zm29.619 16.766a2.9 2.9 0 0 1-2.889 2.6H24.962a2.9 2.9 0 0 1-2.889-2.6l-.949-9.019h5.39a10.649 10.649 0 0 0 18.973 0h5.389ZM27.284 46.65a8.715 8.715 0 1 1 8.715 8.715 8.715 8.715 0 0 1-8.715-8.715Zm23.8 2.905h-4.831a10.679 10.679 0 0 0 0-5.81h5.443Zm.815-7.747h-6.413a10.649 10.649 0 0 0-18.973 0h-6.409L18.677 28.25h2.8v8.715a2.905 2.905 0 1 0 5.81 0v-7.743a.969.969 0 0 1 1.937 0v1.937a2.905 2.905 0 0 0 5.81 0V28.25h18.291Zm3.471-17.43v1.937H35.031V24.38Zm-5.513-1.937h-1.8l5.084-13.557h1.8Z"
              data-name="Path 10360"
            />
            <path
              d="M32.677 51.605a2.891 2.891 0 0 0 2.7 1.826h1.251a2.891 2.891 0 0 0 2.7-1.826l1.311-3.278a2.892 2.892 0 0 0 .208-1.079v-.6a2.893 2.893 0 0 0-.742-1.937 2.893 2.893 0 0 0 .742-1.937v-1.937a.968.968 0 0 0-.968-.968h-1.937a2.893 2.893 0 0 0-1.937.742 2.893 2.893 0 0 0-1.947-.737h-1.937a.968.968 0 0 0-.968.968v1.932a2.893 2.893 0 0 0 .742 1.937 2.893 2.893 0 0 0-.742 1.937v.6a2.893 2.893 0 0 0 .208 1.079Zm4.291-8.826a.969.969 0 0 1 .968-.968h.968v.968a.969.969 0 0 1-.968.968h-.968Zm-3.873-.968h.968a.969.969 0 0 1 .968.968v.968h-.973a.969.969 0 0 1-.968-.968Zm0 4.842a.969.969 0 0 1 .968-.968h.968v.968a.969.969 0 1 0 1.937 0v-.968h.968a.969.969 0 0 1 .968.968v.6a.965.965 0 0 1-.069.36l-1.311 3.278a.964.964 0 0 1-.9.609h-1.251a.964.964 0 0 1-.9-.609l-1.311-3.278a.964.964 0 0 1-.069-.36Z"
              data-name="Path 10361"
            />
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.EAT_HEALTY_SMOOTHIE_3:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)" data-name="Group 2995">
            <g data-name="Group 2994">
              <path
                d="M58.924 12.233c-4.06-.777-7.326-.319-9.7 1.349a8.186 8.186 0 0 0-2.947 3.844c-17.813.783-22.953 14.464-24.115 18.706a9.126 9.126 0 1 0 2.289.687A22.157 22.157 0 0 1 43.79 20.058c-3 4.127-8.308 13.278-3.981 21.856a9.115 9.115 0 1 0 3.127.542c-4.331-6.283-1.819-13.374.855-17.995a10.42 10.42 0 0 0 .151 1.319c.7 3.946 3.482 7.579 8.266 10.8l1.241.831.548-1.392c.114-.283 2.723-7 .09-12.573a10.127 10.127 0 0 0-1.862-2.723 8.157 8.157 0 0 0 1.223-.331c4.2-1.53 6.271-6.3 6.356-6.494l.59-1.374Zm-31.8 34.869a6.7 6.7 0 1 1-4.433-8.374 6.708 6.708 0 0 1 4.436 8.374Zm14.687-2.482a6.7 6.7 0 1 1-8.379 4.428 6.7 6.7 0 0 1 8.383-4.428Zm10.41-10.994c-3.386-2.56-5.368-5.326-5.9-8.229a8.738 8.738 0 0 1 .7-5.3 8.748 8.748 0 0 1 4.88 4.362c1.523 3.209.872 7.083.324 9.167Zm.4-15.507a5.588 5.588 0 0 1-3.994-.1 5.791 5.791 0 0 1 1.982-2.464 9 9 0 0 1 6.212-1.197 9.047 9.047 0 0 1-4.199 3.761Z"
                data-name="Path 10362"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case FactsIconKeys.QUIT_SMOKING:
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
          <defs>
            <clipPath id="a">
              <path d="M0 0h72v72H0z" />
            </clipPath>
          </defs>
          <g clipPath="url(#a)" data-name="Group 2304">
            <path
              d="M62.243 36.054a26.268 26.268 0 0 0-44.869-18.395l-.027-.02-.763.849-.028.029-.667.741.012.011a26.084 26.084 0 0 0 20.1 42.9 26.224 26.224 0 0 0 18.955-8.066l.014.013.424-.472.084-.092.95-1.057-.026-.024a25.917 25.917 0 0 0 5.839-16.416M36 12.115a24.025 24.025 0 0 1 24.056 23.939 23.743 23.743 0 0 1-5.291 14.954l-8.227-7.337h5.657v-8.7H36.778l-17.766-15.85A24.037 24.037 0 0 1 36 12.115m-6.29 25.024h6.232l4.879 4.352H29.71Zm-2.187 4.352h-7.379v-4.352h7.379Zm11.695-4.352h10.789v4.352h-5.909ZM36 59.993a24.025 24.025 0 0 1-24.057-23.939 23.759 23.759 0 0 1 5.594-15.326l15.964 14.238H17.956v8.7h25.3l10.063 8.973a24.039 24.039 0 0 1-17.32 7.35"
              data-name="Path 3710"
            />
            <path d="M52.948 34.966h2.187v8.705h-2.187z" data-name="Rectangle 2823" />
            <path
              d="M42.564 21.912a5.383 5.383 0 0 0 1.331 3.965 5.358 5.358 0 0 0 4.046 1.225h.288c1.356.01 4.177.034 4.177 5.691h2.187c0-3.566-1.1-7.825-6.347-7.867h-.312a3.553 3.553 0 0 1-2.5-.59 3.391 3.391 0 0 1-.688-2.424c0-2.234-.924-4.9-5.321-4.9h-3.844v2.176h3.846c2.255 0 3.134.763 3.134 2.72"
              data-name="Path 3711"
            />
            <path
              d="M42.036 27.339c-.843-5.111-4.656-5.418-4.892-5.431h-5.3v2.176h5.213c.232.022 2.277.316 2.819 3.609.65 3.948 4.964 4.314 8.154 4.314.388 0 .76-.005 1.106-.011l-.032-2.176c-4.845.055-6.76-.6-7.068-2.479"
              data-name="Path 3712"
            />
            <path d="M29.664 17.012h4.248v2.176h-4.248z" data-name="Rectangle 2824" />
            <path fill="none" d="M9.885 9.604h52.306v52.793H9.885z" data-name="Rectangle 2825" />
          </g>
        </svg>
      );
      break;
  }

  return <div className="w-20 h-20">{svg}</div>;
};
