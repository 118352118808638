export const MainLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 1226.21 320.63"
    fill="currentColor"
  >
    <defs>
      <linearGradient
        id="main-logo-linear-gradient"
        x1="217.3"
        x2="47.96"
        y1="90.79"
        y2="292.61"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ff5e00" />
        <stop offset=".54" stopColor="#d4381f" />
        <stop offset=".85" stopColor="#c1272d" />
      </linearGradient>
    </defs>
    <path
      d="M690.15 78.84C690.53 78.06 700.2 50 700.2 50h-90.66v28.68h27.38l.05 78.61h33.81l-1.71-77.86Z"
      className="cls-1"
    />
    <path
      d="M730.52 49.66c-36.6 0-39.54 52.34-39.47 61.55.37 48 27.27 49 40.31 48.78s38.73-1.92 38.37-49.95-26.17-60.58-39.21-60.38Zm.64 84.11c-5 .07-9.52-2-9.68-23s4.24-34.81 9.24-34.88 8.42 13.64 8.58 34.61-3.14 23.19-8.14 23.27ZM1014.87 50.93l-.75 223h36.38v-42.69l11.8-.5 7.91 43.69h43.36l-50-224.43Zm32.28 137.73-.12-58.83 10 58.86ZM1190.25 46.04l-115.81 1.47 7.36 41.83 38.49-1.02v185.92h45.67l-5.89-185.35 27.23-.57 2.95-42.28zM322.93 49.99h-49.35v224.25h119.05v-50.08l-69.7 13.88V49.99zM543.78 175.5l33.72-.33v-44.78h-33.72V90.76l47.96 4.01V49.99H478.8l-37.21 151.54 1.47-151.54h-40.11l2.55 223.9h51.82l45.25-161.86v162.21h90.55v-58.22l-49.34 13.88v-54.4z"
      className="cls-1"
    />
    <path
      d="m346.57 216.54 46.07-8.96V100.39h-46.07v116.15zM666 198.49c0-.14.38-27.7.38-28l-69.84.32 5.13 27.72H622l.32 75.93h25.16l-1.24-76.07ZM731.94 170.59h-23.15l-.1 36.09h-13.53l.53-36.14-24.12.11c.07 7.62 1 103.79 1 103.79h21.58l.66-44.93 14.9-.13-.13 45.06h21.57s.85-102.67.79-103.85ZM760.36 253.46v-25.07l23.68-.15v-20.63h-23.68v-18.26l30.24 1.85v-20.64h-53.8c.19 6.55 0 103.33 0 103.33h54.4v-26.83ZM346.57 49.99v32.56l46.08-.68V49.7h-46.08v.29z"
      className="cls-1"
    />
    <path
      fill="url(#main-logo-linear-gradient)"
      d="M282.24 195.9a86.9 86.9 0 0 0-74.12-85.84 86.76 86.76 0 0 0-172.3 14.53l.5 158.25h161.85a9.52 9.52 0 0 0 2.4-.32 86.88 86.88 0 0 0 81.67-86.62Zm-86.77 67.82h-.55a10.05 10.05 0 0 0-1.74.16h-138L54.77 125v-.45a67.82 67.82 0 0 1 133.92-15.18 86 86 0 0 0-19.33 3.79 48.7 48.7 0 0 0-49.07-36.73 48.94 48.94 0 0 0-41.64 27c-5.23 8.4-5.59 21.6-5.59 22.69V244H200a39.47 39.47 0 0 0 6.57-.45 48.87 48.87 0 0 0 37-45.35 48.47 48.47 0 0 0-96-11.25h-17.4v-9.33a67.82 67.82 0 1 1 65.32 86.1Zm-74.8-144.9a9.48 9.48 0 0 0-9.48 9.48v77.6h84.6a9.48 9.48 0 1 0 0-19h-28.7a29.07 29.07 0 0 1 9.85-14.2 29.68 29.68 0 0 1 47.7 24.73 29.75 29.75 0 0 1-12.42 23c-3 2.16-8.49 4.59-12.2 4.59H92v-98.51a36.14 36.14 0 0 1 6.47-19.24 29.6 29.6 0 0 1 44.27-3.58 29.38 29.38 0 0 1 8.83 17.42 87.32 87.32 0 0 0-21.43 17.72V128.3a9.49 9.49 0 0 0-9.47-9.48Z"
    />
    <path
      d="m957.33 175.7 33.52-.33v-44.52h-33.52v-39.4l47.69 4V50.91h-88.66v127.57c-1-3.14-2.13-6.33-3.5-9.63l-.06-.13a75.41 75.41 0 0 0-18.29-22.85 85.309 85.309 0 0 0-5.52-4.25c18-11.37 21-29.38 21.08-39.41-.74-35.87-30.69-46.79-39.85-49.27-40.45-11-99.37-3.91-100-3.84l-2.21.28 15.8 45.52 1.58-.3c.05 0 5.64-1 17.17-1.18l1.84 180.82A348.47 348.47 0 0 0 845 277c40.56 0 63.53-20.84 71.36-42.27v39.2h90V216l-49.06 13.8ZM851.7 94.32c5.51.35 20.26 2.58 20.26 16.28 0 10.07-7.36 16-20.26 16.56Zm0 146.15v-69.83c8.07 1.36 25.13 11.16 25.13 37 0 20.71-12.62 30.42-25.13 32.83Z"
      className="cls-1"
    />
  </svg>
);
