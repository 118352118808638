import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_ROOT_URL;

export function get<T>(url: string, config?: AxiosRequestConfig) {
  return axios.get<T>(url, config).then((response) => response.data);
}

export function put<T, U>(url: string, data: U, config?: AxiosRequestConfig) {
  return axios.put<T, AxiosResponse<T>, U>(url, data, config).then((response) => response.data);
}

export function post<T, U>(url: string, data: U, config?: AxiosRequestConfig) {
  return axios.post<T, AxiosResponse<T>, U>(url, data, config).then((response) => response.data);
}
