import { Icon, IconKeys } from '../../components';
import { TopicKeys } from '../../data/topics';

export const SubjectButton = ({
  subject,
  color = 'bg-primary-1',
  trophy,
  choice,
}: {
  subject: { id: TopicKeys; name: string; choice: string };
  color?: string;
  trophy?: boolean;
  choice?: boolean;
}) => {
  return (
    <div
      className={
        'relative subject-button w-full aspect-square flex flex-col justify-end items-center text-black select-none rounded-tl-3xl rounded-br-3xl ' +
        color
      }
    >
      <div className="w-full flex-1 flex justify-center items-center">
        <div className="w-1/2">
          <Icon icon={subject.id} />
        </div>
      </div>
      <h3 className="p-2 text-center">{choice ? subject.choice : subject.name}</h3>
      <div className="circle-check absolute top-1/2 -translate-y-1/2 w-1/4 aspect-square fill-white invisible">
        <Icon icon={IconKeys.CIRCLE_CHECK} />
      </div>
      {trophy && (
        <div className="absolute bg-[#AB0000] text-white w-12 aspect-square rounded-full -top-4 -right-4 flex justify-center items-center">
          <div className="w-6">
            <Icon icon={IconKeys.RIBBON} />
          </div>
        </div>
      )}
    </div>
  );
};
