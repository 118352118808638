import { useContext } from 'react';

import { Button } from '..';
import { Api } from '../../api';
import { HistoryContext } from '../../providers';

export const CodeForm = () => {
  const { progress, codename, preferences, setCodename } = useContext(HistoryContext);

  const handleClick = () => {
    if (codename === '') {
      Api.user.new({ progress, preferences }).then((result) => {
        setCodename(result.data.codename);
        console.log('created new user codename: ' + result.data.codename);
      });
    } else {
      console.log('codename already exists: ' + codename);
      Api.user.update({ codename, progress });
    }
  };

  return (
    <div className="max-w-lg mx-auto md:text-center">
      <h3 className="text-2xl text-neutral-2">Transfer to another device!</h3>
      <p className="my-4">
        If you'd like to move to another computer or device, save the code word. You can use it on any device to pick up
        where you left off!
      </p>
      {codename === '' ? (
        <div className="text-center mt-10">
          <div onClick={handleClick}>
            <Button type="cta">Get Code</Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-8 items-center">
          <input
            type="text"
            value={codename}
            readOnly
            className="text-black bg-white text-3xl font-loos font-bold w-32 p-4 text-center"
          />
          {/* <Button type="cta" size="sm">
            Copy to Clipboard
          </Button> */}
          <p>Simply copy this code and save it to a safe place or email yourself!</p>
        </div>
      )}
    </div>
  );
};
