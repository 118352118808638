import { useEffect, useState } from 'react';

import { useStorage } from './storage-hook';

export const useSavedState = <T>(
  key: string,
  initial: T,
  initializer?: (initial: T) => T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const { get, set } = useStorage<T>(key, { json: true });
  const [state, setState] = useState<T>(() => {
    const value = get() || initial;
    return initializer ? initializer(value) : value;
  });

  useEffect(() => {
    set(state);
  }, [state, set]);

  return [state, setState];
};
