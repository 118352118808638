export const SvgElement = ({ type }: { type: string }) => {
  switch (type) {
    case 'map-bg-curve':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 2964" viewBox="0 0 928.458 534.981">
          <defs>
            <clipPath id="a">
              <path fill="none" strokeWidth="3" d="M0 0h136.794v302.048H0z" data-name="Rectangle 2701" />
            </clipPath>
            <clipPath id="b">
              <path fill="none" strokeWidth="3" d="M0 0h136.794v302.048H0z" data-name="Rectangle 2702" />
            </clipPath>
          </defs>
          <path fill="none" stroke="currentColor" strokeWidth="3" d="M92.804 17.713h698.924" data-name="Line 96" />
          <path fill="none" stroke="currentColor" strokeWidth="3" d="M136.297 267.433h657.432" data-name="Line 97" />
          <g clipPath="url(#a)" data-name="Mask Group 1892" transform="translate(791.664)">
            <g
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              data-name="Ellipse 426"
              transform="translate(-125.777 16.525)"
            >
              <ellipse cx="125.777" cy="126.236" stroke="none" rx="125.777" ry="126.236" />
              <ellipse cx="125.777" cy="126.236" rx="124.277" ry="124.736" />
            </g>
          </g>
          <g clipPath="url(#b)" data-name="Mask Group 1893" transform="rotate(180 68.397 267.49)">
            <g
              fill="none"
              stroke="currentColor"
              strokeWidth="3"
              data-name="Ellipse 427"
              transform="translate(-125.777 16.525)"
            >
              <ellipse cx="125.777" cy="126.236" stroke="none" rx="125.777" ry="126.236" />
              <ellipse cx="125.777" cy="126.236" rx="124.277" ry="124.736" />
            </g>
          </g>
          <path fill="none" stroke="currentColor" strokeWidth="3" d="M136.729 517.433h603" data-name="Line 98" />
        </svg>
      );

    default:
      return null;
  }
};
